import React, { useCallback, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Autocomplete from '../shared/Autocomplete'
import LicenceAreaDistrictDropdowns from '../childcomponents/LicenceAreaDistrictDropdowns'
import { StatsCards } from '../childcomponents/StatsCards';

interface Props {
	customerId: string,
	onSubstationFilterChange: (substationsList: any[], filteredPreFaultSubstations: any[], filteringByAreaOnly: boolean) => void;
	substationsList: any[],
	substationsListLoading: boolean,
	feedersList: any[],
	feedersListLoading: boolean,
	masterAlarmsList: any,
	filteredAlarmsList: any,
	alarmsListLoading: boolean,
	prefaultsList: any[],
	prefaultsListLoading: boolean,
	onLicenceAreaChange: (selectedLicenceArea: any, geofence?: any) => void;
	onClearFilters: () => void;
}

export default function SubstationFilters(props: Props) {

	const [filteredSubstationsList, setFilteredSubstationsList] = useState<any[]>([]);
	const [filteredPreFaultSubstations, setFilteredPreFaultSubstations] = useState<any[]>([]);

	const [licenceAreaFilter, setLicenceAreaFilter] = useState<any | null>(null);
	const [districtFilter, setDistrictFilter] = useState<any | null>("");
	const [alarmFilters, setAlarmFilters] = useState<string[]>([])
	const [substationFilter, setSubstationFilter] = useState<any | null>(null);
	const [isPrefaultFilter, setIsPrefaultFilter] = useState(false);
	const [isMonitoredFilter, setIsMonitoredFilter] = useState(false);
	const [filteringByAreaOnly, setFilteringByAreaOnly] = useState(false);

	const handleAlarmCardClick = useCallback((clickedFilter: any) => {

		let currentFilters = [...alarmFilters];

		if (currentFilters.some((f => f === clickedFilter))) {
			const findIndex = currentFilters.findIndex((fi => fi === clickedFilter));

			findIndex !== -1 && currentFilters.splice(findIndex, 1);
		}
		else {
			currentFilters.push(clickedFilter)
		}

		setAlarmFilters(currentFilters);

	}, [alarmFilters]);

	const handlePrefaultCardClick = useCallback(() => {
		setIsPrefaultFilter(!isPrefaultFilter);
	}, [isPrefaultFilter]);

	const handleIsMonitoredCardClick = useCallback(() => {
		setIsMonitoredFilter(!isMonitoredFilter);
	}, [isMonitoredFilter]);

	const { onLicenceAreaChange } = props;
	const handleLicenceAreaChange = useCallback((licenceArea: any, geofence?: any) => {
		setLicenceAreaFilter(licenceArea)
		onLicenceAreaChange((licenceArea && licenceArea.name) || "", geofence);
	}, [onLicenceAreaChange]);

	const handleDistrictChange = useCallback((district: any, geofence?: any) => {
		setDistrictFilter(district);
		district && district !== "" && onLicenceAreaChange(district, geofence);
	}, [onLicenceAreaChange]);
	
	useEffect(() => {
		const filteredPrefaultData = filterPrefaultData(filteredSubstationsList, props.prefaultsList);
		setFilteredPreFaultSubstations(filteredPrefaultData);

		props.onSubstationFilterChange(filteredSubstationsList, filteredPreFaultSubstations, filteringByAreaOnly);
	}, [filteredSubstationsList])

	useEffect(() => {
		if (!props.substationsList)
			return;

		const sortedSubstations = props.substationsList.sort((a, b) => a.name.localeCompare(b.name));
		setFilteredSubstationsList(sortedSubstations);
	}, [props.substationsList])

	function handleSubstationAutocompleteSelectionChange(substation: any) {
		if (substation && substation.id && substation.id !== "") {
			setSubstationFilter(substation);
		}
		else {
			setSubstationFilter(null);
		}
	}

	function clearFilters() {
		props.onClearFilters();

		licenceAreaFilter && handleLicenceAreaChange(null);
		districtFilter && handleDistrictChange("");
		setSubstationFilter(null);
		setAlarmFilters([]);

		setIsPrefaultFilter(false);
		setIsMonitoredFilter(false);
		setFilteringByAreaOnly(false);
	}

	useEffect(() => {
		const filteredPrefaultData = filterPrefaultData(filteredSubstationsList, props.prefaultsList);
		setFilteredPreFaultSubstations(filteredPrefaultData);
	}, [props.prefaultsList])

	useEffect(() => {
		applyFilters();
	}, [licenceAreaFilter, districtFilter, alarmFilters, substationFilter, isPrefaultFilter, isMonitoredFilter])

	function applyFilters() {

		let filteredSubstations = [...props.substationsList];

		if (districtFilter && districtFilter !== "") {
			setFilteringByAreaOnly(true);
			filteredSubstations = filteredSubstations.filter((substation: any) => substation.district === districtFilter);
		}
		else if (licenceAreaFilter && licenceAreaFilter.name !== "") {
			setFilteringByAreaOnly(true);
			filteredSubstations = filteredSubstations.filter((substation: any) => substation.licenceArea === licenceAreaFilter.name);
		}

		if (substationFilter) {
			setFilteringByAreaOnly(false);
			filteredSubstations = [substationFilter];
		}

		if (alarmFilters.length !== 0) {
			filteredSubstations = applyAlarmFilters(filteredSubstations, props.masterAlarmsList, alarmFilters);
		}

		if (isMonitoredFilter) {
			setFilteringByAreaOnly(false);
			filteredSubstations = filteredSubstations.filter((substation: any) => substation.isMonitored === true);
		}

		if (isPrefaultFilter) {
			setFilteringByAreaOnly(false);
			filteredSubstations = filteredSubstations.filter((substation: any) => props.prefaultsList && props.prefaultsList.some((prefaultSubstation: any) => substation.id === prefaultSubstation.substationAssetId))
		}

		setFilteredSubstationsList(filteredSubstations)
		
	}

	return (
		<React.Fragment>

			<LicenceAreaDistrictDropdowns customerId={props.customerId} substationsList={props.substationsList} onLicenceAreaChange={handleLicenceAreaChange} onDistrictChange={handleDistrictChange} selectedLicenceArea={licenceAreaFilter} selectedDistrict={districtFilter} substationsDataLoading={props.substationsListLoading || props.alarmsListLoading} />

			<Col className="pb-2">
				Substations
					<Autocomplete
					disabled={!props.customerId || props.customerId === "" || props.substationsListLoading  || props.alarmsListLoading}
					value={filteredSubstationsList && filteredSubstationsList.length === 1 ? filteredSubstationsList[0] : null}
					className="w-100"
					data={filteredSubstationsList}
					textField="name"
					onSelectionChange={handleSubstationAutocompleteSelectionChange}
				/>
			</Col>

			<Col xs={12} className="pb-2 d-flex justify-content-end">
				<button type="button" className="btn btn-link" onClick={clearFilters}><small style={{ whiteSpace: "nowrap" }}>Clear filters</small></button>
			</Col>

			<Col xs={12}>
				<StatsCards
					loading={props.alarmsListLoading}
					alarmsData={props.filteredAlarmsList}
					substations={filteredSubstationsList}
					substationsLoading={props.substationsListLoading}
					feeders={props.feedersList}
					preFaultAlarmsLoading={props.prefaultsListLoading}
					preFaultAlarmsData={filteredPreFaultSubstations}
					feederListLoading={props.feedersListLoading}
					onAlarmCardClick={handleAlarmCardClick}
					alarmFilters={alarmFilters}
					onPrefaultCardClick={handlePrefaultCardClick}
					prefaultFilterApplied={isPrefaultFilter}
					isMonitoredFilterApplied={isMonitoredFilter}
					onIsMonitoredCardClick={handleIsMonitoredCardClick}
				/>
			</Col>

		</React.Fragment>
	)
}

function applyAlarmFilters(substations: any, alarmsList: any, alarmFilters: string[]) {
	if (alarmFilters.length > 0) {
		return substations.filter((substation: any) => {
			const alarmSubstation = alarmsList.substations.find((alarmSubstation: any) => alarmSubstation.substationId === substation.id);
			return alarmSubstation && alarmFilters.every((alarmName: string) => alarmSubstation.alarms.some((alarm: any) => alarm.alarmName === alarmName))
		})
	}
	else {
		return substations;
	}
}

function filterPrefaultData(substations: any[], prefaultData: any) {

	//filter pre-fault alarms to only include substations that we're looking at
	let filteredPrefaultsList = [];

	if (prefaultData) {
		filteredPrefaultsList = prefaultData.filter((prefaultSubstation: any) => {
			return substations && substations.some((substation => substation.id === prefaultSubstation.substationAssetId))
		})
	}

	return filteredPrefaultsList;
}