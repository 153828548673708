import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import moment from 'moment';
import TimeSpanSlider, { Mark } from '../childcomponents/TimespanSlider';
import NumericPhaseDataChart from './Chart/NumericPhaseDataChart'
import WaveformPhaseDataChart from './Chart/WaveformPhaseDataChart'
import { DataPointTypes, IDataPointType } from '../../constants/datapointtypes';
import { CardTitleBar } from '../shared/CardTitleBar'
import { ToastMode } from '../shared/MessageToast'
import { ResizeIcon } from './ResizeableIcon';

interface Props {
	phases: any;
	busbarAsset: any;
	defaultDataPointTypes: IDataPointType[];
	dataPointTypesList?: IDataPointType[];
	title: string;
	onExpandChart: () => void;
	expanded: boolean;
	graphicHidden: boolean;
	substation?: any;
}

const chartSliderMarks: Mark[] = [
	{
		value: 0,
		label: '-1 MONTH',
		timespanStart: {
			unitOfTime: "M",
			amount: -1
		},
		chartTimeUnit: 'day'
	},
	{
		value: 1,
		label: '-1 WEEK',
		timespanStart: {
			unitOfTime: "w",
			amount: -1
		},
		chartTimeUnit: 'day'
	},
	{
		value: 2,
		label: '-24 HOURS',
		timespanStart: {
			unitOfTime: "h",
			amount: -24
		},
		defaultValue: true,
		chartTimeUnit: 'hour'
	}
];

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		multiSelect: {
			width: "100%",

			"& > * + *": {
				marginTop: theme.spacing(3)
			},
			'& .MuiOutlinedInput-root': {
				cursor: "default",
				'& fieldset': {
					borderColor: theme.palette.grey[400],
				},
				'&:hover fieldset': {
					borderColor: theme.palette.grey[400],
				},
				'&.Mui-focused fieldset': {
					borderColor: theme.palette.grey[400],
				},
			},
			'& .MuiOutlinedInput-input': {
				display: 'none'
			}
		},
		clearIndicator: {
			color: theme.palette.grey[400],

		},
		popupIndicator: {
			color: theme.palette.grey[400]
		},
		inputLabel: {
			color: theme.palette.grey[400],
			"&.focused": {
				color: theme.palette.grey[400]
			}
		},
		deleteIcon: { color: '#00AAE4' },
		expandedClass: {
			margin: "0",
			maxWidth: "100%",
		},
	})
);

export default function DetailPanelCharts(props: Props) {
	const classes = useStyles();
	const { expanded } = props;

	const [timespanSliderDisabled, setTimespanSliderDisabled] = useState(false);
	const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment>(moment().add(-24, "h"));
	const [busbarPhases, setBusbarPhases] = useState<any[]>([]);
	const [busbarAssetIDs, setBusbarAssetIDs] = useState<any[]>([]);
	const [phaseAssetIDs, setPhaseAssetIDs] = useState<any[]>([]);
	const [selectedDatapointTypes, setSelectedDatapointTypes] = useState<IDataPointType[]>(props.defaultDataPointTypes);

	useEffect(() => {
		let phases: any[] = [];

		props.busbarAsset.busbars.forEach((busbar: any) => {
			phases = phases.concat(busbar.phases)
		});

		const assetIDs = phases.map(busbarPhase => busbarPhase.assetId);

		setBusbarPhases(phases);
		setBusbarAssetIDs(assetIDs);
	}, [props.busbarAsset]);

	useEffect(() => {
		const assetIDs = props.phases.map((phase: any) => { return phase.assetId });

		// Temperature can be attached to the transformer assetId
		assetIDs.push(props.busbarAsset.assetId);

		// Signal Strength can be attached to the substation assetId
		if (props.substation) {
			assetIDs.push(props.substation.id);
		}

		setPhaseAssetIDs(assetIDs);
	}, [props.busbarAsset.assetId, props.phases, props.substation]);

	useEffect(() => {
		setSelectedDatapointTypes(props.defaultDataPointTypes);
	}, [props.defaultDataPointTypes]);

	useEffect(() => {
		const displayingNumericData = props.defaultDataPointTypes.some(((dataPointType: any) => !dataPointType.isWaveform));

		if (displayingNumericData) {
			setTimespanSliderDisabled(false);
		}
		else {
			setTimespanSliderDisabled(true);
		}
	}, [props.defaultDataPointTypes])

	const onTimespanSliderChange = (selectedMark: Mark): void => {

		if (selectedMark && selectedMark.timespanStart) {

			const timespanUnit = selectedMark.timespanStart.unitOfTime as moment.unitOfTime.DurationConstructor;
			const selectedDate = moment().add(selectedMark.timespanStart.amount, timespanUnit);

			setSelectedStartDate(selectedDate);

		}
	}

	function handleDataPointTypeSelectionChange(event: any, newValue: IDataPointType[] | null) {
		setSelectedDatapointTypes(newValue || [])
	}

	return <Card className="card-bg-secondary mt-2 mb-2 w-100"  >
		<Card.Body className="px-0">
			<ResizeIcon onClick={props.onExpandChart} expanded={expanded}/>
			<CardTitleBar title={props.title} toastShow={false} toastText="" toastMode={ToastMode.Success} />
			<Container>
				{props.dataPointTypesList && <Row>
					<Col xs="12" className="pt-1">
						<Autocomplete
							className={classes.multiSelect}
							multiple
							options={props.dataPointTypesList}
							getOptionLabel={(option) => option.title}
							value={selectedDatapointTypes}
							filterSelectedOptions
							onChange={handleDataPointTypeSelectionChange}
							classes={{
								clearIndicator: classes.clearIndicator,
								popupIndicator: classes.popupIndicator
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label="Data Type(s)"
									InputLabelProps={{
										classes: {
											root: classes.inputLabel,
											focused: "focused"
										}
									}}
								/>
							)}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										classes={{
											deleteIcon: classes.deleteIcon
										}}

										label={`${option.title}`}
										{...getTagProps({ index })}
									/>
								))

							}
						/>
					</Col>
				</Row>
				}
				<Row>
					<Col xs="12" className="pt-2 px-5">
						<TimeSpanSlider marks={chartSliderMarks} onChange={onTimespanSliderChange} disabled={timespanSliderDisabled} />
					</Col>
				</Row>
				{busbarAssetIDs && busbarAssetIDs.length > 0 && selectedDatapointTypes.map(dataPointType => dataPointType.isBusbarData &&
					<Row>
						<Col xs="12" className="pt-1">

							<NumericPhaseDataChart assetIDs={busbarAssetIDs} dataPointType={DataPointTypes.VoltageMean30minutes} startDate={selectedStartDate} phaseAssets={busbarPhases} expanded={expanded}/>
						</Col>

					</Row>
				)}
				<Row>
					{phaseAssetIDs && phaseAssetIDs.length > 0 && selectedDatapointTypes.map((dataPointType: IDataPointType) =>
						!dataPointType.isBusbarData && <Col xs="12" className="pt-4">
							{dataPointType.isWaveform
								? <WaveformPhaseDataChart dataPointType={dataPointType} phaseAssets={props.phases} displayCount={2} expanded={expanded} />
								: <NumericPhaseDataChart assetIDs={phaseAssetIDs} dataPointType={dataPointType} startDate={selectedStartDate} phaseAssets={props.phases} expanded={expanded}/>
							}
						</Col>
					)}
				</Row>
			</Container>
		</Card.Body>
	</Card>

}
