import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TdrIcon from './TdrIcon'

interface Props {
	width: string;
	height: string;
	visnetSerialNumber: string;
	showTDR?: boolean;
	onTdrClick?: any;
}

const useStyles = makeStyles({
	border: {
		fill: "#A4A4A4"
	},
	background: {
		fill: "#F6F6F6"
	},
	connector: {
		fill: "#464646",
		stroke: "#464646"
	},
});

export default function VisnetIcon(props: Props) {

	const classes = useStyles();

	return (
		<svg width={props.width} x="0px" y="0px" viewBox="11 0 50 200" xmlLang={"http://www.w3.org/1999/xhtml"} >

			<g>
				<title>VisNet Serial Number: {props.visnetSerialNumber}</title>
				<circle className={classes.border} cx="11.9" cy="4.84" r="2.14" />
				<circle className={classes.border} cx="38.78" cy="4.84" r="2.14" />
				<path className={classes.border} d="M13.34,48.12c-0.06,0-0.13-0.05-0.16-0.1l-1.24-2.73c-0.03-0.06-0.05-0.15-0.05-0.21V4.82	c0-0.06,0.05-0.12,0.11-0.13l13.22-1.98c0.06-0.01,0.16-0.01,0.22,0l13.22,1.98c0.06,0.01,0.11,0.07,0.11,0.13v40.26 c0,0.06-0.02,0.16-0.05,0.21l-1.24,2.73c-0.03,0.06-0.1,0.1-0.16,0.1H13.34z" />
				<path className={classes.background} d="M15.03,42.56c-0.1,0-0.19-0.08-0.19-0.19V7.39c0-0.1,0.08-0.2,0.18-0.21l10.12-1.52c0.1-0.02,0.27-0.02,0.37,0 l10.12,1.52c0.1,0.02,0.18,0.11,0.18,0.21v34.99c0,0.1-0.08,0.19-0.19,0.19H15.03z" />
				<path className={classes.border} d="M33.61,14.89c0,0.1-0.08,0.19-0.19,0.19H17.08c-0.1,0-0.19-0.08-0.19-0.19V8.82c0-0.1,0.08-0.19,0.19-0.19	h16.34c0.1,0,0.19,0.08,0.19,0.19V14.89z" />
			</g>

			{ props.showTDR && <g onClick={props.onTdrClick } style={{ "cursor": props.onTdrClick ? "pointer" : "auto" }}>
				<line className={classes.connector} x1="25" y1="48" x2="25" y2="80" fill="rgba(0,0,0,0)" strokeWidth="3"></line>
				<foreignObject x="1" y="65" width={props.width} height="160" >
					<span >
						<TdrIcon width="60" height="70" ></TdrIcon>
					</span>
				</foreignObject>
			</g>} 
		</svg>

	)
}
