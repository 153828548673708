import React from 'react'
import AlarmSeverity from '../../../../utils/AlarmSeverity'

interface Props {
    transformerData: any;
    busbarData: any;
    alarmTypes: any;
}

const xEnd = 630; // x end of the graphic viewbox
let yEnd = 800;// y end of the graphic viewbox

const strokeWidth = 11; //width of the strokes

const xAlarmBoxSectonStart = 200; //x position of the right side of alarm label column / left side of windings section

const rectGridPadding = 5; //padding in the alarm status grid

const yBusbarStart = 295;
const yBusbarEnd = 395;
const xBusbarStart = xAlarmBoxSectonStart;
const xBusbarEnd = xEnd - rectGridPadding;

const yTransformerStart = 5;
const yTransformerEnd = 290;
const xTransformerStart = xAlarmBoxSectonStart;
const xTransformerEnd = xEnd - rectGridPadding;

const headerHeight = 100; //height of the column headers
const yAlarmGridStart = 400; //top of the alarm grid section (bottom of the column headers)
let yAlarmGridEnd = yEnd;

const ALARM_TITLE_MAP: Record<string, string> = {
    Primary: "L1",
    Secondary: "L2",
    Tails: "L3",
}

class TransformerDetailGraphic extends React.Component<Props> {

    renderTransformerSection = () => {

        const xCenter = xTransformerStart + (xTransformerEnd - xTransformerStart) / 2;
        const heightOfTransformerSection = (yTransformerEnd - yTransformerStart)
        const heightOfOO = (heightOfTransformerSection * 0.7);
        const circleRadius = heightOfOO / 3;

        let yTransformerTopLineEnd = yTransformerStart + ((heightOfTransformerSection - heightOfOO) / 2); //end of the top line

        if (this.props.transformerData) {

            const transformerClass = AlarmSeverity.getAlarmStyles(this.props.transformerData.alarmSeverity, this.props.transformerData.hasVisnet && this.props.transformerData.isMonitored).stroke;

            return (
                <g>
                    <title>{this.props.transformerData.assetName}</title>
                    <line className="svgUncolouredStroke" x1={xCenter} y1={yTransformerStart} x2={xCenter} y2={yTransformerTopLineEnd} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></line>
                    <circle className={transformerClass} cx={xCenter} cy={yTransformerTopLineEnd + circleRadius} r={circleRadius} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></circle>
                    <circle className={transformerClass} cx={xCenter} cy={yTransformerTopLineEnd + (circleRadius * 2)} r={circleRadius} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></circle>
                    <line className="svgUncolouredStroke" x1={xCenter} y1={yTransformerTopLineEnd + (circleRadius * 3) + (strokeWidth / 2)} x2={xCenter} y2={yTransformerEnd - rectGridPadding} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></line>
                </g>
            )
        }
    }

    renderBusbarSection = () => {

        if (this.props.busbarData && this.props.busbarData.length > 0) {
    
            const busbarCount = this.props.busbarData.length;
            const busbarSpacing = ((yBusbarEnd - yBusbarStart + strokeWidth) - (strokeWidth * busbarCount)) / (busbarCount - 0.5);

            let busbarClass = "";
            let busbarStartIncrementer = yBusbarStart;
            let currentBusbarStart = busbarStartIncrementer;

            return this.props.busbarData.map((busbar: any) => {
                busbarClass = AlarmSeverity.getAlarmStyles(busbar.alarmSeverity, busbar.hasVisnet && busbar.isMonitored).stroke;

                currentBusbarStart = busbarStartIncrementer;

                busbarStartIncrementer += strokeWidth + busbarSpacing;

                return (
                    <line key={busbar.assetId} className={busbarClass} x1={xBusbarStart} y1={currentBusbarStart} x2={xBusbarEnd} y2={currentBusbarStart} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}>
                        <title>{busbar.assetName}</title>
                    </line>
                )
            })
        }
    }

    renderAlarmColumnHeader = (text: string, xAlarmRectStartPosition: number, columnWidth: number) => {

        let headerRectWidth = columnWidth - rectGridPadding;

        const title = ALARM_TITLE_MAP[text];

        return (
            <foreignObject x={xAlarmRectStartPosition} y={yAlarmGridStart} width={headerRectWidth} height={headerHeight}>
                <div className="svgTransformerDetail_GridTextCell svgFeederDetailHeader" style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                    {title}
                </div>
            </foreignObject>
        )

    }

    renderAlarmStatusColumn = (windingData: any, xAlarmRectStartPosition: number, columnWidth: number) => {

        const rowCount = this.props.alarmTypes.length;
        const alarmRectWidth = columnWidth - rectGridPadding;
        const alarmRectHeight = ((yAlarmGridEnd - yAlarmGridStart - headerHeight) / rowCount) - (rectGridPadding * 1.5);

        let severity = "";

        return this.props.alarmTypes.map((alarmTypeName: string, index: number) => {

            if (windingData.alarms) {
                let alarmMatch = windingData.alarms.find((windingAlarm: any) => windingAlarm.alarmName === alarmTypeName)

                if (alarmMatch) {
                    severity = alarmMatch.severity;
                }
                else {
                    severity = "";
                }
            }

            let svgAlarmStyle = AlarmSeverity.getAlarmStyles(severity, this.props.transformerData.hasVisnet && this.props.transformerData.isMonitored);

            let yAlarmRectStartPosition = yAlarmGridStart + headerHeight + (rectGridPadding * (index + 1)) + (alarmRectHeight * (index));

            return <rect key={`alarmCell${windingData.assetId}${alarmTypeName}`} className={svgAlarmStyle.fill} x={xAlarmRectStartPosition} y={yAlarmRectStartPosition} width={alarmRectWidth} height={alarmRectHeight} rx="3" />;
        });

    }

    renderAlarmTextColumn = () => {

        const textColumnWidth = xAlarmBoxSectonStart - (rectGridPadding * 2);
        const rowCount = this.props.alarmTypes.length;
        const rectHeight = ((yAlarmGridEnd - yAlarmGridStart - headerHeight) / rowCount) - (rectGridPadding * 1.5);

        return this.props.alarmTypes.map((alarmName: string, index: number) => {

            let yPosition = yAlarmGridStart + headerHeight + (rectGridPadding * (index + 1)) + (rectHeight * index);

            return (
                <foreignObject key={`${alarmName}foreignObject`} x={rectGridPadding} y={yPosition} width={textColumnWidth} height={rectHeight}>
                    <div className="svgTransformerDetail_GridTextCell" style={{ display: "flex", alignItems: "center", height: "100%" }}>
                        {alarmName}
                    </div>
                </foreignObject>
            )
        });

    }

    renderAlarmsByWinding = () => {
        const windingCount = this.props.transformerData.windings.length;
        const windingColumnWidth = (xEnd - xAlarmBoxSectonStart) / windingCount;

        return this.props.transformerData.windings.map((windingData: any, index: number) => {

            const xAlarmRectStartPosition = xAlarmBoxSectonStart + (windingColumnWidth * index);

            return (
                <g key={`${windingData.assetId}windingGroup`}>
                    {this.renderAlarmColumnHeader(windingData.assetName, xAlarmRectStartPosition, windingColumnWidth)}
                    {this.renderAlarmStatusColumn(windingData, xAlarmRectStartPosition, windingColumnWidth)}
                </g>
            )
        });

    }

    render() {

		yEnd = 508 + (this.props.alarmTypes.length * 144); 
		yAlarmGridEnd = yEnd;

        const screenWidth = window.innerWidth;
        const svgWidth = screenWidth < 850 ? "50%" : "80%";
        const margin = screenWidth < 850 ? "0 auto" : "0px";

        if (!this.props.transformerData) {

            return null;
        }
        else {

            return (
                <div style={{ width: svgWidth, height: "50%", margin: margin }}>

                    <svg viewBox={`0 0 ${xEnd} ${yEnd}`}>
                        {/* white background */}
                        <rect width="100%" height="100%" fill="#fff" />

                        {/* alarm labels */}
                        {this.renderAlarmTextColumn()}

                        {/* windings section: alarm grid */}
                        {this.renderAlarmsByWinding()}

                        {/* busbar section */}
                        {this.renderBusbarSection()}

                        {/* transformer section */}
                        {this.renderTransformerSection()}

                    </svg>
                </div >
            )
        }
    }
}

export default TransformerDetailGraphic;