import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TransformerDetailGraphic } from './GraphicComponents/TransformerDetailGraphic'
import { LoadingGraphic } from '../shared/LoadingGraphic'
import { AlarmTable } from './AlarmTable';
import { DataPointTypes } from '../../constants/datapointtypes';
import { AlarmConfig } from '../../constants/alarmconfig';
import DetailPanelCharts from './DetailPanelCharts'
import { useState } from 'react';

interface Props {
	loading: boolean;
	transformerAssetHierarchy: any;
	substationName: string;
	substation?: any;
	isAlarmHistory?: boolean;
	showAlarmUI: boolean;
	onExpandChart: () => void;
	expanded: boolean;
}

const defaultTransformerDataPointTypes = [DataPointTypes.VoltageMean30minutes]
const transformerDataPointTypes = [
	DataPointTypes.VoltageMean30minutes,
	DataPointTypes.ActivePower30min,
	DataPointTypes.TransformerTemperature,
	DataPointTypes.SignalStrength,
	DataPointTypes.ReactivePower30minutes,
	DataPointTypes.VoltageTHD30minutes,
];

export default function TransformerDetailView(props: Props) {
	const [graphicHidden, setGraphicHidden] = useState(false);

	if (props.loading) {
		return (
			<LoadingGraphic />
		)
	}
	else if (props.transformerAssetHierarchy) {
		const transformerAlarmTypes = props.showAlarmUI ? AlarmConfig.transformerAlarms : [];

		const windings = props.transformerAssetHierarchy.windings;
		let windingPhases: any[] = [];

		windings.forEach((winding: any) => {
			windingPhases = windingPhases.concat(winding.phases)
		})

		const onChartExpand = () => {
			props.onExpandChart();
			setGraphicHidden(!graphicHidden);
		}

		const xl = graphicHidden ? '12' : '6';

		return (
			<Container fluid className="px-0 pt-1" style={{ width: "99%" }} >
				<Row className="px-0 pb-1"  >
					<Col>
						<div style={{ fontWeight: 400, fontSize: "1.15em" }}>
							{props.substationName}
						</div>
						<div style={{ fontWeight: 200, fontSize: "1em" }}>
							Transformer: {props.transformerAssetHierarchy.assetName}
						</div>

					</Col>
				</Row>
				<Row className="px-0 panelFeederDetailBody" noGutters >
					{!graphicHidden && <Col xs="12" xl="6" className="px-0 pr-xl-2 pb-2 transformer-graphic">
						<TransformerDetailGraphic alarmTypes={transformerAlarmTypes} transformerData={props.transformerAssetHierarchy} busbarData={props.transformerAssetHierarchy.busbars} />
					</Col>}
					<Col xs="12" xl={xl} className="px-0">
						<Row>
							{props.showAlarmUI && <Col xs="12">
								<AlarmTable
									assetHeaderText="Phase"
									assetHeaderField="assetName"
									loading={props.loading}
									alarmsData={[props.transformerAssetHierarchy]}
									isAlarmHistory={props.isAlarmHistory} />
							</Col>
							}
							<Col xs="12" className="pt-2">
								<DetailPanelCharts
									title="Transformer Charts"
									phases={windingPhases}
									busbarAsset={props.transformerAssetHierarchy} 
									defaultDataPointTypes={defaultTransformerDataPointTypes} 
									dataPointTypesList={transformerDataPointTypes}
									onExpandChart={onChartExpand}
									expanded={props.expanded}
									graphicHidden={graphicHidden}
									substation={props.substation}
								/>
							</Col>
						</Row>
					</Col>
				</Row>

			</Container>
		)
	}
	else {
		return null;
	}

}
