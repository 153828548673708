import * as React from 'react';

interface LoadingOverlayProps {
    show: boolean;
    message?: string;
}

const BusyOverlay: React.SFC<LoadingOverlayProps> = (props) => {
    if (!props.show) {
        return null;
    }

    return (
        <div className="loading-overlay">
            <span className="loading-span">{(props.message ? props.message : '')}</span>
            <i style={{ color: '#fff'}} className="glyphicon glyphicon-refresh glyphicon-refresh-animate" /> 
        </div>
    );
}

export default BusyOverlay;