import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';

const tabHeight = '34px';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({

		appBar: {
			paddingTop: 0,
			paddingBottom: theme.spacing(1),
			backgroundColor: "inherit",

		},
		tabs: {
			minHeight: tabHeight,
			height: tabHeight,

		},
		tab: {
			minHeight: tabHeight,
			height: tabHeight,
			color: theme.palette.primary.main,
			textTransform: "capitalize",
			fontSize: "1em",
			fontWeight: 300
		},
		tabPanel: {
			display: 'flex',
			justifyContent: 'center',
			'& > *': {
				padding: theme.spacing(1),
				width: "100%",
				height: "100%",
			},
		},
	}),
);

interface TabViewProps {
	children?: React.ReactNode;
	tabs: TabProps[];
	disabled?: boolean;
}

export function TabView(props: TabViewProps) {
	const classes = useStyles();
	const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);

	const handleChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
		setSelectedTabIndex(newIndex);
	};

	return <React.Fragment>
		<AppBar position="static" color="inherit"
			className={classes.appBar}
			elevation={0}>
			<Tabs value={selectedTabIndex}
				onChange={handleChange}
				indicatorColor="secondary"
				textColor="secondary"
				centered
				classes={{ root: classes.tabs }}

			>
				{props.tabs.map((tab: TabProps) => <Tab key={`tab-${tab.label}`} classes={{ root: classes.tab }} disabled={props.disabled} {...tab} />)}

			</Tabs>
		</AppBar>
		<Divider variant="fullWidth" />
		{props.children && React.Children.map(props.children, (child, index) => { return <TabPanel selectedIndex={selectedTabIndex} index={index}> {child}</TabPanel> })}

	</React.Fragment>
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: any;
	selectedIndex: any;
}

function TabPanel(props: TabPanelProps) {
	const classes = useStyles();
	const { children, selectedIndex, index, ...other } = props;

	return (
		<div
			hidden={selectedIndex !== index}
			{...other}
			className={classes.tabPanel}
		>
			{selectedIndex === index && (children)}
		</div>
	);
}