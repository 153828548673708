import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles';

interface Props {
	height: string;
	width?: string;
}

const useStyles = makeStyles({
	fillWhite: { fill: '#FFFFFF' },
	fillNone: { fill: 'none' },
	text: {
		fontFamily: 'Roboto',
		fontWeight: 300,
		fontSize: '2em'
	}
});

export default function DetectLogo(props: Props) {

	const classes = useStyles();

	return (
		<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 287.4 84.5" width={props.width} height={props.height} >
			<title>EA Technology | Detect</title>
			<g>
				<g>
					<g>
						<path className={classes.fillWhite} d="M48,35l-3.4,3.4l-2.2,2.2c-0.9,0.9-1.8,1.5-2.9,2l-0.1,0.1c-0.6,0.2-1.2,0.5-2,0.5c-1.6,0.2-3.2,0.1-4.8-0.6
				l-0.5-0.2c-0.9-0.5-1.7-1-2.5-1.8c-0.9-0.9-1.6-1.9-2-3c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.2-0.9-0.2-1.2s-0.1-0.6-0.1-1v-0.5
				c0-2.5,0.9-4.5,2.6-6.3c1.4-1.4,3-2.2,5-2.5c1.4-0.2,2.8-0.1,4.1,0.4c-0.5,0.5-1.1,1.1-1.9,1.9c-1.2,1.2-2.5,2.5-3.9,3.9l-4,4
				l0.1,0.5c0,0.1,0.9,4.1,5.2,5.4l0.5,0.1L51.5,25l-3-3c-1.8-1.8-3.6-3-5.7-3.9c-4.2-1.7-8.9-1.7-13.1,0c-2.1,0.9-4.1,2.1-5.8,3.9
				c-1.7,1.7-3,3.6-3.9,5.8c-0.9,2.1-1.2,4.3-1.2,6.5s0.4,4.4,1.2,6.5s2.1,4.1,3.9,5.7c1.6,1.6,3.5,2.9,5.6,3.8
				c2.1,0.9,4.4,1.4,6.6,1.4c2.4,0,4.6-0.5,6.6-1.4c2.1-0.9,4-2.1,5.6-3.8l2.4-2.4C49.1,41.7,48.1,38.5,48,35 M84.5,51.9h-8.4V34.4
				c0-1.2-0.2-2.4-0.8-3.5c-0.5-1.1-1.1-2-1.9-2.9c-0.9-0.9-1.8-1.5-2.9-1.9c-1.1-0.5-2.2-0.8-3.5-0.8s-2.4,0.3-3.5,0.8
				s-2.1,1.1-2.9,1.9c-0.9,0.9-1.5,1.8-1.9,2.9c-0.5,1-0.8,2.2-0.8,3.5s0.3,2.4,0.8,3.5s1.1,2.1,1.9,2.9c0.9,0.9,1.8,1.5,2.9,1.9
				c1.1,0.5,2.2,0.8,3.5,0.8h7.4V52H67c-2.4,0-4.6-0.5-6.8-1.4c-2.1-0.9-4-2.1-5.6-3.8s-2.9-3.5-3.8-5.6s-1.4-4.4-1.4-6.8
				c0-2.4,0.5-4.8,1.4-6.8c0.9-2.1,2.1-4,3.8-5.5c1.6-1.6,3.5-2.9,5.6-3.8s4.4-1.4,6.8-1.4s4.8,0.5,6.9,1.4s3.9,2.1,5.5,3.8
				c1.6,1.6,2.9,3.4,3.8,5.5s1.4,4.4,1.4,6.8L84.5,51.9C84.6,51.9,84.5,51.9,84.5,51.9z M21.4,57.4v1.8h1.4v1h-1.4v4.5
				c0,0.3,0,0.5,0.1,0.6s0.4,0.3,0.6,0.3c0.1,0,0.4,0,0.6-0.1v1c-0.4,0.1-0.6,0.1-1,0.1c-0.5,0-1-0.1-1.2-0.5s-0.4-0.8-0.4-1.4v-4.5
				h-1.4v-1h1.4v-1.8C20.1,57.4,21.4,57.4,21.4,57.4z M27,66.5c-1,0-1.7-0.4-2.4-1s-0.9-1.5-0.9-2.6v-0.3c0-0.7,0.1-1.4,0.4-1.9
				c0.2-0.6,0.6-1,1.1-1.4s1-0.5,1.6-0.5c1,0,1.6,0.3,2.2,0.9c0.5,0.6,0.8,1.5,0.8,2.6v0.8H25c0,0.7,0.3,1.2,0.6,1.8
				c0.4,0.5,0.9,0.6,1.5,0.6c0.4,0,0.8-0.1,1.1-0.3c0.3-0.1,0.6-0.4,0.8-0.7l0.8,0.6C29.1,66,28.2,66.5,27,66.5 M26.9,60
				c-0.5,0-0.9,0.1-1.2,0.5c-0.4,0.4-0.5,0.9-0.6,1.5h3.6v-0.1c0-0.6-0.2-1.1-0.5-1.5C27.7,60.3,27.4,60,26.9,60 M34.1,65.5
				c0.5,0,0.9-0.1,1.1-0.4c0.4-0.2,0.5-0.6,0.5-1h1.1c0,0.4-0.1,0.8-0.4,1.1c-0.3,0.4-0.6,0.6-1,0.9c-0.5,0.2-0.9,0.4-1.4,0.4
				c-1,0-1.8-0.4-2.4-1s-0.9-1.5-0.9-2.8v-0.2c0-0.8,0.1-1.4,0.4-1.9s0.6-1,1.1-1.2s1-0.5,1.8-0.5c0.9,0,1.5,0.3,2,0.8
				s0.9,1.1,0.9,1.9h-1.1c0-0.5-0.3-0.9-0.5-1.1c-0.4-0.2-0.8-0.5-1.1-0.5c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.5-0.5,1.1-0.5,2v0.3
				c0,0.9,0.1,1.5,0.5,1.9C33,65.3,33.4,65.5,34.1,65.5 M39.5,60c0.5-0.6,1.2-1,2.1-1c1.5,0,2.2,0.9,2.4,2.6v4.8h-1.2v-4.8
				c0-0.5-0.1-0.9-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.1-1,0.4c-0.3,0.2-0.5,0.5-0.8,0.9v5.1h-1.2V56.3h1.2V60z M46.7,59.2V60
				c0.5-0.8,1.2-1,2.1-1c1.5,0,2.2,0.9,2.4,2.6v4.8H50v-4.8c0-0.5-0.1-0.9-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.1-1,0.4
				S47,61,46.8,61.4v5.1h-1.2v-7.2L46.7,59.2L46.7,59.2L46.7,59.2z M52.7,62.8c0-0.8,0.1-1.4,0.4-1.9c0.3-0.6,0.6-1,1.1-1.2
				s1.1-0.5,1.8-0.5c1,0,1.8,0.4,2.4,1s0.9,1.6,0.9,2.8v0c0,0.7-0.1,1.4-0.4,1.9c-0.2,0.5-0.6,1-1.1,1.2c-0.5,0.4-1.1,0.5-1.8,0.5
				c-1,0-1.8-0.4-2.4-1C53,64.8,52.7,63.9,52.7,62.8L52.7,62.8z M54,62.9c0,0.8,0.1,1.5,0.5,1.9c0.4,0.5,0.9,0.8,1.5,0.8
				s1.1-0.2,1.5-0.8s0.5-1.1,0.5-2.1c0-0.7-0.1-1.5-0.6-1.9C57,60.3,56.5,60,55.9,60s-1.1,0.2-1.5,0.7C54.1,61.3,54,61.9,54,62.9
				 M62.1,66.4h-1.2V56.2h1.2V66.4z M63.6,62.8c0-0.8,0.1-1.4,0.4-1.9c0.3-0.6,0.6-1,1.1-1.2s1.1-0.5,1.8-0.5c1,0,1.8,0.4,2.4,1
				s0.9,1.6,0.9,2.8v0c0,0.7-0.1,1.4-0.4,1.9s-0.6,1-1.1,1.2c-0.5,0.4-1.1,0.5-1.8,0.5c-1,0-1.8-0.4-2.4-1
				C63.9,64.8,63.6,63.9,63.6,62.8L63.6,62.8z M64.9,62.9c0,0.8,0.1,1.5,0.5,1.9c0.4,0.5,0.9,0.8,1.5,0.8s1.1-0.2,1.5-0.8
				s0.5-1.1,0.5-2.1c0-0.7-0.2-1.5-0.6-1.9c-0.4-0.5-0.9-0.7-1.5-0.7s-1.1,0.2-1.5,0.7C65,61.3,64.9,61.9,64.9,62.9 M71.4,62.8
				c0-1.1,0.2-2,0.7-2.8c0.5-0.6,1.2-1,2.1-1s1.6,0.2,2.1,1v-0.8h1.1v7.1c0,1-0.2,1.6-0.9,2.2c-0.5,0.5-1.2,0.7-2.2,0.7
				c-0.5,0-1-0.1-1.5-0.4c-0.5-0.2-0.9-0.5-1.1-0.9l0.6-0.7c0.5,0.6,1.1,1,2,1c0.6,0,1.1-0.1,1.4-0.5c0.4-0.4,0.5-0.9,0.5-1.4v-0.9
				c-0.5,0.6-1.1,0.9-2,0.9s-1.5-0.4-2-1C71.6,64.8,71.4,63.9,71.4,62.8 M72.6,62.9c0,0.9,0.1,1.5,0.5,1.9c0.4,0.5,0.8,0.8,1.4,0.8
				c0.7,0,1.4-0.4,1.8-1.1v-3.2c-0.4-0.8-1-1-1.8-1c-0.6,0-1.1,0.2-1.4,0.8C72.7,61.3,72.6,61.9,72.6,62.9 M81.6,64.7l1.8-5.4h1.4
				l-2.9,8.4c-0.5,1.2-1.1,1.7-2.1,1.7h-0.2L79,69.3v-1h0.4c0.4,0,0.7-0.1,1-0.2s0.4-0.5,0.6-0.9l0.3-0.7l-2.6-7.1H80L81.6,64.7z"/>
					</g>
				</g>
				<g>
					<rect x="109" y="12.4" className={classes.fillWhite} width="1" height="61.8" />
				</g>
			</g>
			<rect x="129.2" y="27" className={classes.fillNone} width="379" height="141" />
			<text transform="matrix(1 0 0 1 129.2448 57.0304)" className={`${classes.fillWhite} ${classes.text}`}>Detect</text>
		</svg>
	)
}
