import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails } from './Accordion/Accordion';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'react-bootstrap';
import { LoadingGraphic } from '../../shared/LoadingGraphic';
import TdrDeviceSettings from './TdrDeviceSettings';
import TdrDeviceSettingsAPIs, { DeviceDetails } from '../../Data/Tdr/TdrDeviceSettingsAPIs';
import { UserPermissionsContext } from '../../Contexts/UserPermissionsContext';
import { MessageToast, ToastMode } from '../../shared/MessageToast'
import moment from 'moment';

interface Props {
	instrumentAssetId: string;
}

const useStyles = makeStyles((theme) => ({

	expandIcon: {
		color: '#00AAE4',
		verticalAlign: 'bottom',
		height: 20,
		width: 20,
	},
	icon: {
		color: 'rgba(255, 255, 255, 0.75)',
		verticalAlign: 'bottom',
		height: 20,
		width: 20,
	},
	fullWidth: {
		width: "100%",

	},

	divider: {
		backgroundColor: theme.palette.grey[200],
		width: "100%"
	},
	typographyPrimary: {
		fontSize: '0.75em',
		fontWeight: 300,
		letterSpacing: '0.05em',

	},

}));

export default function TdrInstrumentDetails(props: Props) {
	const userPermissions = useContext(UserPermissionsContext);
	const classes = useStyles();
	const [accordionExpanded, setAccordionExpanded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deviceSettings, setDeviceSettings] = useState<DeviceDetails | undefined>(undefined)
	const [showToast, setShowToast] = useState(false);
	useEffect(() => {
		setLoading(true);

		const tdrDeviceSettingsAPIs = new TdrDeviceSettingsAPIs();
		tdrDeviceSettingsAPIs.getDeviceSettings(props.instrumentAssetId, userPermissions.tokenManager.checkTokenIsValid, handleGetSettingsSuccess, handleGetSettingsError)

	}, [props.instrumentAssetId])

	function handleAccordionChange(event: object, expanded: boolean) {
		setAccordionExpanded(expanded)
	}

	const handleGetSettingsSuccess = (settings: any) => {
		setDeviceSettings(settings);
		setLoading(false);
	}

	const handleGetSettingsError = () => {
		setDeviceSettings(undefined);
		setLoading(false);
		setShowToast(true);
	}

	function handleToastAutohide() {
		setShowToast(false);
	}

	function renderDetailRow(label: string, value: string) {
		return <Container fluid className={`px-0 `} >
			<Row noGutters>
				<Col xs={5} className="px-0" >
					<Typography className={classes.typographyPrimary} noWrap>{label} </Typography>
				</Col>
				<Col xs={7} className="px-0">
					<Typography className={classes.typographyPrimary} noWrap>{value}</Typography>
				</Col>
			</Row></Container>
	}

	return (

		<React.Fragment>
			<Divider className={classes.divider} />

			<Accordion className={classes.fullWidth} expanded={accordionExpanded} onChange={handleAccordionChange} elevation={0}  >
				<AccordionSummary expandIcon={accordionExpanded ? <ExpandMoreIcon className={classes.icon} /> : <FontAwesomeIcon icon={faPlus} className={`${classes.expandIcon}`} />}>
					<Row className="w-100 pb-2 " noGutters><h5 className="page-header">TDR Instrument Details</h5></Row>
					<Container fluid className={`px-0 pb-2 `}  >
						{loading
							? <div className="pb-2" style={{ height: "50px" }}>
								<LoadingGraphic />
							</div>
							: deviceSettings && <Row className="w-100" noGutters>
								<Col xs={{ span: 12, order: 1 }} lg={{ span: 6, order: 1 }} xl={{ span: 5, order: 1, offset: 1 }} className="px-0">{renderDetailRow("Machine ID: ", deviceSettings.machineId)}</Col>
								<Col xs={{ span: 12, order: 3 }} lg={{ span: 6, order: 2 }} xl={{ span: 5, order: 2, offset: 1 }} className="px-0 pt-2 pt-sm-1">{renderDetailRow("Version: ", deviceSettings.versionNumber)}</Col>
								<Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 3 }} xl={{ span: 5, order: 3, offset: 1 }} className="px-0 pt-2 pt-sm-1">{renderDetailRow("Last Updated: ", formatDateTime(deviceSettings.lastUpdated))}</Col>
							</Row>
						}

						{showToast && <Row noGutters className="ml-6" >
							<Col xs={{ span: 10, offset: 1 }}>
								<MessageToast text={"Unable to load TDR details at this time. Please try again later."} mode={ToastMode.Error} show={showToast} onAutohide={handleToastAutohide}></MessageToast>
							</Col>
						</Row>
						}
					</Container>

				</AccordionSummary>

				<AccordionDetails className={` d-flex justify-content-center  ${classes.fullWidth}`} >
					<Col xs={12} xl={9} className="px-0">
						{deviceSettings && <TdrDeviceSettings instrumentAssetId={props.instrumentAssetId} deviceSettings={deviceSettings} />}

					</Col>
				</AccordionDetails>

			</Accordion>
			<Divider className={classes.divider} />
		</React.Fragment>
	);
}

function formatDateTime(timestamp: string) {
	const dateFormat = 'DD/MM/YYYY HH:mm:ss';
	return moment.utc(timestamp).local().format(dateFormat);
}
