import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Fade from 'react-bootstrap/Fade'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import WarningIcon from '@material-ui/icons/Warning';
interface Props {
	show: boolean;
	mode: IconToastMode;
	autoHide?: boolean;
	onAutohide?: () => void;
	style?: React.CSSProperties;
}

export enum IconToastMode {
	Check,
	Warning,
	Error,
	Loading
}

const useStyles = makeStyles((theme) => ({

	icon: {
		'&:focus': {
			outline: 'none !important'
		},
	},
	iconLoading: {
		color: '#00AAE4'
	},
	iconSuccess: {
		color: theme.palette.success.main
	},
	iconError: {

		color: theme.palette.error.dark
	},
	iconWarning: {
		color: theme.palette.warning.dark
	},

}));

export default function IconToast(props: Props) {
	const classes = useStyles();

	const [showToast, setShowToast] = useState(false);

	useEffect(() => {
		props.show && setShowToast(props.show);
		props.autoHide && setTimeout(function () { handleToastClose() }, 5000);
	}, [props.show, props.autoHide, props.mode])

	function handleToastClose() {
		setShowToast(false);
		props.onAutohide && props.onAutohide();
	}

	function renderIcon() {
		if (props.mode === IconToastMode.Check) { return <CheckIcon className={`${classes.icon} ${classes.iconSuccess}`} style={props.style} /> }
		else if (props.mode === IconToastMode.Warning) { return <WarningIcon className={`${classes.icon} ${classes.iconWarning}`} style={props.style} /> }
		else if (props.mode === IconToastMode.Error) { return <CloseIcon className={`${classes.icon} ${classes.iconError}`} style={props.style} /> }
		else if (props.mode === IconToastMode.Loading) { return <CircularProgress className={`${classes.icon} ${classes.iconLoading}`} style={props.style} /> }
	}

	return (
		<Fade in={showToast}>
			<React.Fragment>
				{renderIcon()}
			</React.Fragment>
		</Fade>

	);
}
