import * as React from 'react'

interface Props {
	width: string;
	height: string;
	shieldColour?: string;
	gPathColour?: string;
	gPathEnabled?: boolean;
	serialNumber?: string;
}

export default class GuardIcon extends React.Component<Props> {
	render() {
		return (
			<svg width={this.props.width} height={this.props.height} viewBox="0 0 132 132">
				<g>
					{this.props.serialNumber && <title>Guard Serial Number: {this.props.serialNumber}</title>}
					<path fill={this.props.shieldColour || "#00aae4"}
					 	d="M 65.839524,0.54329053 C 55.671084,0.60848703 48.157121,5.3124309 39.384254,8.1318198 30.552741,10.97004 18.415215,13.36302 12.256255,14.557702 c -1.589652,0.308353 -3.1310327,0.4841 -5.360383,1.505277 -2.2293489,1.021191 -4.9643776,3.899602 -5.6632013,6.490008 -1.39764156,5.180813 0.054625,7.94192 1.3101011,14.018663 2.5048534,12.124001 7.3500419,36.556207 16.0633152,53.92982 8.713428,17.37391 21.288259,26.11883 28.293038,31.05974 7.004659,4.94081 9.733931,7.32017 13.536499,9.15764 1.897996,0.91714 5.138174,1.71299 7.966983,1.04005 2.828803,-0.67293 4.13682,-1.84245 5.217739,-2.61793 2.163797,-1.55235 4.908497,-3.6709 11.892747,-8.33792 6.984882,-4.66743 19.941187,-13.07583 28.818597,-30.149043 8.87734,-17.073086 13.61695,-41.097162 16.15458,-53.231561 1.26869,-6.066578 2.51796,-8.884305 1.65735,-13.665289 -0.43032,-2.390492 -2.52221,-5.459822 -4.70821,-6.790841 -2.186,-1.331019 -3.79414,-1.61937 -5.30356,-2.006097 C 116.12088,13.420161 104.51294,10.976027 94.411206,7.8809839 84.319234,4.7889345 75.854602,0.47904847 65.839524,0.54329053 Z M 66.309772,15.011909 c 4.123363,0.158826 12.476054,3.300294 23.282315,6.611189 10.33838,3.167543 21.171913,5.516716 26.762323,6.882923 -0.21634,1.509571 -0.49156,3.451701 -1.05412,6.141724 -2.54611,12.174922 -7.64919,35.767976 -14.88559,49.685172 -7.236315,13.917035 -16.666498,19.871723 -23.900045,24.705303 -5.374547,3.59138 -8.243276,5.66563 -10.204883,7.09817 z"/>
					{
						this.props.gPathEnabled && 
							<path fill={this.props.gPathColour || "#555"} 
								d="m 63.14897,21.299492 c -10.184815,0.44539 -19.920795,4.604785 -27.069543,11.55244 -7.428088,7.219141 -11.590228,16.961111 -11.953858,26.940249 -0.362545,9.949531 3.02224,19.69918 9.142232,27.57048 1.529948,1.967763 3.254163,3.85419 5.199822,5.588852 7.488713,6.67658 17.507657,10.350987 27.743265,10.174617 10.235625,-0.17637 20.109153,-4.193521 27.341616,-11.123759 l 2.128999,-2.040226 -0.285912,-30.110348 -29.239114,-0.320794 -0.16521,13.755427 15.15131,0.166548 0.09992,10.498267 C 76.920278,87.231298 71.42164,89.277409 65.951695,89.371656 59.569042,89.48163 52.942431,87.051878 48.272687,82.888547 47.033992,81.784176 45.875939,80.526891 44.80812,79.153497 40.595705,73.735651 38.25773,66.872799 38.498289,60.271012 38.73777,53.698822 41.611661,47.08571 46.32869,42.501371 c 4.526828,-4.399492 11.034626,-7.177956 17.477186,-7.459695 7.543388,-0.329873 15.511866,2.843815 20.581643,8.196859 L 95.046363,34.002636 C 86.973817,25.479048 75.16023,20.77423 63.14897,21.299492 Z"/>
					}
				</g>
			</svg>
		)
	}
}
