import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingGraphic } from '../shared/LoadingGraphic';
import TdrInstrumentDetails from './TDR/TdrInstrumentDetails';
import { EventsGrid } from './TDR/EventsGrid';
import { UserPermissionsContext, PermissionLevels } from '../Contexts/UserPermissionsContext';
import TdrData from '../Data/Tdr/TdrDataAPIs';
import TdrChart from './TDR/TdrChart';
import { MessageToast, ToastMode } from '../shared/MessageToast'
import ManualTrigger from './TDR/ManualTrigger'

interface Props {
	loading: boolean;
	instrument: any;
	substation: any;
	onExpandChart: () => void;
}

const TDRDetailView = (props: Props) => {
	const userPermissions = useContext(UserPermissionsContext);
	const userIsTdrConfigurator = (userPermissions.permissions.Tdr === PermissionLevels.Configurator || userPermissions.permissions.Tdr === PermissionLevels.CustomerConfigurator);

	const [tdrData, setTdrData] = useState([]);
	const [selectedTdrEvent, setSelectedTdrEvent] = useState<any>();
	const [tdrDataLoading, setTdrDataLoading] = useState(true);
	const [tdrDataError, setTdrDataError] = useState();
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastMode, setToastMode] = useState(ToastMode.Error);
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		getEvents();
	}, [props.substation, props.instrument]);

	function getEvents() {
		if (props.substation && props.instrument && props.instrument.id) {
			setTdrDataLoading(true);

			const tdrData = new TdrData();
			tdrData.getData(props.instrument.id, props.substation.id, 1, 1000, userPermissions.tokenManager.checkTokenIsValid, handleGetTdrDataSuccess, handleGetTdrDataError)
		}
	}

	const handleGetTdrDataSuccess = (dataResponse: any) => {
		const responseData = dataResponse.map((tdrEvent: any) => {
			tdrEvent.dataValue = JSON.parse(tdrEvent.dataValue);
			return tdrEvent;
		})

		setTdrData(responseData);
		setTdrDataLoading(false);
		setTdrDataError(undefined);
	}

	const handleGetTdrDataError = (error: any) => {
		setTdrData([]);
		setTdrDataLoading(false);
		setTdrDataError(error);
	}

	function handleViewEventClick(tdrEvent: any) {
		setSelectedTdrEvent(tdrEvent);
	}

	function handleBinaryDownloadClick() {
		if (selectedTdrEvent !== undefined) {
			const tdrData = new TdrData();
			selectedTdrEvent && tdrData.getFile(selectedTdrEvent.instrumentId, selectedTdrEvent.time, userPermissions.tokenManager.checkTokenIsValid, () => { }, handleGetTdrFileError)
		}
	}

	function handleToastAutohide() {
		setShowToast(false);
	}

	const handleGetTdrFileError = (error: any) => {
		setToastText("Unable to download TDR file at this time. Please try again later.");
		setToastMode(ToastMode.Error);
		setShowToast(true);

	}

	const handleGetManualTriggerSuccess = (response: any) => {
		getEvents();
		setSelectedTdrEvent(response);
	}

	const onChartExpand = () => {
		props.onExpandChart();
		setExpanded(!expanded);
	}

	if (props.loading) {
		return (
			<LoadingGraphic />
		)
	}
	else if (props.substation && props.instrument && props.instrument.id) {
		return (
			<Container fluid className="px-0 pt-1" style={{ width: "99%" }} >
				{!expanded && 
				<> 
					<Row className="px-0 pb-4"  >
						<Col>
							<div style={{ fontWeight: 400, fontSize: "1.15em" }}>
								{props.substation.name}
							</div>
							<div style={{ fontWeight: 200, fontSize: "1em" }}>
								TDR for VisNet {props.instrument.serialNo}
							</div>
						</Col>
					</Row>
					<Row className="px-0 pb-2" style={{ width: "100%" }} >
						<TdrInstrumentDetails instrumentAssetId={props.instrument.id} />
					</Row>
				</>}

				{(userIsTdrConfigurator && !expanded)
					&& <Row className="px-0 pb-2 d-flex justify-content-start" noGutters style={{ width: "99%" }} >
						<ManualTrigger instrumentAssetId={props.instrument.id} onGetManualTriggerSuccess={handleGetManualTriggerSuccess} />
					</Row>
				}
				<Row className="px-0 py-1 d-flex justify-content-center" noGutters style={{ width: "99%" }} >
					<EventsGrid loading={tdrDataLoading} tdrData={tdrData} dataError={tdrDataError} onViewEventClick={handleViewEventClick} style={{ maxHeight: "300px" }} />

				</Row>
				{showToast && <Row className="px-0 d-flex justify-content-center" style={{ width: "100%" }} >
					<MessageToast text={toastText} mode={toastMode} show={showToast} autoHide onAutohide={handleToastAutohide}></MessageToast>
				</Row>}
				<Row className="px-0 py-4" noGutters style={{ width: "99%" }} >
					{selectedTdrEvent && <TdrChart tdrEventData={selectedTdrEvent.dataValue} onBinaryDownloadClick={handleBinaryDownloadClick} enableBinaryDownload={userIsTdrConfigurator} onExpandChart={onChartExpand} expanded={expanded}></TdrChart>}

				</Row>
			</Container>
		)
	}
	else {
		return null;
	}

}

export default TDRDetailView;