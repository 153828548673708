import config from '../../../config';
import ApiResponseHandler from '../../shared/ApiResponseHandler';
import { saveAs } from 'file-saver';

export default class TdrData {

	getData(instrumentAssetId: string, substationAssetId: string, page: number, pageSize: number, checkTokenIsValid: any, onSuccess: any, onError: any): any {

		const apiResponseHandler = new ApiResponseHandler({});
		const endpointAddress = `${config.apiGateway.DATA_API}/api/Tdr/visnetid/${instrumentAssetId}/substation/${substationAssetId}/page/${page}/pagesize/${pageSize}`

		apiResponseHandler.callAPIGet(endpointAddress, onSuccess, onError, checkTokenIsValid);
	}

	getFile(instrumentAssetId: string, time: string, checkTokenIsValid: any, onSuccess: any, onError: any): any {

		const apiResponseHandler = new ApiResponseHandler({});
		const endpointAddress = `${config.apiGateway.DATA_API}/api/Tdr/file/visnetid/${instrumentAssetId}?time=${time}`
		function handleSuccess(response: any) {

			const byteArray = Uint8Array.from(atob(response), c => c.charCodeAt(0))

			const blob = new Blob([byteArray], { type: "application/octet-stream" });
			saveAs(blob, `${time}.trg`);

			onSuccess();
		}

		apiResponseHandler.callAPIGet(endpointAddress, handleSuccess, onError, checkTokenIsValid);
	}

	getManualTrigger(instrumentAssetId: string, checkTokenIsValid: any, onSuccess: any, onError: any): any {

		function tryParseJSON(jsonString: string) {
			try {
				const o = JSON.parse(jsonString);
				if (o && typeof o === "object") {
					return o;
				}
			}
			catch (e) { }

			return false;
		};

		function handleManualTriggerSuccess(response: any) {

			const jsonResponse = tryParseJSON(response);
			const data = jsonResponse && { dataValue: jsonResponse[0] };
			onSuccess(data);
		}

		const apiResponseHandler = new ApiResponseHandler({});
		const endpointAddress = `${config.apiGateway.META_API}/api/Tdr/trigger/visnetassetid/${instrumentAssetId}`

		apiResponseHandler.callAPIGet(endpointAddress, handleManualTriggerSuccess, onError, checkTokenIsValid);
	}

}