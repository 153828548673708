import { useCallback, useEffect } from 'react';
import { useDataLoadingReducer } from "../../Data/DataLoadingReducer";
import { DataAPIs } from '../../Data/DataAPIs';
import { Impedance, ImpedanceValue } from '../../shared/interfaces/Impedance';
import { DataPointTypes } from '../../../constants/datapointtypes';
import { objectPropertiesToLowerCase } from '../../shared/helpers/ObjectPropertiesToLowerCase';

export function useImpedanceData(assetID: number) {
	const [state, dispatch] = useDataLoadingReducer<Impedance | undefined>(undefined);

	const dataAPIs = new DataAPIs();

	const fetchData = useCallback(
		() => {
			dispatch({ type: "loading" });

			dataAPIs.getLatestDataByAssetAndDataPointType<string>(assetID, DataPointTypes.Impedance.dataPointTypeId)
				.then(response => {

					if (response instanceof Error) {
						dispatch({ type: "errored", message: response.message });
					} else {

						let impedanceData: Impedance = response && JSON.parse(response.dataValue);

						if (impedanceData) {
							//impedance data from the API could be lower case or PascalCase - so convert to lower case
							impedanceData = objectPropertiesToLowerCase<Impedance>(impedanceData);

							impedanceData.bestimpedance = objectPropertiesToLowerCase<ImpedanceValue>(impedanceData.bestimpedance);
							impedanceData.latestimpedance = objectPropertiesToLowerCase<ImpedanceValue>(impedanceData.latestimpedance);
						}

						dispatch({ type: "loaded", data: impedanceData });
					}
				})
				.catch(error => {
					dispatch({ type: "errored", message: error });
				})
		},
		[dataAPIs, dispatch, assetID, DataPointTypes.Impedance.dataPointTypeId]
	);

	useEffect(() => { assetID && fetchData(); }, []);

	return { ...state, refreshData: fetchData };

}
