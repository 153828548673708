import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { LoadingGraphic } from '../../shared/LoadingGraphic'
import { Card } from '../../shared/Card'

interface Props {
	loading: boolean;
	alarmsData: any;
	substations: any;
	feeders: any;
	preFaultAlarmsData: any[];
	substationsLoading: boolean;
	preFaultAlarmsLoading: boolean;
	feederListLoading: boolean;
	onAlarmCardClick: any;
	alarmFilters: any;
	onPrefaultCardClick: () => void;
	prefaultFilterApplied: boolean;
	isMonitoredFilterApplied: boolean;
	onIsMonitoredCardClick: () => void;
}

interface AlarmsList {
	alarmName: string;
	severity: string;
	assetIDs: Set<string>;
	substationAssetIDs: Set<string>;
	feederAssetIDs: Set<string>;
}

export const StatsCards = React.memo(function StatsCards(props: Props) {

	function buildAlarmList() {

		let alarmsList: AlarmsList[] = [];

		if (props.alarmsData && props.alarmsData.substations) {
			props.alarmsData.substations.forEach((alarmSubstation: any) => {

				alarmSubstation.alarms && alarmSubstation.alarms.forEach((activeAlarm: any) => {

					let alarmIndex = alarmsList.findIndex((obj: AlarmsList) => obj.alarmName === activeAlarm.alarmName);

					if (alarmIndex === -1) {
						alarmsList.push({
							alarmName: activeAlarm.alarmName,
							severity: activeAlarm.severity,
							assetIDs: new Set(),
							substationAssetIDs: new Set(),
							feederAssetIDs: new Set(),
						})

						alarmIndex = alarmsList.findIndex((obj: AlarmsList) => obj.alarmName === activeAlarm.alarmName);
					}

					alarmsList[alarmIndex].assetIDs.add(activeAlarm.assetId);
					alarmsList[alarmIndex].substationAssetIDs.add(alarmSubstation.substationId);

					if (activeAlarm.parentAsset) {
						activeAlarm.parentAsset.forEach((parentAsset: any) => {
							if (parentAsset.parentAssetTypeKey === "LvWay") {
								alarmsList[alarmIndex].feederAssetIDs.add(parentAsset.parentId);
							}

						})
					}
				});
			});
		}
		return alarmsList;
	}

	function handleAlarmCardClick(alarmName: string) {
		const filter = alarmName;

		props.onAlarmCardClick(filter);
	}

	function renderAlarmStats(alarmsList: AlarmsList[], severity: string, alarmClassName: string) {

		if (alarmsList) {
			let filteredAlarmsList = alarmsList.filter((obj: AlarmsList) => obj.severity === severity);

			return filteredAlarmsList.map((alarm: any) => {

				const filterIconSelected = props.alarmFilters.some((filter: any) => filter === alarm.alarmName);

				return (
					<Col key={`alarmCard-${alarm.alarmName}`} xs={6} className='pt-2 pr-1 pl-1'>
						<Card
							title={alarm.alarmName}
							className={`${alarmClassName}`}
							style={{ width: "100%", height: "80px", cursor: "pointer" }}
							showFilterIcon
							filterIconSelected={filterIconSelected}
							onClick={() => handleAlarmCardClick(alarm.alarmName)}
						>
							{renderAlarmAssetCounts(alarm.substationAssetIDs.size, alarm.feederAssetIDs.size)}
						</Card>
					</Col>
				);
			});
		}
	}

	function renderAssetCards() {
		let monitoredSubstationCount: number | JSX.Element = 0;
		let monitoredFeederCount: number | JSX.Element = 0;

		if (props.substationsLoading) {
			monitoredSubstationCount = renderCardLoadingGraphic();
		}
		else if (props.substations) {
			const monitoredSubstations = props.substations.filter((substation: any) => substation.isMonitored === true);
			monitoredSubstationCount = monitoredSubstations.length;
		}

		if (props.feederListLoading) {
			monitoredFeederCount = renderCardLoadingGraphic();
		}
		else if (props.feeders) {
			const monitoredFeeders = props.feeders.filter((feeder: any) => feeder.isMonitored === true)
			monitoredFeederCount = monitoredFeeders.length;
		}

		return (
			[
				renderAssetStats("No. of Monitored Substations", monitoredSubstationCount, true, props.isMonitoredFilterApplied, props.onIsMonitoredCardClick),
				//renderAssetStats("No. of Monitored LV Feeders", monitoredFeederCount)
			]
		)
	}

	function handlePrefaultClick(alarmLabel: string, substationCount: number) {

		const filter = alarmLabel;

		props.onPrefaultCardClick();
	}

	function renderPreFaultStats(cardLabel: string, preFaultAlarmsData: any) {

		const substationCount = preFaultAlarmsData ? preFaultAlarmsData.length : 0;

		const reducer = (accumulator: any, currentValue: any) => accumulator + currentValue.preFaultFeeders;
		let feederCount = preFaultAlarmsData ? preFaultAlarmsData.reduce(reducer, 0) : 0;

		const showFilterIcon = substationCount > 0 ? true : false;
		const cursor = substationCount > 0 ? "pointer" : "auto";

		const filterIconSelected = props.prefaultFilterApplied;

		return (
			<Col xs={6} className='pt-2 pr-1 pl-1'>
				<Card
					title={cardLabel}
					className={"bcCardPrefault"}
					style={{ width: "100%", height: "80px", cursor: cursor }}
					showFilterIcon={showFilterIcon}
					filterIconSelected={filterIconSelected}
					onClick={() => handlePrefaultClick(cardLabel, substationCount)}
				>
					{props.preFaultAlarmsLoading
						? renderCardLoadingGraphic()
						: renderAlarmAssetCounts(substationCount, feederCount)}
				</Card>
			</Col>
		);

	}

	function renderCardLoadingGraphic(): JSX.Element {
		return <div style={{ margin: "0 5px 0 5px", height: "65px", width: "85%" }}><LoadingGraphic small /></div>
	}

	if (props.loading) {
		return (
			<div style={{ height: "100px" }}>
				<LoadingGraphic />
			</div>
		)
	}
	else {

		let substationCount = 0;

		if (props.substations) {
			substationCount = props.substations.length;
		}

		const alarmsList = buildAlarmList();

		return (
			<Container className="pl-2 pr-1">
				<Row>
					{/*Display Red alarm cards*/}
					{alarmsList && alarmsList.length > 0 && renderAlarmStats(alarmsList, "RED", "bgAlarmRed")}

					{/*Display Pre-fault card*/}	
					{substationCount > 0 ? renderPreFaultStats("Pre-Fault", props.preFaultAlarmsData) : null}

					{/*Display Amber alarm cards*/}	
					{alarmsList && alarmsList.length > 0 && renderAlarmStats(alarmsList, "AMBER", "bgAlarmAmber")}

					{/*Display asset information cards*/}	
					{renderAssetCards()}
					
				</Row>
			</Container>
		)
	}
});

function buildCountRow(count: number, label: string, rowHeight: string) {
	let countClassname = "statsCards__count--small";

	if(count > 999){
		countClassname = "statsCards__count--small-fourDigits";
	}
	else if(count > 99){
		countClassname = "statsCards__count--small-threeDigits";
	}

	return (
		<Row noGutters className="align-items-center" style={{ height: `${rowHeight}` }}>
			<span className={`d-flex justify-content-end align-items-center ${countClassname}`}>{count}</span>
			<span className="pl-1 d-flex align-items-center statsCards__countLabel">{label}{count === 1 ? "" : "s"}</span>
		</Row>
	);
}

function renderAlarmAssetCounts(substationCount: number, feederCount: number) {

	let rowHeight = "100%";
	let assetCountRows = [];
	let feederRow = null;

	if (feederCount > 0) {
		rowHeight = "50%";
		feederRow = buildCountRow(feederCount, "feeder", rowHeight);
	}

	assetCountRows.push(buildCountRow(substationCount, "substation", rowHeight));
	assetCountRows.push(feederRow)

	return (
		<Container className="align-items-center" style={{ padding: "0 5px 0 5px", height: "55px" }} >
			{assetCountRows}
		</Container>
	)
}

function renderAssetStats(cardLabel: string, assetCount: number | JSX.Element, filterable?: boolean, filterIsEnabled?: boolean, onClick?: () => void) {

	let assetCountClassName = "statsCards__count--large"

	if (assetCount >= 100000) {
		assetCountClassName = "statsCards__count--medium"
	}

	return (
		<Col xs={6} className='pt-2 pr-1 pl-1'>
			<Card title={cardLabel} className={"bgCardDefault"} style={{ width: "100%", height: "80px" }} showFilterIcon={filterable}
				filterIconSelected={filterIsEnabled} onClick={onClick}>
				{typeof assetCount === "number"
					&& <div className={`${assetCountClassName}`}>
						{assetCount}
					</div>
				}
				{typeof assetCount === "object" && assetCount}
			</Card>
		</Col>
	)
}
