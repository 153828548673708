import React from 'react'
import AlarmSeverity from '../../utils/AlarmSeverity'
import GuardIcon from './GraphicComponents/GuardIcon'
import VisnetIcon from './GraphicComponents/VisnetIcon'
import InstrumentTypes from '../../constants/instrumenttypes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faHeart } from '@fortawesome/free-solid-svg-icons'

interface Props {
	transformerData?: any;
	onFeederClick: any;
	onTransformerClick: any;
	onGuardClick: any;
	onTDRClick: any;
	substationPrefaults?: any;
}

const strokeWidth = 12;
const xCenter = 350;
const xStart = 100;
const xEnd = 600;
const yStart = 30;
const yEnd = 800;

const maxFeederStrokeWidth = 55;

const busbarYstart = 390;
const busbarSpacing = 20;

class ComplexSubstationGraphic extends React.Component<Props> {
	renderGuardIcon = (connectedGuards: any) => {

		const yPosition = 30;
		const rectWidth = 60;
		const rectHeight = 70;

		return connectedGuards.map((guard: any, index: number) =>
			<foreignObject
				x={xCenter + 120 + ((index % 2) * 80)}
				y={yPosition + (80 * Math.floor((index / 2)))}
				width={rectWidth}
				height={rectHeight}
				onClick={() => { this.props.onGuardClick(this.props.transformerData) }}
				style={{ "cursor": "pointer" }}
			>
				<span>
					<GuardIcon serialNumber={guard.serialNo} width="60" height="60" gPathEnabled></GuardIcon>
				</span>
			</foreignObject>
		)
	}

	getIconXPosition = (index: number) => {
		if (index < 3) {
			return xStart - 20;
		}
		if (index >= 3 && index < 6) {
			return xStart + 90;
		}
		if (index >= 6 && index < 9) {
			return xStart + 355;
		}

		return xStart + 450;
	}

	getIconYPosition = (index: number) => {
		if (index < 3) {
			return index * 125;
		}
		if (index >= 3 && index < 6) {
			return (index - 3) * 125;
		}
		if (index >= 6 && index < 9) {
			return (index - 6) * 125;
		}

		return (index - 9) * 100;
	}
	
	renderVisnetIcons = (connectedVisnets: any) => {
		return connectedVisnets.map((visnet: any, index: number) => {
			return (
				<>
					<foreignObject x={this.getIconXPosition(index)} y={this.getIconYPosition(index)} width={60} height={250} style={{ "cursor": "pointer" }}>
						<span>
							<VisnetIcon width="90" height="70" visnetSerialNumber={visnet.serialNo} showTDR={visnet.tdrConnected} onTdrClick={() => this.props.onTDRClick(visnet)}></VisnetIcon>
						</span>
					</foreignObject>
					<text className="svgSubstationText visnetSerial" x={this.getIconXPosition(index) - 15} y={this.getIconYPosition(index) + 120}>{visnet.serialNo}</text>
				</>
			)
		})
	}

	renderTransformerSection = () => {

		if (this.props.transformerData) {

			const transformerClass = AlarmSeverity.getAlarmStyles(this.props.transformerData.alarmSeverity, this.props.transformerData.hasVisnet && this.props.transformerData.isMonitored).stroke;

			return (
				<g onClick={() => this.props.onTransformerClick(this.props.transformerData)} style={{ "cursor": "pointer" }}>
					<title>{this.props.transformerData.assetName}</title>
					<circle className={transformerClass} cx={xCenter} cy="170" r="80" fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></circle>
					<circle className={transformerClass} cx={xCenter} cy="250" r="80" fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></circle>
				</g>
			)
		}
	}

	renderBusbarSection = () => {

		if (this.props.transformerData.busbars && this.props.transformerData.busbars.length > 0) {
			let busbarClass = "";
			let busbarStartIncrementer = busbarYstart;
			let currentBusbarStart = busbarStartIncrementer;

			return this.props.transformerData.busbars.map((busbar: any) => {

				busbarClass = AlarmSeverity.getAlarmStyles(busbar.alarmSeverity, busbar.hasVisnet && busbar.isMonitored).stroke;

				currentBusbarStart = busbarStartIncrementer;

				busbarStartIncrementer += strokeWidth + busbarSpacing;

				return (
					<line key={busbar.assetId} className={busbarClass} x1={xStart} y1={currentBusbarStart} x2={xEnd} y2={currentBusbarStart} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}>
						<title>{busbar.assetName}</title>
					</line>
				)
			})
		}
	}

	renderFeederSection = (feederYstart: number) => {
		let feederCount = this.props.transformerData.feeders.length;
		let feederFullWidth = (xEnd - xStart) / feederCount;

		let feederStrokeWidth = feederFullWidth * 0.75;

		//set maximum feeder width to prevent huge feeder stroke if there are few feeders
		if (feederStrokeWidth > maxFeederStrokeWidth)
			feederStrokeWidth = maxFeederStrokeWidth;

		let feederXincrementer = xStart;
		let currentFeederPosition = 0;

		const prefaultIndicatorYPosition = feederYstart + 20;

		return this.props.transformerData.feeders.map((feeder: any) => {

			const feederHasPrefaults = this.props.substationPrefaults && this.props.substationPrefaults.feeders.some((prefaultFeeder: any) => prefaultFeeder.id === feeder.assetId && prefaultFeeder.affectedPhases > 0);

			let feederClass = AlarmSeverity.getAlarmStyles(feeder.alarmSeverity, feeder.hasVisnet && feeder.isMonitored).stroke;

			currentFeederPosition = feederXincrementer + feederFullWidth / 2;
			feederXincrementer += feederFullWidth;

			const prefaultIndicatorXPosition = currentFeederPosition - (feederStrokeWidth / 2);

			return (
				<React.Fragment>
					<line
						key={feeder.assetId}
						className={feederClass}
						x1={currentFeederPosition}
						y1={feederYstart}
						x2={currentFeederPosition}
						y2={yEnd}
						fill="rgba(0,0,0,0)"
						strokeWidth={feederStrokeWidth}
						onClick={() => this.props.onFeederClick(feeder, this.props.transformerData)}
						style={{ "cursor": "pointer" }}
					>
						<title>{feeder.assetName}</title>
					</line>

					{feederHasPrefaults &&
						<foreignObject x={prefaultIndicatorXPosition} y={prefaultIndicatorYPosition} width={feederStrokeWidth} height={30} style={{ "cursor": "pointer" }} onClick={() => this.props.onFeederClick(feeder, this.props.transformerData)}>
							<span className="d-flex justify-content-center">
								<span className="fa-layers fa-fw fa-2x">
									<FontAwesomeIcon icon={faHeart} className="text-white fa-stack"  />
									<FontAwesomeIcon icon={faHeartbeat} className="prefaultIcon fa-stack" title={`${feeder.assetName}: Pre-faults recorded`} transform={'shrink-2'} />
								</span>
							</span>
						</foreignObject>
					}
				</React.Fragment>
			)

		});
	}

	render() {
		let transformerTextWidth = 15; //approx width of the transformer text
		let transformerTextXPosition = (((yStart + busbarYstart) / 2) + transformerTextWidth) * -1;

		//calculate the position of the Y end of the busbar section, based on number of busbars, stroke width, and spacing between them
		let busbarCount = this.props.transformerData.busbars.length;
		let busbarYEnd = busbarYstart - (strokeWidth / 2) + (strokeWidth * busbarCount) + (busbarSpacing * (busbarCount - 1));

		let busbarTextWidth = 35; //approx width of the busbar text
		let busbarTextXPosition = (((busbarYstart + busbarYEnd) / 2) + busbarTextWidth) * -1;

		let feederYstart = busbarYEnd + (busbarSpacing / 2);
		let feederTextWidth = 55; //approx width of the feeder text
		let feederTextXPosition = (((feederYstart + yEnd) / 2) + feederTextWidth) * -1;

		const connectedVisnets = this.props.transformerData.instruments && this.props.transformerData.instruments.filter((instrument: any) => instrument.assetModel === InstrumentTypes.VisnetHub);
		const connectedGuards = this.props.transformerData.instruments && this.props.transformerData.instruments.filter((instrument: any) => instrument.assetModel === InstrumentTypes.Guard)

		return (
			<svg style={{ width: "100%" }} viewBox="0 0 630 800">

				{/* transformer section */}
				<text className="svgSubstationText" transform="rotate(-90)">
					<tspan x={transformerTextXPosition} y="40">TX</tspan>
				</text>
				<text className="svgSubstationText">
					<tspan x={275} y={20}>{this.props.transformerData.assetName}</tspan>
				</text>
				<line className="svgUncolouredStroke" x1={xCenter} y1={yStart} x2={xCenter} y2="90" fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></line>
				{this.renderTransformerSection()}
				<line className="svgUncolouredStroke" x1={xCenter} y1="335" x2={xCenter} y2={busbarYstart} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></line>

				{/* busbar section */}
				<text className="svgSubstationText" transform="rotate(-90)">
					<tspan x={busbarTextXPosition} y="40">VOLTS</tspan>
				</text>

				{this.renderBusbarSection()}

				{/* feeder section */}
				<text className="svgSubstationText" transform="rotate(-90)">
					<tspan x={feederTextXPosition} y="40">FEEDERS</tspan>
				</text>
				{this.renderFeederSection(feederYstart)}

				{this.props.transformerData.hasGuard && this.renderGuardIcon(connectedGuards)}

				{connectedVisnets && this.renderVisnetIcons(connectedVisnets)}

			</svg>
		)
	}
}

export default ComplexSubstationGraphic;