import React, { useContext } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { UserPermissionsContext } from './Contexts/UserPermissionsContext'
import DetectLogo from './childcomponents/GraphicComponents/DetectLogo'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link: {
			color: theme.palette.grey[400],
			'&:hover': {
				color: "#00AAE4",
				textDecoration: "underline"
			} 
		},
		title: {
			fontSize: '1.5rem',
			[theme.breakpoints.up('sm')]: {
				fontSize: '1.8rem',
			},
			[theme.breakpoints.up('md')]: {
				fontSize: '2rem',
			},
		},
	})
);
export default function Header() {
	const classes = useStyles();

	const userPermissions = useContext(UserPermissionsContext);
	function handleLogoutClick() {
		userPermissions.logout();
	}

	return <Navbar bg="dark" variant="dark" expand={"sm"} className="py-0" >

		<Navbar.Brand href="#home" className="py-0" >
			<Row noGutters className=" d-flex align-items-center">
				<Col xs="auto" className="p-0 h-100" >
					<DetectLogo height="70px" />
				</Col>
				
			</Row>
		</Navbar.Brand>

		{userPermissions.isAuthenticated
			&&
			<React.Fragment>
				<Navbar.Toggle />
				<Navbar.Collapse >
					<span className="d-flex justify-content-end w-100">
						<Nav.Link onClick={handleLogoutClick} className={` pr-1 ${classes.link}`}>
							<FontAwesomeIcon icon={faPowerOff}  />
							<span className="pl-1">Log Out</span>
						</Nav.Link>
			
					</span>
				</Navbar.Collapse>
			</React.Fragment>

		}
	</Navbar>
}