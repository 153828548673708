import React from 'react';
import Divider from '@material-ui/core/Divider';
import { Row, Col } from 'react-bootstrap';
import MarkerCounts  from './Models'

interface Props {
	markerCounts: MarkerCounts
}
export function MarkerClusterTooltip(props: Props) {
	const { markerCounts } = props;

	return <div>
		<h6 className='cluster-popup-header'>Total: {`${markerCounts.totalCount} substations`}</h6>
		<Divider />
		{
			markerCounts.greenCount > 0 &&
			<Row>
				<Col xs={1}><div className="cluster-legend-item green"></div></Col>
				<Col className="d-flex justify-content-start align-items-center" >{markerCounts.greenCount} substations</Col>
			</Row>
		}
		{
			markerCounts.amberCount > 0 &&
			<Row>
				<Col xs={1}><div className="cluster-legend-item amber"></div></Col>
				<Col className="d-flex justify-content-start align-items-center" >{markerCounts.amberCount} substations</Col>
			</Row>
		}
		{
			markerCounts.redCount > 0 &&
			<Row>
				<Col xs={1}><div className="cluster-legend-item red"></div></Col>
				<Col className="d-flex justify-content-start align-items-center" >{markerCounts.redCount} substations</Col>
			</Row>
		}
		{
			markerCounts.unmonitoredCount > 0 &&
			<Row>
				<Col xs={1}><div className="cluster-legend-item grey"></div></Col>
				<Col className="d-flex justify-content-start align-items-center" >{markerCounts.unmonitoredCount} substations</Col>
			</Row>
		}

	</div>
};