import React, { CSSProperties, useState, useEffect } from 'react';
import { LoadingGraphic } from '../../../shared/LoadingGraphic'
import moment from 'moment';
import { GridColumn, GridNoRecords, GridColumnMenuSort, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { Grid, DropdownFilterUI, DateTimeFilterUI, ColumnMenuCheckboxFilter } from '../../../shared/Grid'
import EventsGridActions from './EventsGridActions'
import { MessageToast, ToastMode } from '../../../shared/MessageToast'

interface Props {
	loading?: boolean;
	style?: CSSProperties;
	onViewEventClick: (tdrEvent: any) => void,
	tdrData: any;
	dataError: any;
}

export function EventsGrid(props: Props) {

	const [formattedData, setFormattedData] = useState([]);

	useEffect(() => {
		if (props.loading) {
			setFormattedData([]);
		}
		else{
			const data = formatData(props.tdrData)
			setFormattedData(data);
		}
	}, [props.tdrData, props.loading])

	function formatData(data: any) {
		return data.map((tdrDataItem: any) => {
			return {
				...tdrDataItem,
				formattedTime: tdrDataItem.time && moment.utc(tdrDataItem.time).local().format('DD/MM/YYYY HH:mm:ss')
			}
		});
	}

	function getFilterDropdown(data: any, field: string) {
		const fieldList = Array.from(new Set(data.map((p: any) => eval(`p.${field}`))));
		return DropdownFilterUI(fieldList, '');
	}

	function getFilterDateTime() {
		return DateTimeFilterUI("dd/MM/yyyy");
	}

	return (

		<React.Fragment>
			<div id="eventsGrid">
				<Grid
					gridData={formattedData}
					style={props.style}
					sortable

				>
					<GridColumn title="Date/Time" field="formattedTime" format="{0: dd/MM/yyyy HH:mm:ss}" filter="date" columnMenu={columnMenuProps =>
						<React.Fragment>
							<GridColumnMenuSort {...columnMenuProps} />
							<GridColumnMenuFilter
								{...columnMenuProps}
								filterUI={getFilterDateTime()}
							/>
						</React.Fragment>
					}
					/>
					<GridColumn title="Trigger Source"
						field="dataValue.PhaseString"
						columnMenu={columnMenuProps =>
							<React.Fragment>
								<GridColumnMenuSort {...columnMenuProps} />
								<ColumnMenuCheckboxFilter  {...columnMenuProps} data={formattedData} />
							</React.Fragment>
						}

					/>

					<GridColumn title="Event Type"
						field="dataValue.EventType"
						columnMenu={columnMenuProps =>
							<React.Fragment>
								<GridColumnMenuSort {...columnMenuProps} />
								<GridColumnMenuFilter
									{...columnMenuProps}
									filterUI={getFilterDropdown(formattedData, "dataValue.EventType")}
								/>
							</React.Fragment>
						}
					/>

					<GridColumn cell={EventsGridActions(props.onViewEventClick)} width="80px" />

					<GridNoRecords>
						{props.loading
							? <div className="pb-2 " style={{ height: "100px", width: "100%" }}>
								<LoadingGraphic />

							</div>
							: props.dataError
								? <MessageToast text={"Unable to load TDR events at this time. Please try again later."} mode={ToastMode.Error} show={true} />
								: "No events found."}
					</GridNoRecords>
				</Grid>
			</div>
		</React.Fragment>
	);
}

export default EventsGrid;