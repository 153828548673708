//alarm severity list - in order of least -> most severe
const AlarmSeverityList = ["NONE", "GREEN", "AMBER", "RED"];

const AlarmStyles = {
    Red: {
		key: "RED",
        fill: "svgAlarmFillRed",
        stroke: "svgAlarmRed",
        text: "alarmTextRed",
        color: "#E51E3D"
    },
    Amber: {
		key: "AMBER",
        fill: "svgAlarmFillAmber",
        stroke: "svgAlarmAmber",
        text: "alarmTextAmber",
        color: "#FAB722"
    },
    Green: {
		key: "GREEN",
        fill: "svgAlarmFillGreen",
        stroke: "svgAlarmNone",
        text: "alarmTextGreen",
        color: "#1C9D5C"
    },
    NotMonitored: {
		key: "NONE",
        fill: "svgAlarmFillNotMonitored",
        stroke: "svgAlarmNotMonitored",
        text: "alarmTextNotMonitored",
        color: "#A4A4A4"
    }
}

class AlarmSeverity {

    static alarmStyles = AlarmStyles;

    static getMaximumAlarmSeverityFromAlarmList = (alarms: any[], origSeverity?: string) => {
        let maxSeverity = "NONE";

        AlarmSeverityList.forEach((severity: string) => {
            if (alarms && alarms.some((alarm: any) => alarm.severity === severity)) {
                maxSeverity = severity;
            }
        })

        if (origSeverity) {
            let origSeverityIndex = AlarmSeverityList.indexOf(origSeverity);
            let maxSeverityIndex = AlarmSeverityList.indexOf(maxSeverity);

            if (origSeverityIndex > maxSeverityIndex) {
                maxSeverity = origSeverity;
            }
        }

        return maxSeverity;
    }

    static getAlarmStyles = (severity: string, isMonitored: boolean) => {

        let alarmStyle = null;

        if (isMonitored) {
            alarmStyle = AlarmStyles.Green;
        }
        else {
            alarmStyle = AlarmStyles.NotMonitored;
        }

        if (severity === "RED") {
            alarmStyle = AlarmStyles.Red;
        }
        else if (severity === "AMBER") {
            alarmStyle = AlarmStyles.Amber;
        }

        return alarmStyle;
    }

}

export default AlarmSeverity;