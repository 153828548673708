import * as React from 'react';
import * as _ from "lodash";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { SidePanel } from '../shared/SidePanel'
import ApiResponseHandler from '../shared/ApiResponseHandler';
import { MapPrimaryFocus } from '../childcomponents/Map';
import MapContainer from '../childcomponents/Map/MapContainer'
import { AlarmTable } from '../childcomponents/AlarmTable';
import ComplexSubstationView from '../childcomponents/ComplexSubstationView'
import config from '../../config';
import FeederDetailView from '../childcomponents/FeederDetailView';
import TransformerDetailView from '../childcomponents/TransformerDetailView';
import GuardDetailView from '../childcomponents/GuardDetailView';
import TDRDetailView from '../childcomponents/TDRDetailView';
import TimeSpanSlider from '../childcomponents/TimespanSlider';
import { Mark } from '../childcomponents/TimespanSlider';
import AlarmSeverity from '../../utils/AlarmSeverity';
import moment from 'moment';
import { AlarmConfig } from '../../constants/alarmconfig';
import InstrumentTypes from '../../constants/instrumenttypes'
import { FeedersByCustomer } from '../Data/FeedersByCustomer'
import { PrefaultEventsByCustomer, PrefaultEventsBySubstation } from '../Data/PrefaultEvents'
import { ActiveAlarmsByCustomer, AlarmHistoryByCustomer } from '../Data/Alarms'
import { UserPermissionsContext } from '../Contexts/UserPermissionsContext'
import SubstationFilters from '../childcomponents/SubstationFilters'
import CustomerDropdown from '../childcomponents/CustomerDropdown'
import { SubstationsByCustomer } from '../Data/SubstationsByCustomer'

interface State {
	leftPanelExpanded: boolean;
	rightPanelExpanded: boolean;
	transformerPanelExpanded: boolean;
	feederPanelExpanded: boolean;
	guardPanelExpanded: boolean;
	tdrPanelExpanded: boolean;
	customers?: any;
	customersLoading: boolean;
	selectedCustomer: string;
	substationsListLoading: boolean;
	feedersListLoading: boolean;
	selectedGeofence?: any;
	selectedGeofenceName?: string;
	substations: any[];
	substationsLocationFiltered: any[]
	filteredSubstations: any[];
	selectedSubstation: any;
	filteredFeeders: any[]
	feeders: any[],
	alarmsListLoading: boolean;
	alarmsList: any;
	filteredAlarms: any;
	preFaultCustomerDataLoading: boolean;
	preFaultCustomerData: any;
	showComplexSubstationModal: boolean;
	substationAssetHierarchyLoading: boolean;
	selectedFeeder: any;
	selectedTransformer: any;
	substationAssetHierarchyFormatted: any;
	substationPreFaultData: any;
	substationPreFaultDataLoading: boolean;
	selectedFeederPreFaultData: any;
	complexViewModalClassname: string;
	selectedAlarmTimespanStartDate: string | moment.Moment;
	selectedAlarmTimespanEndDate: string | moment.Moment;
	isAlarmHistory: boolean;
	mapWidth: string;
	mapPrimaryFocus: MapPrimaryFocus;
	showLoadingGraphics: boolean;
	selectedInstrument: any;
	alarmUIEnabled: boolean;
	rightPanelWidth: string;
	rightPanelChartExpanded: boolean;
}

interface Props {
	checkTokenIsValid: any;
}

const refreshDataIntervalTimeMS = 600000; //milliseconds
let refreshDataInterval: NodeJS.Timeout;

const leftPanelDefaultWidthPx = 300;
const rightPanelDefaultWidthPx = 500;
const xsScreenWidthBreakpointPx = 576;

const DEFAULT_RIGHT_PANEL_WIDTH = "60%";
const EXPANDED_RIGHT_PANEL_WIDTH = "80%";

export class MapDashboard extends React.Component<Props, State> {

	static contextType = UserPermissionsContext;
	context!: React.ContextType<typeof UserPermissionsContext>;

	apiResponseHandler = new ApiResponseHandler({});

	constructor(props: Props) {
		super(props)

		this.state = {
			customersLoading: false,
			substationsListLoading: false,
			feedersListLoading: false,
			alarmsListLoading: false,
			preFaultCustomerDataLoading: false,
			substationPreFaultDataLoading: false,
			feederPanelExpanded: false,
			guardPanelExpanded: false,
			tdrPanelExpanded: false,
			transformerPanelExpanded: false,
			substationAssetHierarchyLoading: false,
			leftPanelExpanded: true,
			rightPanelExpanded: false,
			selectedCustomer: "",
			selectedGeofence: null,
			selectedGeofenceName: "",
			substations: [],
			substationsLocationFiltered: [],
			filteredSubstations: [],
			selectedSubstation: null,
			filteredFeeders: [],
			feeders: [],
			selectedFeeder: null,
			selectedTransformer: null,
			alarmsList: [],
			filteredAlarms: [],
			preFaultCustomerData: null,
			showComplexSubstationModal: false,
			substationAssetHierarchyFormatted: null,
			substationPreFaultData: null,
			selectedFeederPreFaultData: null,
			complexViewModalClassname: "",
			selectedAlarmTimespanStartDate: "NOW",
			selectedAlarmTimespanEndDate: "NOW",
			isAlarmHistory: false,
			mapWidth: `calc(100% - ${leftPanelDefaultWidthPx}px)`,
			mapPrimaryFocus: MapPrimaryFocus.Substations,
			showLoadingGraphics: true,
			selectedInstrument: null,
			alarmUIEnabled: false,
			rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH,
			rightPanelChartExpanded: false,
		};

	}

	componentDidMount() {
		this.setState({ customersLoading: true });
	}

	handleGetCustomersSuccess = (response: any) => {

		if (response) {

			//if the user has access to one customer, get the substations for that customer. Otherwise, populate the dropdown so the user can select a customer.
			if (response.length === 1) {

				this.setState({
					customersLoading: false,
					customers: response,
					selectedCustomer: response[0].id
				}, () => {
					this.handleCustomerChange(response[0].id);
				}
				);

			}
			else {
				this.setState({
					customersLoading: false,
					customers: response
				});
			}
		}
	}

	handleAutoDataRefresh = () => {

		this.setState({
			preFaultCustomerDataLoading: true,
			alarmsListLoading: true,
			showLoadingGraphics: false
		})
	}

	handleCustomerChange = (customerId: string) => {

		if (customerId) {
			const userCanViewAlarms = this.context.permissions.checkAlarmAccess(customerId);

			//default right panel to closed on extra small and small screens
			let defaultRightPanelExpanded = userCanViewAlarms && (window.innerWidth <= 768 ? false : true);

			clearInterval(refreshDataInterval);
			refreshDataInterval = setInterval(this.handleAutoDataRefresh, refreshDataIntervalTimeMS);

			this.setState({
				selectedCustomer: customerId,
				selectedSubstation: "",
				feedersListLoading: true,
				preFaultCustomerDataLoading: true,
				substationsListLoading: true,
				substations: [],
				filteredSubstations: [],
				substationsLocationFiltered: [],
				alarmsListLoading: userCanViewAlarms,
				alarmUIEnabled: userCanViewAlarms,
				showLoadingGraphics: true,
				alarmsList: [],
				rightPanelExpanded: defaultRightPanelExpanded,
				feederPanelExpanded: false,
				guardPanelExpanded: false,
				tdrPanelExpanded: false,
				transformerPanelExpanded: false
			}, () => this.calculateMapWidth())
		}
	}

	handleSubstationFilterChange = (substationsList: any[], filteredPreFaultSubstations: any[], filteringByAreaOnly: boolean) => {

		let mapFocus = this.state.selectedGeofence && filteringByAreaOnly ? MapPrimaryFocus.Geofence : MapPrimaryFocus.Substations;

		this.setState({
			filteredSubstations: substationsList,
			mapPrimaryFocus: mapFocus,
		}, () => { this.applyFiltersBySubstationList(this.state.filteredSubstations) });
	}

	applyFiltersBySubstationList = (filteredSubstations: any) => {

		if (filteredSubstations === this.state.substations) {

			this.setState({
				filteredFeeders: this.state.feeders,
				filteredAlarms: this.state.alarmsList,
			});
		}
		else {
			this.setState({
				filteredFeeders: this.filterFeedersBySubstationList(filteredSubstations),
				filteredAlarms: this.filterAlarmsBySubstation(filteredSubstations),
			});
		}

	}

	handleGetPreFaultDataByCustomerSuccess = (prefaultData: any) => {

		if (prefaultData && prefaultData.substations) {
			prefaultData.substations = prefaultData.substations.filter((substation: any) => substation.preFaultFeeders > 0);

		}

		this.setState({
			preFaultCustomerDataLoading: false,
			preFaultCustomerData: prefaultData.substations,
		});
	}

	handleGetAlarmsSuccess = (response: any) => {
		this.setState({
			alarmsListLoading: false,
			alarmsList: response,
		}, () => this.applyFiltersBySubstationList(this.state.filteredSubstations));
	}

	filterAlarmsBySubstation = (substations: any[]) => {

		//filter alarms to only include substations that we're looking at
		let filteredAlarmsList = [];
		if (substations && this.state.alarmsList && this.state.alarmsList.substations) {
			filteredAlarmsList = this.state.alarmsList.substations.filter((alarmSubstation: any) => {

				let substationIndex = substations.findIndex((substation: any) => substation.id === alarmSubstation.substationId);

				if (substationIndex === -1) { return false }
				else { return true }
			});
		}

		return { substations: filteredAlarmsList };
	}

	filterAlarmsByAssetList = (assets: any[], alarms: any[]) => {
		let filteredAlarmsList: any = [];
		if (assets && alarms) {
			assets.forEach((asset: any) => {
				alarms.forEach((alarm: any) => {
					if (alarm.assetId === asset.assetId) {
						filteredAlarmsList.push(alarm);
					}
				})
			})
		}

		return filteredAlarmsList;
	}
	handleGetFeedersSuccess = (feedersResponse: any) => {
		this.setState({
			filteredFeeders: feedersResponse,
			feedersListLoading: false,
			feeders: feedersResponse
		});

	}

	handleGetCustomerSubstationsSuccess = (substationsResponse: any) => {

		this.setState({
			filteredSubstations: substationsResponse,
			substationsListLoading: false,
			substationsLocationFiltered: substationsResponse,
			substations: substationsResponse
		});

	}

	handleError(errorMessage: string) {
		let result = {
			show: true,
			warn: true,
			message: JSON.stringify(errorMessage),
			error: true
		};
	}

	filterFeedersBySubstationList = (substations: any) => {

		let substationIDs = substations.map((substation: any) => substation.id)
		let feeders: any = [];

		if (this.state.feeders) {
			feeders = this.state.feeders.filter((feeder: any) => {
				return substationIDs.includes(feeder.substationId)
			});
		}

		return feeders;
	}

	handleGeofenceChange = (geofenceName: string, geofence?: any) => {
		let mapFocus = MapPrimaryFocus.Substations;
		if (geofence) {
			mapFocus = MapPrimaryFocus.Geofence;
		}

		this.setState({
			selectedGeofence: geofence,
			selectedGeofenceName: geofenceName,
			mapPrimaryFocus: mapFocus
		});
	}

	getSubstationAssetHierarchy = (substationId: string) => {
		this.setState({
			substationAssetHierarchyLoading: true
		}, () => {
			const baseAddress = config.apiGateway.META_API;

			const connectedChannelsEndpointAddress = `${baseAddress}/api/Assets/substationHierarchyReport/substationid/${substationId}?onlyConnected=true&returnBusbars=true`;
			this.apiResponseHandler.callAPIGet(connectedChannelsEndpointAddress, () => { }, this.handleError, this.props.checkTokenIsValid)
				.then((connectedChannels) => {
					const endpointAddress = `${baseAddress}/api/Assets/hierarchyTree/${substationId}`;
					this.apiResponseHandler.callAPIGet(endpointAddress, (apiResponse: any) => this.handleGetAssetHierarchySuccess(apiResponse, connectedChannels), this.handleError, this.props.checkTokenIsValid);
				});
		})
	}

	assetHasInstrumentType = (assetId: number, instrumentType: InstrumentTypes, connectedChannels: any): boolean => {
		const instruments = this.getAssetInstruments(assetId, connectedChannels);

		const assetHasInstrumentType = instruments && instruments.some((instrument: any) => {
			return instrument.assetModel.trim().toLowerCase() === instrumentType.toLowerCase()
		})

		return assetHasInstrumentType;
	}

	getAssetInstruments = (assetId: any, substationHierarchyReport: any) => {

		const asset = this.findNestedObject(substationHierarchyReport, "id", assetId)

		if (asset && asset.connectedInstruments) {
			let instruments = substationHierarchyReport.instruments.filter((instrument: any) => asset.connectedInstruments.includes(instrument.id));

			instruments.forEach((instrument: any) => {
				if (instrument.assetModel === InstrumentTypes.VisnetHub) {
					instrument.tdrConnected = substationHierarchyReport.instruments.some((substationInstrument: any) => substationInstrument.assetModel === "TDR" && substationInstrument.assetTag === instrument.id.toString())
				}
			});

			return instruments;
		}
	}

	findNestedObject = (obj: any, key: any, val: any) => {
		if (obj && obj[key] === val) {
			return obj;
		}
		else {
			let foundObj = null;
			obj && Object.keys(obj).every((objKey) => {
				if (typeof obj[objKey] == 'object') {
					foundObj = this.findNestedObject(obj[objKey], key, val);
					if (foundObj) {
						//found the object - break out of the .every()
						return false;
					}
				}
				return true;
			})

			if (foundObj) {
				return foundObj;
			}
		}
	}

	handleGetAssetHierarchySuccess = (assetHierarchy: any, connectedChannels: any) => {

		let feederDataAssetIDs: any = [];
		let transformerAndBusbarDataAssetIDs: any = []; //transformers and busbars share phase assets

		if (assetHierarchy.head.assetId === this.state.selectedSubstation.id) {

			let substation = this.state.selectedSubstation;

			const substationAlarms = this.filterAlarmsBySubstation([substation]);
			let alarms: any[] = [];

			if (substationAlarms && substationAlarms.substations && substationAlarms.substations.length > 0) {
				alarms = substationAlarms.substations[0].alarms;
			}

			let modalClassName = "";

			let realSubstationFeeders: any = [];

			if (this.state.feeders) {
				realSubstationFeeders = this.state.feeders.filter((realFeeder: any) => realFeeder.substationId === assetHierarchy.head.assetId)
			}

			let formattedSubstationHierarchy: any = {
				assetId: assetHierarchy.head.assetId,
				assetTypeKey: assetHierarchy.head.assetTypeKey,
				assetName: assetHierarchy.head.assetName,
				alarmSeverity: AlarmSeverity.getMaximumAlarmSeverityFromAlarmList(alarms),
				isMonitored: substation.isMonitored,
				transformers: []
			};

			let transformers = assetHierarchy.head.children.filter((child: any) => child.assetTypeKey.trim() === "Transformer");

			if (transformers && transformers.length > 0) {

				if (transformers.length >= 2) {
					modalClassName = "modal-tx-2"
				}

				transformers.forEach((transformer: any) => {
					const transformerHasVisnet = this.assetHasInstrumentType(transformer.assetId, InstrumentTypes.VisnetHub, connectedChannels);
					const transformerHasGuard = this.assetHasInstrumentType(transformer.assetId, InstrumentTypes.Guard, connectedChannels);
					const transformerConnectedInstruments = this.getAssetInstruments(transformer.assetId, connectedChannels);

					//get the feeders under this transformer
					let feederJson: any = [];
					let lvBoard = transformer.children.filter((child: any) => child.assetTypeKey.trim() === "LvBoard");
					let feeders = lvBoard[0].children.filter((child: any) => child.assetTypeKey.trim() === "LvWay");

					if (feeders && feeders.length > 0) {
						feeders.forEach((feeder: any) => {

							let realFeeder = realSubstationFeeders.find((obj: any) => obj.id === feeder.assetId);

							if (realFeeder) {

								let phases: any = [];

								feeder.children.forEach((feederChild: any) => {
									if (feederChild.assetTypeKey.startsWith("Feeder")
										&& feederChild.assetTypeKey.trim().endsWith("Phase")) {

										const phaseAlarms = alarms.filter((alarm: any) => alarm.assetId === feederChild.assetId);

										const phase = {
											assetId: feederChild.assetId,
											assetName: feederChild.assetName,
											assetTypeKey: feederChild.assetTypeKey,
											alarms: phaseAlarms
										};

										phases.splice(_.sortedIndexBy(phases, phase, 'assetTypeKey'), 0, phase);

										feederDataAssetIDs.push(feederChild.assetId);
									}
								})

								const feederAlarmSeverity = this.getAssetHierarchyAlarmsMaxSeverity(feeder, alarms, "");
								const feederWideAlarms = alarms.filter((alarm: any) => alarm.assetId === feeder.assetId);
								const feederAlarmsList = [...feederWideAlarms, ...this.filterAlarmsByAssetList(feeder.children, alarms)]
								const feederHasVisnet = this.assetHasInstrumentType(feeder.assetId, InstrumentTypes.VisnetHub, connectedChannels);
								const feederHasGuard = this.assetHasInstrumentType(feeder.assetId, InstrumentTypes.Guard, connectedChannels);
								const feederHasStreetSense = this.assetHasInstrumentType(feeder.assetId, InstrumentTypes.StreetSense, connectedChannels);

								feederJson.push({
									assetId: feeder.assetId,
									assetName: feeder.assetName,
									assetTypeKey: feeder.assetTypeKey,
									alarmSeverity: feederAlarmSeverity,
									alarms: feederAlarmsList,
									isMonitored: realFeeder.isMonitored,
									hasVisnet: feederHasVisnet,
									hasGuard: feederHasGuard,
									hasStreetSense: feederHasStreetSense,
									phases: phases
								});
							}
						});
					}

					//get the busbars for this transformer
					let busbars = assetHierarchy.head.children.filter((child: any) => child.assetTypeKey.trim() === "Busbar");
					let busbarsJson: any = [];

					if (busbars && busbars.length > 0) {
						busbars.forEach((busbar: any) => {

							transformerAndBusbarDataAssetIDs.push(busbar.assetId);

							let phases: any = [];

							//phase assets are a child of both the transformer windings and the busbars. If the busbar and the windings share a phase asset, then the busbar belongs to the transformer.
							let isBusbarInThisTransformer = false;

							if (busbar.children && busbar.children.length > 0) {
								busbar.children.forEach((busbarPhase: any) => {

									isBusbarInThisTransformer = this.containsNestedObject(transformer, "assetId", busbarPhase.assetId);

									if (isBusbarInThisTransformer) {
										transformerAndBusbarDataAssetIDs.push(busbarPhase.assetId);

										phases.push({
											assetId: busbarPhase.assetId,
											assetName: busbarPhase.assetName,
											assetTypeKey: busbarPhase.assetTypeKey,
										})
									}
								})
							}

							if (isBusbarInThisTransformer) {
								let busbarAlarms = alarms.filter((alarm: any) => AlarmConfig.busbarAlarms.includes(alarm.alarmName));
								let busbarAlarmSeverity = this.getAssetHierarchyAlarmsMaxSeverity(busbar, busbarAlarms, "")

								const formattedBusbar = {
									assetId: busbar.assetId,
									assetName: busbar.assetName,
									assetTypeKey: busbar.assetTypeKey,
									alarmSeverity: busbarAlarmSeverity,
									hasVisnet: transformerHasVisnet,
									hasGuard: transformerHasGuard,
									isMonitored: substation.isMonitored,
									phases: phases
								};

								busbarsJson.splice(_.sortedIndexBy(busbarsJson, formattedBusbar, 'assetName'), 0, formattedBusbar);
							}
						});
					}

					//get the maximum alarm severity for this transformer - transformer-specific alarms only
					let transformerAlarms = alarms.filter((alarm: any) => AlarmConfig.transformerAlarms.includes(alarm.alarmName));
					let transformerAlarmSeverity = this.getTransformerAlarmsMaxSeverity(transformer, transformerAlarms)

					//get the windings under this transformer
					let windingsJson: any = [];
					let windings = transformer.children.filter((child: any) => (child.assetTypeKey.trim() === "PrimaryWinding"
						|| child.assetTypeKey.trim() === "SecondaryWinding"
						|| child.assetTypeKey.trim() === "ShortTail"));

					if (windings && windings.length > 0) {
						windings.forEach((winding: any) => {

							let phases: any = [];

							winding.children.forEach((windingChild: any) => {
								if (windingChild.assetTypeKey.startsWith("Tx")
									&& windingChild.assetTypeKey.trim().endsWith("Phase")) {

									let phaseAlarms = alarms.filter((alarm: any) => alarm.assetId === windingChild.assetId)

									phases.push({
										assetId: windingChild.assetId,
										assetName: windingChild.assetName,
										assetTypeKey: windingChild.assetTypeKey,
										alarms: phaseAlarms
									})
								}
							})

							let windingAlarmSeverity = this.getAssetHierarchyAlarmsMaxSeverity(winding, alarms, "");
							let windingAlarmsList = this.filterAlarmsByAssetList(winding.children, alarms)

							windingsJson.push({
								assetId: winding.assetId,
								assetName: winding.assetName,
								assetTypeKey: winding.assetTypeKey,
								alarmSeverity: windingAlarmSeverity,
								alarms: windingAlarmsList,
								phases: phases
							});
						});
					}

					formattedSubstationHierarchy.transformers.push({
						assetId: transformer.assetId,
						assetName: transformer.assetName,
						assetTypeKey: transformer.assetTypeKey,
						isMonitored: substation.isMonitored,
						hasVisnet: transformerHasVisnet,
						hasGuard: transformerHasGuard,
						instruments: transformerConnectedInstruments,
						alarmSeverity: transformerAlarmSeverity,
						alarms: transformerAlarms,
						feeders: feederJson,
						busbars: busbarsJson,
						windings: windingsJson
					});

				});
			}

			this.setState({
				substationAssetHierarchyFormatted: formattedSubstationHierarchy,
				substationAssetHierarchyLoading: false,
				complexViewModalClassname: modalClassName
			})
		}
	}

	handleGetSubstationPreFaultData = (substationPreFaultData: any) => {
		this.setState({
			substationPreFaultDataLoading: false,
			substationPreFaultData: substationPreFaultData
		})
	}

	containsNestedObject = (obj: any, key: any, val: any) => {
		if (obj[key] === val) {
			return true;
		}

		if (obj.children) {
			let isMatch = false;

			obj.children.every((child: any) => {
				isMatch = this.containsNestedObject(child, key, val);

				//if it's a match, break out of the .every() - we don't need to look any further
				if (isMatch) { return false }
				else { return true }

			})
			return isMatch;
		}

		return false;
	}

	getAssetHierarchyAlarmsMaxSeverity = (asset: any, alarms: any, maxSeverity: string) => {

		if (asset && alarms && alarms.length > 0) {

			let alarmsByAsset = alarms.filter((alarm: any) => alarm.assetId === asset.assetId);
			maxSeverity = AlarmSeverity.getMaximumAlarmSeverityFromAlarmList(alarmsByAsset, maxSeverity);

			asset.children.forEach((child: any) => {
				maxSeverity = this.getAssetHierarchyAlarmsMaxSeverity(child, alarms, maxSeverity);
			});
		}

		return maxSeverity;
	}

	getTransformerAlarmsMaxSeverity = (transformer: any, alarms: any) => {
		let maxSeverity = "NONE";

		if (transformer && alarms && alarms.length > 0) {

			let children = transformer.children.filter((child: any) => (child.assetTypeKey.trim() === "PrimaryWinding"
				|| child.assetTypeKey.trim() === "SecondaryWinding"
				|| child.assetTypeKey.trim() === "ShortTail"));

			if (children && children.length > 0) {
				children.forEach((child: any) => {

					maxSeverity = this.getAssetHierarchyAlarmsMaxSeverity(child, alarms, maxSeverity);
				});
			}

		}

		return maxSeverity;
	}

	handleLeftPanelToggle = () => {
		this.setState({ leftPanelExpanded: !this.state.leftPanelExpanded });
	}

	handleLeftPanelStateChange = (componentState: any) => {
		this.setState({ leftPanelExpanded: componentState.isOpen }, () => this.calculateMapWidth())
	}

	handleRightPanelToggle = () => {
		this.setState({ rightPanelExpanded: !this.state.rightPanelExpanded });
	}

	handleRightPanelStateChange = (componentState: any) => {
		this.setState({ rightPanelExpanded: componentState.isOpen }, () => this.calculateMapWidth())
	}

	handleFeederSelect = (selectedFeeder: any, selectedTransformer: any) => {
		let feederPreFaultData: any = null;

		if (selectedFeeder) {
			this.closeComplexSubstationModal();

			if (this.state.substationPreFaultData && this.state.substationPreFaultData.feeders) {
				feederPreFaultData = this.state.substationPreFaultData.feeders.find((prefaultFeeder: any) => prefaultFeeder.id === selectedFeeder.assetId)
			}
		}

		this.setState({
			feederPanelExpanded: !this.state.feederPanelExpanded,
			selectedFeeder: selectedFeeder,
			selectedTransformer: selectedTransformer,
			selectedFeederPreFaultData: feederPreFaultData,
		});
	}

	handleFeederPanelStateChange = (componentState: any) => {
		this.setState({ feederPanelExpanded: componentState.isOpen });
		this.setState({ rightPanelChartExpanded: !this.state.rightPanelExpanded})
		if (!componentState.isOpen) {
			this.setState({ rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH });
		}
	}

	handleTransformerSelect = (selectedTransformer: any) => {
		if (selectedTransformer) {
			this.closeComplexSubstationModal();
		}

		this.setState({
			transformerPanelExpanded: !this.state.transformerPanelExpanded,
			selectedTransformer: selectedTransformer
		});
	}

	handleTransformerPanelStateChange = (componentState: any) => {
		this.setState({ transformerPanelExpanded: componentState.isOpen })
		this.setState({ rightPanelChartExpanded: !this.state.rightPanelExpanded})

		if (!componentState.isOpen) {
			this.setState({ rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH });
		}
	}

	handleGuardSelect = (selectedTransformer: any) => {
		if (selectedTransformer) {
			this.closeComplexSubstationModal();
		}

		let phaseAssetIds: any[] = [];

		selectedTransformer.feeders.forEach((feeder: any) => {
			feeder.phases.forEach((phase: any) => { phaseAssetIds.push(phase.assetId) })
		})

		this.setState({
			guardPanelExpanded: !this.state.guardPanelExpanded,
			selectedTransformer: selectedTransformer,
		});
	}

	handleTDRSelect = (selectedInstrument: any) => {
		if (selectedInstrument) {
			this.closeComplexSubstationModal();
		}

		this.setState({
			tdrPanelExpanded: true,
			selectedInstrument: selectedInstrument,
		});
	}

	handleGuardPanelStateChange = (componentState: any) => {
		this.setState({ guardPanelExpanded: componentState.isOpen })
		if (!componentState.isOpen) {
			this.setState({ rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH });
		}
	}

	handleTDRPanelStateChange = (componentState: any) => {
		this.setState({ tdrPanelExpanded: componentState.isOpen })
		if (!componentState.isOpen) {
			this.setState({ rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH });
		}
	}

	mapMarkerClick = (substation: any) => {
		this.setState({
			selectedSubstation: substation,
			showComplexSubstationModal: true,
			feederPanelExpanded: false,
			transformerPanelExpanded: false,
			guardPanelExpanded: false,
			substationAssetHierarchyFormatted: null,
			substationPreFaultDataLoading: true
		}, () => {
			this.getSubstationAssetHierarchy(substation.id);
		})
	}

	closeComplexSubstationModal = () => {
		this.setState({
			showComplexSubstationModal: false
		})
	}

	handleDetailViewBackButtonClick = (e: any) => {
		this.setState({
			feederPanelExpanded: false,
			transformerPanelExpanded: false,
			guardPanelExpanded: false,
			tdrPanelExpanded: false,
			showComplexSubstationModal: true,
			rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH,
		});
	}

	onAlarmHistorySliderChange = (selectedMark: Mark): void => {

		let selectedStartDate: moment.Moment | string = moment();
		let selectedEndDate: moment.Moment | string = moment();
		let isHistory = false;

		if (selectedMark) {

			if (selectedMark.label === 'NOW') {
				selectedStartDate = 'NOW';
				selectedEndDate = 'NOW';
			}
			else {
				if (selectedMark.timespanStart) {
					const unit = selectedMark.timespanStart.unitOfTime as moment.unitOfTime.DurationConstructor;
					selectedStartDate = moment().add(selectedMark.timespanStart.amount, unit);
					isHistory = true;
				}
				else if (selectedMark.dateStart) {
					if (selectedMark.dateStart === 'NOW') {
						selectedStartDate = moment.utc();
					}
					else {
						selectedStartDate = selectedMark.dateStart;
						isHistory = true;
					}

				}

				if (selectedMark.dateEnd) {
					if (selectedMark.dateEnd === 'NOW') {
						selectedEndDate = moment.utc();
					}
					else {
						selectedEndDate = selectedMark.dateEnd;
						isHistory = true;
					}

				}
			}
		}

		this.setState({
			selectedAlarmTimespanStartDate: selectedStartDate,
			selectedAlarmTimespanEndDate: selectedEndDate,
			showLoadingGraphics: true,
			alarmsListLoading: true,
			isAlarmHistory: isHistory
		},
		);
	}

	getSliderDisabledStatus = () => {
		if (!this.state.selectedCustomer) {
			return true;
		}
		else if (this.state.alarmsListLoading) {
			return true;
		}
		else {
			return false;
		}

	}

	calculateResponsivePanelWidth = (defaultWidth: string) => {
		//Extra small devices (portrait phones, less than 576px)
		if (window.innerWidth < xsScreenWidthBreakpointPx) {
			return "98%";
		}
		//Other devices (576px and up) 
		else {
			return defaultWidth;
		}
	}

	calculateMapWidth = () => {
		let width = '100%';

		if (window.innerWidth >= xsScreenWidthBreakpointPx) {

			const leftPanelWidth = this.state.leftPanelExpanded ? leftPanelDefaultWidthPx : 0;
			const rightPanelWidth = this.state.rightPanelExpanded ? rightPanelDefaultWidthPx : 0;

			const totalPanelWidth = leftPanelWidth + rightPanelWidth;

			width = `calc(100% - ${totalPanelWidth}px)`;
		}

		this.setState({ mapWidth: width })
	}

	getAlarmsData = () => {

		if (this.state.alarmUIEnabled && this.state.selectedCustomer && this.state.alarmsListLoading && this.state.selectedAlarmTimespanStartDate === "NOW") {
			return <ActiveAlarmsByCustomer customer={this.state.selectedCustomer} refreshData={this.state.alarmsListLoading} onDataGet={this.handleGetAlarmsSuccess} checkTokenIsValid={this.props.checkTokenIsValid} />
		}
		else if (this.state.alarmUIEnabled && moment.isMoment(this.state.selectedAlarmTimespanStartDate) && moment.isMoment(this.state.selectedAlarmTimespanEndDate)) {
			return <AlarmHistoryByCustomer customer={this.state.selectedCustomer} refreshData={this.state.alarmsListLoading} onDataGet={this.handleGetAlarmsSuccess} checkTokenIsValid={this.props.checkTokenIsValid} alarmTimespanStartDate={this.state.selectedAlarmTimespanStartDate} alarmTimespanEndDate={this.state.selectedAlarmTimespanEndDate} />
		}
	}

	getData = () => {
		return <React.Fragment>

			{this.state.selectedCustomer && this.state.feedersListLoading
				&& <FeedersByCustomer customer={this.state.selectedCustomer} refreshData={this.state.feedersListLoading} onDataGet={this.handleGetFeedersSuccess} checkTokenIsValid={this.props.checkTokenIsValid} />
			}

			{this.state.selectedCustomer && this.state.preFaultCustomerDataLoading
				&& <PrefaultEventsByCustomer customer={this.state.selectedCustomer} refreshData={this.state.preFaultCustomerDataLoading} onDataGet={this.handleGetPreFaultDataByCustomerSuccess} checkTokenIsValid={this.props.checkTokenIsValid} />
			}

			{this.state.selectedCustomer && this.state.substationsListLoading
				&& <SubstationsByCustomer customer={this.state.selectedCustomer} refreshData={this.state.substationsListLoading} onDataGet={this.handleGetCustomerSubstationsSuccess} checkTokenIsValid={this.props.checkTokenIsValid} />
			}

			{this.getAlarmsData()}

			{this.state.selectedSubstation && this.state.substationPreFaultDataLoading
				&& <PrefaultEventsBySubstation substationAssetId={this.state.selectedSubstation.id} refreshData={this.state.substationPreFaultDataLoading} onDataGet={this.handleGetSubstationPreFaultData} checkTokenIsValid={this.props.checkTokenIsValid} />
			}
		</React.Fragment>
	}

	private clearFilters() {
		this.setState({ transformerPanelExpanded: false, feederPanelExpanded: false, guardPanelExpanded: false, tdrPanelExpanded: false });
	}

	private setRightPanelWidth(width: string) {
		if (this.state.rightPanelWidth === EXPANDED_RIGHT_PANEL_WIDTH) {
			this.setState({ rightPanelWidth: DEFAULT_RIGHT_PANEL_WIDTH});
		} else {
			this.setState({ rightPanelWidth: width });
		}
	}

	private onExpandChart() {
		this.setRightPanelWidth(EXPANDED_RIGHT_PANEL_WIDTH);

		//Delay required for right menu resizing transition to complete before charts resize
		//If charts resize too soon, they will resize to incorrect size
		setTimeout(() => {
			this.setState({ rightPanelChartExpanded: !this.state.rightPanelChartExpanded });
		}, 500)
	}

	public render() {

		const alarmHistorySliderMarks: Mark[] = [
			{
				value: 0,
				label: '-1 MONTH',
				timespanStart: {
					unitOfTime: "M",
					amount: -1
				},
				dateEnd: 'NOW'
			},
			{
				value: 1,
				label: '-1 WEEK',
				timespanStart: {
					unitOfTime: "w",
					amount: -1
				},
				dateEnd: 'NOW'
			},
			{
				value: 2,
				label: '-24 HOURS',
				timespanStart: {
					unitOfTime: "h",
					amount: -24
				},
				dateEnd: 'NOW'
			},
			{
				value: 3,
				label: 'NOW',
				defaultValue: true
			},
		];

		return <React.Fragment>

			{this.getData()}

			<section className="layout-content" id="outer-container">

				{/* Left panel */}
				<SidePanel
					pageWrapId={"page-wrap"}
					outerContainerId={"outer-container"}
					isOpen={this.state.leftPanelExpanded}
					onStateChange={this.handleLeftPanelStateChange}
					onToggle={this.handleLeftPanelToggle}
					width={this.calculateResponsivePanelWidth(`${leftPanelDefaultWidthPx}px`)}
					showBurgerIcon
				>
					<Container className="pb-sm-1 pl-4 h-100" >
						<Row className="pb-2">
							<CustomerDropdown onCustomerChange={this.handleCustomerChange} />
						</Row>

						<Row className="pb-2">
							<SubstationFilters
								customerId={this.state.selectedCustomer}
								substationsList={this.state.substationsLocationFiltered}
								substationsListLoading={this.state.showLoadingGraphics && this.state.substationsListLoading}
								onSubstationFilterChange={this.handleSubstationFilterChange}
								feedersList={this.state.filteredFeeders}
								feedersListLoading={this.state.showLoadingGraphics && this.state.feedersListLoading}
								masterAlarmsList={this.state.alarmsList}
								filteredAlarmsList={this.state.filteredAlarms}
								alarmsListLoading={this.state.showLoadingGraphics && this.state.alarmsListLoading}
								prefaultsList={this.state.preFaultCustomerData}
								prefaultsListLoading={this.state.showLoadingGraphics && this.state.preFaultCustomerDataLoading}
								onLicenceAreaChange={this.handleGeofenceChange}
								onClearFilters={this.clearFilters.bind(this)}
							/>
						</Row>
					</Container>

				</SidePanel>

				<MapContainer
					id="page-wrap"
					loading={this.state.showLoadingGraphics && (this.state.substationsListLoading || this.state.alarmsListLoading || this.state.feedersListLoading)}
					geofence={this.state.selectedGeofence}
					geofenceName={this.state.selectedGeofenceName}
					substations={this.state.filteredSubstations}
					alarmsData={this.state.alarmsList}
					onMarkerClick={this.mapMarkerClick}
					alarmTypes={AlarmConfig}
					width_md={this.state.mapWidth}
					primaryFocus={this.state.mapPrimaryFocus}
				/>

				{/* Right panel */}
				{this.state.alarmUIEnabled && <SidePanel
					right
					isOpen={this.state.rightPanelExpanded}
					onStateChange={this.handleRightPanelStateChange}
					onToggle={this.handleRightPanelToggle}
					width={this.calculateResponsivePanelWidth(`${rightPanelDefaultWidthPx}px`)}
					showBurgerIcon
					classString="alarm"
				>
					<Row className="d-flex justify-content-center pt-3">
						<Col xs="11">
							<TimeSpanSlider marks={alarmHistorySliderMarks} onChange={this.onAlarmHistorySliderChange} disabled={this.getSliderDisabledStatus()} />
						</Col>
					</Row>

					<Row className="pb-3">
						<Col  >
							<AlarmTable
								loading={this.state.showLoadingGraphics && this.state.alarmsListLoading}
								alarmsData={this.state.filteredAlarms}
								assetHeaderText="Substation"
								assetHeaderField="substationName"
								exportButton
								isAlarmHistory={this.state.isAlarmHistory}
								style={{ margin: "5px 0 10px 0", maxHeight: "calc(72vh)" }}
							/>
						</Col>
					</Row>
				</SidePanel>
				}

				{/* Feeder panel */}
				<SidePanel
					right
					isOpen={this.state.feederPanelExpanded}
					onStateChange={this.handleFeederPanelStateChange}
					onToggle={this.handleFeederSelect}
					width={this.calculateResponsivePanelWidth(this.state.rightPanelWidth)}
					showBackButton
					onBackButtonClick={this.handleDetailViewBackButtonClick}
				>
					{this.state.feederPanelExpanded && <FeederDetailView
						loading={this.state.showLoadingGraphics && (this.state.alarmsListLoading || this.state.feedersListLoading || this.state.substationAssetHierarchyLoading || this.state.substationPreFaultDataLoading)}
						feederAssetHierarchy={this.state.selectedFeeder}
						transformerData={this.state.selectedTransformer}
						preFaultData={this.state.selectedFeederPreFaultData}
						substationName={this.state.selectedSubstation ? this.state.selectedSubstation.name : ""}
						isAlarmHistory={this.state.isAlarmHistory}
						showAlarmUI={this.state.alarmUIEnabled}
						onExpandChart={this.onExpandChart.bind(this)}
						expanded={this.state.rightPanelChartExpanded}
					/>
					}
				</SidePanel>

				{/* Transformer panel */}
				<SidePanel
					right
					isOpen={this.state.transformerPanelExpanded}
					onStateChange={this.handleTransformerPanelStateChange}
					onToggle={this.handleTransformerSelect}
					width={this.calculateResponsivePanelWidth(this.state.rightPanelWidth)}
					showBackButton
					onBackButtonClick={this.handleDetailViewBackButtonClick}
				>
					{this.state.transformerPanelExpanded && <TransformerDetailView
						loading={this.state.showLoadingGraphics && (this.state.alarmsListLoading || this.state.feedersListLoading || this.state.substationAssetHierarchyLoading)}
						transformerAssetHierarchy={this.state.selectedTransformer}
						substationName={this.state.selectedSubstation ? this.state.selectedSubstation.name : ""}
						substation={this.state.selectedSubstation}
						isAlarmHistory={this.state.isAlarmHistory}
						showAlarmUI={this.state.alarmUIEnabled}
						onExpandChart={this.onExpandChart.bind(this)}
						expanded={this.state.rightPanelChartExpanded && this.state.transformerPanelExpanded}
					/>
					}
				</SidePanel>
				{/* Guard panel */}
				<SidePanel
					right
					isOpen={this.state.guardPanelExpanded}
					onStateChange={this.handleGuardPanelStateChange}
					onToggle={this.handleGuardSelect}
					width={this.calculateResponsivePanelWidth(this.state.rightPanelWidth)}
					showBackButton
					onBackButtonClick={this.handleDetailViewBackButtonClick}
				>
					{this.state.guardPanelExpanded && <GuardDetailView
						loading={this.state.showLoadingGraphics && (this.state.alarmsListLoading || this.state.feedersListLoading || this.state.substationAssetHierarchyLoading || this.state.substationPreFaultDataLoading)}
						transformerAssetHierarchy={this.state.selectedTransformer}
						substationName={this.state.selectedSubstation ? this.state.selectedSubstation.name : ""}
						prefaultData={this.state.substationPreFaultData}
						onExpandChart={this.onExpandChart.bind(this)}

					/>
					}
				</SidePanel>

				{/* TDR panel */}
				<SidePanel
					right
					isOpen={this.state.tdrPanelExpanded}
					onStateChange={this.handleTDRPanelStateChange}
					onToggle={this.handleTDRSelect}
					width={this.calculateResponsivePanelWidth(this.state.rightPanelWidth)}
					showBackButton
					onBackButtonClick={this.handleDetailViewBackButtonClick}

				>
					{this.state.tdrPanelExpanded && <TDRDetailView
						loading={this.state.showLoadingGraphics && (this.state.substationAssetHierarchyLoading)}
						instrument={this.state.selectedInstrument}
						substation={this.state.selectedSubstation}
						onExpandChart={this.onExpandChart.bind(this)}
					/>
					}
				</SidePanel>
			</section>

			<Modal centered show={this.state.showComplexSubstationModal} onHide={this.closeComplexSubstationModal} dialogClassName={this.state.complexViewModalClassname} >
				<Modal.Header closeButton>
					<Modal.Title>{this.state.selectedSubstation ? this.state.selectedSubstation.name : ""}</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					{this.state.showComplexSubstationModal && <ComplexSubstationView
						loading={this.state.substationAssetHierarchyLoading}
						substationData={this.state.substationAssetHierarchyFormatted}
						onFeederClick={this.handleFeederSelect}
						onTransformerClick={this.handleTransformerSelect}
						onGuardClick={this.handleGuardSelect}
						onTDRClick={this.handleTDRSelect.bind(this)}
						substationPrefaults={this.state.substationPreFaultData}
					/>
					}
				</Modal.Body>

			</Modal>

		</React.Fragment>
	}
}
