import config from '../../../config';
import ApiResponseHandler from '../../shared/ApiResponseHandler';

export interface DeviceDetails {
	autoReport: string,
	recordNo: string,
	versionNumber: string,
	installation: {
		date: string,
		job: string,
		location: string,
		spare: string
	},
	machineId: string,
	lastUpdated: string,
	voltageThreshold: string,
	voltageDuration: string,
	currentFsd: string,
	phase: string,
	tdrTrsMode: string,
	range: string,
	gain: string,
	model: string,
	triggerMode: string
}

export interface DeviceSettings {
	voltageThreshold: string,
	voltageDuration: string,
	currentFsd: string,
	phase: string,
	tdrTrsMode: string,
	range: string,
	gain: string,
	model: string,
	triggerMode: string
}

export default class TdrDeviceSettingsAPIs {

	getDeviceSettings(instrumentAssetId: string, checkTokenIsValid: any, onSuccess: any, onError: any): any {

		const apiResponseHandler = new ApiResponseHandler({});
		const endpointAddress = `${config.apiGateway.META_API}/api/Tdr/settings/visnetassetid/${instrumentAssetId}`

		apiResponseHandler.callAPIGet(endpointAddress, onSuccess, onError, checkTokenIsValid);

	}

	setDeviceSettings(instrumentAssetId: string, deviceSettings: DeviceSettings, checkTokenIsValid: any, onSuccess: any, onError: any): any {

		const apiResponseHandler = new ApiResponseHandler({});
		const settingsBody = JSON.stringify(deviceSettings);

		const endpointAddress = `${config.apiGateway.META_API}/api/Tdr/settings/visnetassetid/${instrumentAssetId}`;
		apiResponseHandler.callAPIPost(endpointAddress, onSuccess, onError, checkTokenIsValid, settingsBody);

	}

}