import React, { useEffect, useState } from 'react';
import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

const pageSize = 12;

interface Props extends ComboBoxProps {
	data: any;
	onSelectionChange: (event: any) => void;
}

export default function Autocomplete(props: Props) {
	const { data, onSelectionChange, ...rest } = props;

	const [skip, setSkip] = useState(0);
	const [filteredData, setFilteredData] = useState<any[]>([]);
	const [pagedData, setPagedData] = useState<any[]>([]);
	const [recordCount, setRecordCount] = useState(0);

	useEffect(() => {
		if (data) {
			setRecordCount(data.length)
			setPagedData(data.slice(0, pageSize))
		}
		else{
			setRecordCount(0)
			setPagedData([])
		}
		setSkip(0);
		setFilteredData(data)

	}, [data]);

	function pageChange(event: any) {
		const skip = event.page.skip;
		const take = event.page.take;
		const newPagedData = filteredData.slice(skip, skip + take);

		setPagedData(newPagedData);
		setSkip(skip);
	}

	function onFilterChange(event: any) {
		const subsetData: any = filterBy(data, event.filter);

		setRecordCount(subsetData.length);
		setFilteredData(subsetData);
		setPagedData(subsetData.slice(0, pageSize))
		setSkip(0);
	}

	function onChange(event: any) {
		const value = event.target.value;
		onSelectionChange(value);
	}

	return (
		<ComboBox
			data={pagedData}
			filterable={true}
			onChange={onChange}
			onFilterChange={onFilterChange}
			virtual={{
				total: recordCount,
				pageSize: pageSize,
				skip: skip
			}}
			onPageChange={pageChange}
			{...rest}
		/>
	);

}
