import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingGraphic } from '../shared/LoadingGraphic'
import ComplexSubstationGraphic from './ComplexSubstationGraphic'

interface Props {
    loading: boolean;
    substationData: any;
    onFeederClick: any;
    onTransformerClick: any;
	onGuardClick: any;
	onTDRClick: any;
	substationPrefaults?: any;
}

class ComplexSubstationView extends React.Component<Props> {

    renderTransformerGraphics = () => {
        if (this.props.substationData && this.props.substationData.transformers) {
            return this.props.substationData.transformers.map((transformer: any) => {

                return (
                    <Col key={`tx-col-${transformer.assetId}`}>
                        <ComplexSubstationGraphic 
                            key={`tx-graphic-${transformer.assetId}`} 
                            transformerData={transformer} 
                            onFeederClick={this.props.onFeederClick} 
                            onTransformerClick={this.props.onTransformerClick}
							onGuardClick={this.props.onGuardClick}
							onTDRClick={this.props.onTDRClick}
							substationPrefaults={this.props.substationPrefaults}
                        />
                    </Col>
                )
            })
        }

        return (<div></div>)
	}
    render() {
        if (this.props.loading || !this.props.substationData) {
            return (
                <div style={{height: "100px"}}>
                    <LoadingGraphic />
                </div>
            )
        }
        else {
            return (
                <Row>
                    {this.renderTransformerGraphics()}
                </Row>
            )
        }
    }
}

export default ComplexSubstationView;