import * as React from 'react';
import 'leaflet/dist/leaflet.css';
import { bbox, helpers } from '@turf/turf';
import { Map as LeafletMap, TileLayer, GeoJSON } from 'react-leaflet';
import { LoadingGraphic } from '../../shared/LoadingGraphic';
import { GeoFenceConfig } from '../../shared/interfaces/Regions';
import SubstationMarkers from './SubstationMarkers';

export interface MapProps {
	geofence: GeoFenceConfig | undefined;
	geofenceName?: string;
	substations: any;
	alarmsData: any;
	onMarkerClick: any;
	loading: boolean;
	alarmTypes: any;
	primaryFocus: MapPrimaryFocus;
	invalidateSize?: boolean;
}

export enum MapPrimaryFocus {
	Substations = "SUBSTATIONS",
	Geofence = "GEOFENCE"
}

class Map extends React.Component<MapProps> {

	private mapRef: React.RefObject<any>;

	constructor(props: MapProps) {
		super(props);

		this.mapRef = React.createRef();
	}

	shouldComponentUpdate(nextProps: MapProps) {
		if (this.props.loading !== nextProps.loading) {
			return true;
		}
		if (this.props.substations !== nextProps.substations) {
			return true;
		}
		if (this.props.alarmsData !== nextProps.alarmsData) {
			return true;
		}
		if (nextProps.invalidateSize && this.props.invalidateSize !== nextProps.invalidateSize) {
			return true;
		}

		return false;
	}

	onMarkerClick = (substation: any) => {
		this.props.onMarkerClick(substation)
	}

	renderGeofences = () => {
		if (this.props.geofence?.geoJson) {
			const geojson = JSON.parse(this.props.geofence.geoJson);
			return (
				<GeoJSON key={this.props.geofenceName} data={geojson} />
			)
		}
	}

	getBounds(geoJSON: helpers.AllGeoJSON) {
		const bboxArray = bbox(geoJSON);
		const corner1 = [bboxArray[1], bboxArray[0]];
		const corner2 = [bboxArray[3], bboxArray[2]];
		const bounds = [corner1, corner2];

		return bounds;
	}

	removeDuplicateCoordinates = (array: any[]) => {
		return Array.from(new Set(array.map((element: any) => JSON.stringify(element)))).map((element: any) => JSON.parse(element));
	}

	getSubstationsGeoJSON() {
		if (this.props.substations && this.props.substations.length > 0) {
			let gpsCoords = this.props.substations.filter((substation: any) => substation.gpsCoords && ((substation.gpsCoords.longitude !== 0 && substation.gpsCoords.latitude !== 0)))
				.map((substation: any) => [substation.gpsCoords.longitude, substation.gpsCoords.latitude]);

			if (gpsCoords && gpsCoords.length > 0) {

				gpsCoords = this.removeDuplicateCoordinates(gpsCoords)

				let feature;
				if (gpsCoords.length === 1) {
					feature = helpers.point(gpsCoords[0]);
				}
				else {
					feature = helpers.multiPoint(gpsCoords);
				}
				return feature;
			}
		}
	}

	componentDidUpdate() {
		this.props.invalidateSize && setTimeout(() => this.resetMapSize(), 250);
	}

	resetMapSize = () => {
		if (this.mapRef.current) {
			this.mapRef.current.leafletElement.invalidateSize(true);
		}
	}

	render() {
		if (this.props.loading) {
			return (
				<LoadingGraphic />
			)
		}
		else {
			const geofence = this.props.geofence?.geoJson && JSON.parse(this.props.geofence.geoJson);

			const substationsGeoJSON = this.getSubstationsGeoJSON();

			let center = [54.5, -3];
			let zoom = 6;
			let bounds = null;

			if (geofence && this.props.primaryFocus === MapPrimaryFocus.Geofence) {
				bounds = this.getBounds(geofence)
			}

			else if (substationsGeoJSON && substationsGeoJSON.geometry && substationsGeoJSON.geometry.type === "MultiPoint") {
				bounds = this.getBounds(substationsGeoJSON)
			}

			else if (substationsGeoJSON && substationsGeoJSON.geometry && substationsGeoJSON.geometry.type === "Point") {
				center = [substationsGeoJSON.geometry.coordinates[1], substationsGeoJSON.geometry.coordinates[0]];
				zoom = 14;
			}

			return (
				<LeafletMap
					ref={this.mapRef}
					center={center}
					bounds={bounds}
					zoom={zoom}
					maxZoom={19}
				>
					<TileLayer
						attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
						url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
					/>

					{this.props.substations && this.props.substations.length > 0
						&& <SubstationMarkers
							substations={this.props.substations}
							alarmsData={this.props.alarmsData}
							alarmTypes={this.props.alarmTypes}
							onMarkerClick={this.onMarkerClick}
						/>
					}

					{this.renderGeofences()}

				</LeafletMap>
			)
		}
	}
}

export default Map;