import React, { ReactElement } from 'react'
import AlarmSeverity from '../../../../utils/AlarmSeverity'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { IFeederAlarmConfig } from '../../../../constants/alarmconfig';

interface Props {
	feederData: any;
	busbarData: any;
	alarmTypes: IFeederAlarmConfig[];
	preFaultData: any;

	selectedAlarm: IFeederAlarmConfig | string | undefined;
	onAlarmClick: any;
}

const xEnd = 630; // x end of the graphic viewbox
const yEnd = 800; // y end of the graphic viewbox

const strokeWidth = 11; //width of the fuse strokes
const rectGridPadding = 5; //padding in the alarm status grid

const xPhaseSectonStart = 200; //x position of the right side of alarm label column / left side of phase section

const yBusbarStart = 20;
const yBusbarEnd = 100;
const xBusbarStart = xPhaseSectonStart;
const xBusbarEnd = xEnd - rectGridPadding;

const yFuseStart = yBusbarEnd; //top of the fuse section
const yFuseEnd = 310; //bottom of the fuse section (top of the column headers)

const headerHeight = 100; //height of the column headers
const yGridStart = yFuseEnd;  //top of the alarm grid section (bottom of the column headers)
const yGridEnd = yEnd;

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

let alarmRAGMatrixList: any[] = [];

class FeederDetailGraphic extends React.Component<Props> {

	feederIsMonitored = () => {
		return this.props.feederData.hasVisnet && this.props.feederData.isMonitored;
	}

	renderBusbarSection = () => {

		if (this.props.busbarData && this.props.busbarData.length > 0) {

			const busbarCount = this.props.busbarData.length;
			const busbarSpacing = ((yBusbarEnd - yBusbarStart + strokeWidth) - (strokeWidth * busbarCount)) / (busbarCount - 0.5);

			let busbarClass = "";
			let busbarStartIncrementer = yBusbarStart;
			let currentBusbarStart = busbarStartIncrementer;

			return this.props.busbarData.map((busbar: any) => {

				busbarClass = AlarmSeverity.getAlarmStyles(busbar.alarmSeverity, busbar.hasVisnet && busbar.isMonitored).stroke;

				currentBusbarStart = busbarStartIncrementer;

				busbarStartIncrementer += strokeWidth + busbarSpacing;

				return (
					<line key={busbar.assetId} className={busbarClass} x1={xBusbarStart} y1={currentBusbarStart} x2={xBusbarEnd} y2={currentBusbarStart} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}>
						<title>{busbar.assetName}</title>
					</line>
				)
			})
		}
	}

	renderFuse = (phaseData: any, xFusePosition: number) => {

		if (phaseData.assetTypeKey.includes("Neutral")) {
			return (
				<line className="svgUncolouredStroke" x1={xFusePosition} y1={yFuseStart} x2={xFusePosition} y2={yFuseEnd} strokeWidth={strokeWidth}></line>
			)
		}
		else {
			let fuseStyle = AlarmSeverity.alarmStyles.NotMonitored;
			let fuseCross: ReactElement[] = [];

			const fuseCircleRadius = 20; // circle radius for the OXO section

			const heightOfOXO = (fuseCircleRadius * 7);
			const heightOfFuseSection = (yFuseEnd - yFuseStart)

			const yFuseTopLineEnd = yFuseStart + ((heightOfFuseSection - heightOfOXO) / 2); //end of the top line

			//x and y top and bottom for the lines of the "X" (cross) in the OXO section. 
			const yFuseCrossTop = yFuseTopLineEnd + (fuseCircleRadius * 2);
			const yFuseCrossBottom = yFuseCrossTop + (fuseCircleRadius * 3);
			const xFuseCrossLeft = xFusePosition - fuseCircleRadius;
			const xFuseCrossRight = xFusePosition + fuseCircleRadius;

			const yfuseBottomLineStart = yFuseCrossBottom + (fuseCircleRadius * 2) + (strokeWidth / 2) - 1;

			const fuseAlarm = phaseData.alarms.find((alarm: any) => alarm.alarmName.toLowerCase() === "fuse blown");

			if (fuseAlarm) {
				fuseStyle = AlarmSeverity.getAlarmStyles(fuseAlarm.severity, this.feederIsMonitored());
				fuseCross = [
					<line key={`${phaseData.assetId}fuseCross`} className={fuseStyle.stroke} x1={xFuseCrossLeft} y1={yFuseCrossTop + 10} x2={xFuseCrossRight} y2={yFuseCrossBottom - 10} strokeWidth={strokeWidth}></line>,
				]
			}
			else {
				fuseStyle = AlarmSeverity.getAlarmStyles("", this.feederIsMonitored());
				fuseCross = [
					<line key={`${phaseData.assetId}fuseCrossLeft`} className={fuseStyle.stroke} x1={xFuseCrossLeft} y1={yFuseCrossTop} x2={xFuseCrossRight} y2={yFuseCrossBottom} strokeWidth={strokeWidth}></line>,
					<line key={`${phaseData.assetId}fuseCrossRight`} className={fuseStyle.stroke} x1={xFuseCrossRight} y1={yFuseCrossTop} x2={xFuseCrossLeft} y2={yFuseCrossBottom} strokeWidth={strokeWidth}></line>
				]
			}

			return (
				<g key={`${phaseData.assetId}fuseGroup`}>

					{/* top line */}
					<line key={`${phaseData.assetId}fuseTopLine`} className="svgUncolouredStroke" x1={xFusePosition} y1={yFuseStart} x2={xFusePosition} y2={yFuseTopLineEnd} strokeWidth={strokeWidth}></line>

					{/* O */}
					<circle key={`${phaseData.assetId}fuseTopO`} className={fuseStyle.stroke} cx={xFusePosition} cy={yFuseTopLineEnd + fuseCircleRadius} r={fuseCircleRadius} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></circle>

					{/* X */}
					{fuseCross}

					{/* O */}
					<circle key={`${phaseData.assetId}fuseBottomO`} className={fuseStyle.stroke} cx={xFusePosition} cy={yFuseCrossBottom + (fuseCircleRadius)} r={fuseCircleRadius} fill="rgba(0,0,0,0)" strokeWidth={strokeWidth}></circle>

					{/* bottom line */}
					<line key={`${phaseData.assetId}fuseBottomLine`} className="svgUncolouredStroke" x1={xFusePosition} y1={yfuseBottomLineStart} x2={xFusePosition} y2={yFuseEnd} strokeWidth={strokeWidth}></line>
				</g>
			)
		}
	}

	getAlarmCellHeight = () => {
		const alarmRowCount = alarmRAGMatrixList.length + 2; //the number of alarms, plus two rows for pre-fault
		return ((yGridEnd - yGridStart - headerHeight) / alarmRowCount) - (rectGridPadding * 1.25);
	}

	getAlarmCellYPosition = (height: number, index: number) => {
		return yGridStart + headerHeight + (rectGridPadding * (index + 1)) + (height * (index));
	}

	renderAlarmLabelColumn = () => {
		const textColumnWidth = xPhaseSectonStart - (rectGridPadding * 2);
		const rectHeight = this.getAlarmCellHeight();

		return alarmRAGMatrixList.map((alarmConfig: any, index: number) => {

			const yPosition = this.getAlarmCellYPosition(rectHeight, index);

			return this.renderClickableAlarmGridTextCell(alarmConfig, rectGridPadding, yPosition, textColumnWidth, rectHeight, "svgFeederDetail_GridTextCell svgFeederDetailAlarmText", this.props.onAlarmClick)
		});
	}

	renderGridAlarmLabelColumn = () => {
		const rectHeight = this.getAlarmCellHeight();
		const alarmCount = alarmRAGMatrixList.length;

		const yPositionPrefaultCountCell = this.getAlarmCellYPosition(rectHeight, alarmCount);
		const yPositionPrefaultDateCell = this.getAlarmCellYPosition(rectHeight, alarmCount + 1);

		const textColumnWidth = xPhaseSectonStart - (rectGridPadding * 2);

		let prefaultsDetected =  this.props.alarmTypes.find((feederAlarm: IFeederAlarmConfig) => feederAlarm.name === "Pre-Faults Detected")
		let prefaultsMostRecent =  this.props.alarmTypes.find((feederAlarm: IFeederAlarmConfig) => feederAlarm.name === "Pre-Faults - Most Recent")

		return (
			<g>
				{this.renderAlarmLabelColumn()}
				{prefaultsDetected ? this.renderClickableAlarmGridTextCell(prefaultsDetected, rectGridPadding, yPositionPrefaultCountCell, textColumnWidth, rectHeight, "svgFeederDetail_GridTextCell svgFeederDetailAlarmText", this.props.onAlarmClick) : null}
				{prefaultsMostRecent ? this.renderClickableAlarmGridTextCell(prefaultsMostRecent, rectGridPadding, yPositionPrefaultDateCell, textColumnWidth, rectHeight, "svgFeederDetail_GridTextCell svgFeederDetailAlarmText", this.props.onAlarmClick) : null}
			</g>
		)
	}

	getPhasePreFaultData = (phaseId: string) => {

		if (this.props.preFaultData && this.props.preFaultData.phases) {
			return this.props.preFaultData.phases.find((preFaultPhase: any) => preFaultPhase.id === phaseId)
		}
	}

	renderPhaseAlarmColumn = (phaseData: any, feederAlarms: any, xPosition: number, columnWidth: number) => {

		const rectHeight = this.getAlarmCellHeight();
		const rectWidth = columnWidth - rectGridPadding;
		const alarmCount = alarmRAGMatrixList.length;

		const yPositionPrefaultCountCell = this.getAlarmCellYPosition(rectHeight, alarmCount);
		const yPositionPrefaultDateCell = this.getAlarmCellYPosition(rectHeight, alarmCount + 1);

		const phasePreFaultData = this.getPhasePreFaultData(phaseData.assetId)

		let preFaultEventCount = "0";
		let preFaultMostRecent = "n/a";

		if (phasePreFaultData) {
			preFaultEventCount = phasePreFaultData.eventCount;
			preFaultMostRecent = phasePreFaultData.mostRecent ? moment.utc(phasePreFaultData.mostRecent).local().format(dateFormat) : preFaultMostRecent;
		}

		let prefaultsDetected = this.props.alarmTypes.find((feederAlarm: IFeederAlarmConfig) => feederAlarm.name === "Pre-Faults Detected")
		let prefaultsMostRecent = this.props.alarmTypes.find((feederAlarm: IFeederAlarmConfig) => feederAlarm.name === "Pre-Faults - Most Recent")

		return (
			<g>
				{/* Header Cell */}
				{this.renderAlarmGridTextCell(phaseData.assetName, xPosition, yGridStart, rectWidth, headerHeight, "svgFeederDetail_GridTextCell svgFeederDetailHeader")}

				{/* Alarm Status Cells*/}
				{this.renderPhaseAlarmStatusCells(phaseData, feederAlarms, xPosition, rectWidth, rectHeight, this.props.onAlarmClick)}

				{/* Pre-fault Count Cell */}
				{prefaultsDetected ? this.renderPrefaultTextCell(preFaultEventCount, xPosition, yPositionPrefaultCountCell, rectWidth, rectHeight, "svgFeederDetail_GridTextCell svgFeederDetailPrefaultAlarmCount", this.props.onAlarmClick, prefaultsDetected, phaseData.assetId) : null}

				{/* Pre-fault Date Cell */}
				{prefaultsMostRecent ? this.renderPrefaultTextCell(preFaultMostRecent, xPosition, yPositionPrefaultDateCell, rectWidth, rectHeight, "svgFeederDetail_GridTextCell svgFeederDetailPrefaultAlarmDate", this.props.onAlarmClick, prefaultsMostRecent, phaseData.assetId) : null}
			</g>
		)
	}

	renderAlarmGridTextCell = (text: string, xPosition: number, yPosition: number, rectWidth: number, rectHeight: number, cssClass: string) => {
		return (
			<foreignObject x={xPosition} y={yPosition} width={rectWidth} height={rectHeight}>
				<div className={cssClass}>
					{text}
				</div>
			</foreignObject>
		)
	}

	renderPrefaultTextCell = (text: string, xPosition: number, yPosition: number, rectWidth: number, rectHeight: number, cssClass: string, clickHandler: any, alarm: IFeederAlarmConfig, assetId: string) => {
		return (
			<foreignObject x={xPosition} y={yPosition} width={rectWidth} height={rectHeight} onClick={() => { clickHandler(alarm, assetId) }} style={{ "cursor": "pointer" }}>
				<div className={cssClass}>
					{text}
				</div>
			</foreignObject>
		)
	}

	renderClickableAlarmGridTextCell = (alarm: IFeederAlarmConfig, xPosition: number, yPosition: number, rectWidth: number, rectHeight: number, cssClass: string, clickHandler: any) => {
		return (
			<foreignObject x={xPosition} y={yPosition} width={rectWidth} height={rectHeight} onClick={() => { clickHandler(alarm) }} style={{ "cursor": "pointer" }}>
				<div className={`${cssClass} d-flex justify-content-between`} style={{ width: "100%" }}>

					{alarm.name}

					{this.props.selectedAlarm && this.props.selectedAlarm === alarm ? <FontAwesomeIcon className="text-info" icon={faChartLine} /> : <FontAwesomeIcon style={{ color: "white" }} icon={faChartLine} />}

				</div>

			</foreignObject>
		)
	}

	renderPhaseAlarmStatusCells = (phaseData: any, feederAlarms: any, xPosition: number, rectWidth: number, rectHeight: number, clickHandler: any) => {

		let severity = "";

		return alarmRAGMatrixList.map((alarmConfig: any, index: number) => {

			const alarmMatch = (phaseData.alarms && phaseData.alarms.find((phaseAlarm: any) => phaseAlarm.alarmName === alarmConfig.name)) || (feederAlarms && feederAlarms.find((alarm: any) => alarm.alarmName === alarmConfig.name));

			if (alarmMatch) {
				severity = alarmMatch.severity;
			}
			else {
				severity = "";
			}

			const svgAlarmStyle = AlarmSeverity.getAlarmStyles(severity, this.feederIsMonitored());
			const yPosition = this.getAlarmCellYPosition(rectHeight, index);

			return <rect key={`alarmCell${phaseData.assetId}${alarmConfig.name}`} className={svgAlarmStyle.fill} x={xPosition} y={yPosition} width={rectWidth} height={rectHeight} rx="3" onClick={() => { clickHandler(alarmConfig, phaseData.assetId) }} style={{ "cursor": "pointer" }} />;
		});
	}

	renderPhases = () => {
		const phaseCount = this.props.feederData.phases.length;
		const phaseColumnWidth = (xEnd - xPhaseSectonStart) / phaseCount;
		const phaseMidpoint = phaseColumnWidth / 2;

		const feederAlarms = this.props.feederData.alarms.filter((alarm: any) => alarm.assetTypeKey === "LvWay")

		return this.props.feederData.phases.map((phaseData: any, index: number) => {

			const xFuseLinePosition = (xPhaseSectonStart + (phaseColumnWidth * index)) + phaseMidpoint;
			const xAlarmRectStartPosition = xPhaseSectonStart + (phaseColumnWidth * index);

			return (
				<g key={`${phaseData.assetId}phaseGroup`}>
					{this.renderFuse(phaseData, xFuseLinePosition)}
					{this.renderPhaseAlarmColumn(phaseData, feederAlarms, xAlarmRectStartPosition, phaseColumnWidth)}
				</g>
			)
		});

	}

	render() {

		if (!this.props.feederData) {

			return null;
		}
		else {

			alarmRAGMatrixList = this.props.alarmTypes.filter((feederAlarm: IFeederAlarmConfig) => feederAlarm.showInRAGMatrix === true)

			return (
				<div style={{ width: "100%" }}>

					<svg viewBox={`0 0 ${xEnd} ${yEnd}`}>
						{/* white background */}
						<rect width="100%" height="100%" fill="#fff" />

						{/* alarm labels */}
						{this.renderGridAlarmLabelColumn()}

						{/* phase section: fuses and alarm grid */}
						{this.renderPhases()}

						{/* busbar section */}
						{this.renderBusbarSection()}

					</svg>
				</div >
			)
		}
	}
}

export default FeederDetailGraphic;