import { useEffect } from 'react'
import ApiResponseHandler from '../shared/ApiResponseHandler';
import config from '../../config';

interface Props {
	refreshData: boolean;
	checkTokenIsValid: any;
	onDataGet: (response: any) => void;
	onDataError?: (message: string) => void;
}

export function Customers(props: Props) {
	useEffect(() => {
		const apiResponseHandler = new ApiResponseHandler({});

		const abortController = new AbortController();
		const signal = abortController.signal;

		const endpointAddress = `${config.apiGateway.META_API}/api/customers`;

		const handleError = (error: any) => {
			props.onDataError &&  props.onDataError(error)
		}

		apiResponseHandler.callAPIGet(endpointAddress, props.onDataGet, handleError, props.checkTokenIsValid, signal);
		return () => {
			abortController.abort()
		  }
	}, [props.refreshData]);

	return null;
}
