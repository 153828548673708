import MarkerCounts  from './Models'
export const MarkerClusterDonutWithRAG = (markerCounts: MarkerCounts) => {

	var circumference: number;
	var cxy: number;
	var tx: number;
	var ty: number;
	var donutWidth: number;
	var viewBoxX: number;
	var viewBoxY: number;

	//set cluster marker size based on total number of assets in cluster
	if (markerCounts.totalCount < 100) {
		circumference = 160;
	}
	else if (markerCounts.totalCount < 1000) {
		circumference = 220;
	}
	else if (markerCounts.totalCount < 10000) {
		circumference = 320;
	}
	else if (markerCounts.totalCount < 100000) {
		circumference = 420;
	}
	else {
		circumference = 420;
	}

	donutWidth = 10;

	const percentBase = circumference / markerCounts.totalCount;
	const redPercent = Math.round(percentBase * markerCounts.redCount);
	const amberPercent = Math.round(percentBase * markerCounts.amberCount);
	const greenPercent = Math.round(percentBase * markerCounts.greenCount);
	const unmonitoredPercent = circumference - (redPercent + amberPercent + greenPercent);

	const redRemainder = circumference - redPercent;
	const amberRemainder = circumference - amberPercent;
	const greenRemainder = circumference - greenPercent;
	const unmonitoredRemainder = circumference - unmonitoredPercent;

	const redDashOffset = 0;
	const amberDashOffset = circumference - (redPercent) + redDashOffset;
	const greenDashOffset = circumference - (redPercent + amberPercent) + redDashOffset;
	const unmonitoredDashOffset = circumference - (redPercent + amberPercent + greenPercent) + redDashOffset;

	//r=C / (pi * 2)
	const radius = circumference / (Math.PI * 2);
	const diameter = radius * 2;
	viewBoxX = diameter + donutWidth;
	viewBoxY = diameter + donutWidth;
	cxy = radius + (donutWidth / 2);
	tx = cxy;
	ty = cxy + donutWidth;

	return {
		radius: radius,
		html: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 ${viewBoxX} ${viewBoxY}" style="enable-background:new 0 0 ${viewBoxX} ${viewBoxY};" xml:space="preserve">
	
  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="#fff"></circle>

  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="#E51E3D" stroke-width="${donutWidth}" stroke-dasharray="${redPercent} ${redRemainder}" stroke-dashoffset="${redDashOffset}"></circle>

  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="#FAB722" stroke-width="${donutWidth}" stroke-dasharray="${amberPercent} ${amberRemainder}" stroke-dashoffset="${amberDashOffset}"></circle>

  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="#1C9D5C" stroke-width="${donutWidth}" stroke-dasharray="${greenPercent} ${greenRemainder}" stroke-dashoffset="${greenDashOffset}"></circle>

  <circle cx="${cxy}" cy="${cxy}" r="${radius}" fill="transparent" stroke="#BCBCBC" stroke-width="${donutWidth}" stroke-dasharray="${unmonitoredPercent} ${unmonitoredRemainder}" stroke-dashoffset="${unmonitoredDashOffset}"></circle>

	<text x="${tx}" y="${ty}" style="fill:#4B4A49; font-family:'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
		Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; text-anchor: middle; font-size:24px; font-weight: bold">${markerCounts.totalCount}</text>
</svg>`};
};