import { useEffect } from 'react'
import ApiResponseHandler from '../shared/ApiResponseHandler';
import config from '../../config';

interface Props {
	customer: any;
	refreshData: boolean;
	checkTokenIsValid: any;
	onDataGet: (response: any) => void;
	onDataError?: (message: string) => void;
}

export function LicenceAreasAndDistrictsByCustomer(props: Props) {
	useEffect(() => {
		const apiResponseHandler = new ApiResponseHandler({});

		const abortController = new AbortController();
		const signal = abortController.signal;

		const endpointAddress = `${config.apiGateway.META_API}/api/Configuration/LicenceAreasAndDistricts/Customer/${props.customer}/PopulateAttributes/true `;

		const handleError = (error: any) => {
			props.onDataError &&  props.onDataError(error)
		}

		apiResponseHandler.callAPIGet(endpointAddress, props.onDataGet, handleError, props.checkTokenIsValid, signal);
		return () => {
			abortController.abort()
		  }
	}, [props.customer, props.refreshData]);

	return null;
}
