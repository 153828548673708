import React, { useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import IconToast, { IconToastMode } from '../../shared/IconToast'
import { MessageToast, ToastMode } from '../../shared/MessageToast'
import TdrData from '../../Data/Tdr/TdrDataAPIs';
import { UserPermissionsContext } from '../../Contexts/UserPermissionsContext';

interface Props {
	instrumentAssetId: string;
	onGetManualTriggerSuccess: (response: any) => void;
	onGetManualTriggerError?: (error: any) => void;
}

export default function ManualTrigger(props: Props) {
	const userPermissions = useContext(UserPermissionsContext);
	const [manualTriggerLoading, setManualTriggerLoading] = useState(false);
	const [manualTriggerErrorMessage, setManualTriggerErrorMessage] = useState<string | undefined>();

	const [showIconToast, setShowIconToast] = useState(false);
	const [iconToastMode, setIconToastMode] = useState(IconToastMode.Error);
	const [iconToastAutoHide, seIconToastAutoHide] = useState(false);

	function handleGrabWaveformButtonClick() {

		setManualTriggerLoading(true);
		setShowIconToast(true);
		setIconToastMode(IconToastMode.Loading);
		seIconToastAutoHide(false);
		setManualTriggerErrorMessage(undefined)

		const tdrData = new TdrData();
		tdrData.getManualTrigger(props.instrumentAssetId, userPermissions.tokenManager.checkTokenIsValid, handleGetManualTriggerSuccess, handleGetManualTriggerError)
	}

	const handleGetManualTriggerSuccess = (response: any) => {

		props.onGetManualTriggerSuccess(response);

		setManualTriggerLoading(false);
		setShowIconToast(true);
		setIconToastMode(IconToastMode.Check);
		seIconToastAutoHide(true)

	}

	const handleGetManualTriggerError = (error: any) => {

		setManualTriggerLoading(false);
		setShowIconToast(false);
		setManualTriggerErrorMessage("Unable to trigger waveform at this time. Please try again later.")
		props.onGetManualTriggerError && props.onGetManualTriggerError(error);
	}

	function handleIconToastAutohide() {
		setShowIconToast(false);
	}

	return (
		<Container>
			<Row>
				<Col xs={"auto"}>
					<Button onClick={handleGrabWaveformButtonClick} disabled={manualTriggerLoading}>Trigger Waveform</Button>
				</Col>
				{showIconToast && <Col xs={1} className="px-2 d-flex justify-content-start align-items-center">

					<IconToast mode={iconToastMode} show={showIconToast} autoHide={iconToastAutoHide} onAutohide={handleIconToastAutohide} style={{ height: 30, width: 30 }} />

				</Col>}

				{manualTriggerErrorMessage && <Col xs={"auto"} className="px-2 d-flex justify-content-start align-items-center">
					<MessageToast text={manualTriggerErrorMessage} mode={ToastMode.Error} show={true}></MessageToast>
				</Col>}
			</Row>
		</Container>
	);

}
