import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Toast } from 'react-bootstrap';
import { MessageToast, ToastMode } from './MessageToast'

interface Props {
	title: string;
	toastText: string;
	toastShow: boolean;
	toastMode: ToastMode;
	toastAutoHide?: boolean;
	onToastAutohide?: () => void;
}

export function CardTitleBar(props: Props) {

	return (

		<Card.Title className="mb-2" >
			<Container fluid>
				<Row className="d-flex justify-content-between" >
					<Col xs="12" sm="auto" ><h5 className="page-header" >{props.title}</h5></Col>
					{props.toastShow && <Col xs={12} sm="auto" className="d-flex justify-content-end" >
						<MessageToast text={props.toastText} mode={props.toastMode} autoHide={props.toastAutoHide} show={props.toastShow} onAutohide={props.onToastAutohide} />
					</Col>
					}
				</Row>
			</Container>
		</Card.Title>
	);
}
