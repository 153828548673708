import * as React from 'react';
import { GridColumnMenuCheckboxFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';

interface Props extends GridColumnMenuProps {
	data: any;
}

export default class ColumnMenuCheckboxFilter extends React.Component<Props> {
	render() {
		return (
			<GridColumnMenuCheckboxFilter {...this.props} expanded={true} />
		);
	}
}
