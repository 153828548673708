import React, { useContext, useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { LoadingGraphic } from '../shared/LoadingGraphic';
import { Customers } from '../Data/Customers'
import { UserPermissionsContext } from '../Contexts/UserPermissionsContext';

interface Props {
	onCustomerChange: (customerId: string) => void
}

export default function CustomerDropdown(props: Props) {
	const userContext = useContext(UserPermissionsContext);
	const [selectedCustomerId, setSelectedCustomerId] = useState("")
	const [customersLoading, setCustomersLoading] = useState(false);
	const [customersList, setCustomersList] = useState([]);	

	useEffect(() => {
		setCustomersLoading(true)
	}, [])

	function handleCustomerDropdownChange(event: any) {
		setSelectedCustomerId(event.target.value.id);
	}

	useEffect(() => {
		props.onCustomerChange(selectedCustomerId);
	}, [selectedCustomerId])

	function handleGetCustomersSuccess(customersResponse: any) {
		setCustomersList(filterCustomerData(customersResponse));		
		setCustomersLoading(false);

		if (customersResponse.length === 1) {
			setSelectedCustomerId(customersResponse[0].id);
		}
	}

	function filterCustomerData(customerData: any) {	
		return customerData.filter((value: any) => 
		{ return !value.customerType.toLowerCase().includes('limsclient') 
			&& !value.customerType.toLowerCase().includes('limsendclient')})
        	.sort((a: any, b: any) => a.name.localeCompare(b.name));
	}

	if (customersLoading) {
		return (
			<Col xs={12}>
				<Customers refreshData={customersLoading} onDataGet={handleGetCustomersSuccess} checkTokenIsValid={userContext.tokenManager.checkTokenIsValid} />
				<div style={{ height: "50px" }}><LoadingGraphic small /></div>
			</Col>
		)
	}
	else if (customersList && customersList.length !== 1) {
		return (
			<Col xs={12}>
				Customer
				<DropDownList name="customers"
					data={customersList}
					textField="name"
					dataItemKey="id"
					onChange={handleCustomerDropdownChange}
					className="dropDownList"
				/>
			</Col>
		)
	}
	else {
		return null;
	}
}

