import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FeederDetailGraphic } from './GraphicComponents/FeederDetailGraphic'
import { LoadingGraphic } from '../shared/LoadingGraphic'
import { AlarmTable } from './AlarmTable';
import { DataPointTypes } from '../../constants/datapointtypes';
import { AlarmConfig, IFeederAlarmConfig } from '../../constants/alarmconfig';
import StreetsenseUI from './StreetSense/StreetSenseUI'
import DetailPanelCharts from './DetailPanelCharts';
import { ImpedanceUI } from './Impedance';
import { FeederPhases } from '../shared/enums/Assets'

interface Props {
	loading: boolean;
	feederAssetHierarchy: any;
	transformerData: any;
	preFaultData: any;
	substationName: string;
	isAlarmHistory?: boolean;
	showAlarmUI: boolean;
	onExpandChart: () => void;
	expanded: boolean;
}

const defaultFeederDataPointTypes = [DataPointTypes.VoltageMean30minutes, DataPointTypes.CurrentMean30minutes];
const feederDataPointTypesList = [DataPointTypes.VoltageMean30minutes, DataPointTypes.CurrentMean30minutes, DataPointTypes.CurrentWaveform, DataPointTypes.ActivePower30min, DataPointTypes.ReactivePower30minutes];

export default function FeederDetailView(props: Props) {
	const [graphicHidden, setGraphicHidden] = useState(false);

	const [selectedAlarm, setSelectedAlarm] = useState<IFeederAlarmConfig | undefined>();
	const [selectedDataPointTypes, setSelectedDataPointTypes] = useState(defaultFeederDataPointTypes);

	const handleAlarmClick = (alarm: IFeederAlarmConfig, assetId?: string) => {

		if (selectedAlarm === alarm) {
			setSelectedDataPointTypes(defaultFeederDataPointTypes);
			setSelectedAlarm(undefined);
		}
		else {
			setSelectedDataPointTypes(alarm && alarm.dataPointTypes || defaultFeederDataPointTypes);
			setSelectedAlarm(alarm);
		}

	}

	if (props.loading) {
		return (
			<LoadingGraphic />
		)
	}
	else if (props.feederAssetHierarchy) {

		const onChartExpand = () => {
			props.onExpandChart();
			setGraphicHidden(!graphicHidden);
		}

		const feederAlarmTypes = props.showAlarmUI ? AlarmConfig.feederAlarms : AlarmConfig.feederAlarms.filter((alarm) => alarm.isPreFault);

		const phaseL1 = props.feederAssetHierarchy.phases.find((phase: any) => phase.assetTypeKey === FeederPhases.L1);
		const phaseL2 = props.feederAssetHierarchy.phases.find((phase: any) => phase.assetTypeKey === FeederPhases.L2);
		const phaseL3 = props.feederAssetHierarchy.phases.find((phase: any) => phase.assetTypeKey === FeederPhases.L3);

		const xl = graphicHidden ? '12' : '6';

		return (
			<Container fluid className="px-0 pt-1" style={{ width: "99%" }} >
				<Row className="px-0 pb-1"  >
					<Col>
						<div style={{ fontWeight: 400, fontSize: "1.15em" }}>
							{props.substationName}
						</div>
						<div style={{ fontWeight: 200, fontSize: "1em" }}>
							Feeder: {props.feederAssetHierarchy.assetName}
						</div>

					</Col>
				</Row>
				<Row className="px-0 panelFeederDetailBody" noGutters >
					<Col xs="12" xl="6" className="px-0 pr-xl-2 pb-2">
						<Row noGutters className="p-0">
							{!graphicHidden && <FeederDetailGraphic
								alarmTypes={feederAlarmTypes}
								feederData={props.feederAssetHierarchy}
								busbarData={props.transformerData.busbars}
								preFaultData={props.preFaultData}
								selectedAlarm={selectedAlarm}
								onAlarmClick={handleAlarmClick}
							/>}
						</Row>
						{(props.feederAssetHierarchy.hasVisnet && !graphicHidden) && <Row>
							<Container>
								<ImpedanceUI phaseL1={phaseL1} phaseL2={phaseL2} phaseL3={phaseL3} />
							</Container>
						</Row>
						}
					</Col>
					<Col xs="12" xl={xl} className="px-0">
						<Row>
							{props.showAlarmUI && <Col xs="12">
								<AlarmTable
									assetHeaderText="Phase"
									assetHeaderField="assetName"
									loading={props.loading}
									alarmsData={[props.feederAssetHierarchy]}
									isAlarmHistory={props.isAlarmHistory} />
							</Col>
							}
							<Col xs="12" className="pt-4">
								<StreetsenseUI feederAssetHierarchy={props.feederAssetHierarchy} transformerAssetHierarchy={props.transformerData} />
							</Col>
							<Col xs="12" className="pt-2">
								<DetailPanelCharts
									title="Feeder Charts"
									phases={props.feederAssetHierarchy.phases}
									busbarAsset={props.transformerData}
									defaultDataPointTypes={selectedDataPointTypes}
									dataPointTypesList={feederDataPointTypesList}
									onExpandChart={onChartExpand}
									expanded={props.expanded}
									graphicHidden={graphicHidden}
								/>
							</Col>
						</Row>
					</Col>
				</Row >

			</Container >
		)
	}
	else {
		return null;
	}

}
