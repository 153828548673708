import * as React from 'react';
import resizeIcon from '../../images/resizeLogo.png';

interface ResizeIconProps {
    onClick: () => void;
    expanded: boolean;
}

export function ResizeIcon(props: ResizeIconProps) {
    const className = props.expanded ? 'resize-logo-expanded' : 'resize-logo-expand'

    return (
        <img src={resizeIcon} onClick={props.onClick} alt="Resize Charts" height={30} className={className} />
    )
}
