import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Load Highcharts modules
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

interface Props {
	series: any[];
	chartTitle: string;
	chartSubtitle?: string;
	enableBinaryDownload?: boolean;
	yAxisSettings: yAxisSettings[];
	onBinaryDownloadClick?: () => void;
	height?: string;
	onChartExpand?: () => void;
	width?: number;
	expanded?: boolean;
}

export interface yAxisSettings {
	label: string,
	lineWidth?: number;
	opposite: boolean //setting opposite to true puts the axis on the right side of the chart
}

const CHART_ELEMENTS_COLOUR = "#fff";
const EXPORTED_CHART_ELEMENTS_COLOUR = "#000";

export default function LineChart(props: Props) {

	const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
	const [chartOptions, setChartOptions] = useState(buildOptions(props.series, props.yAxisSettings, props.chartTitle, containerRef, props.chartSubtitle, props.enableBinaryDownload, props.onBinaryDownloadClick, props.height));
	const [expandedOptions, setExpandedOptions] = useState(buildOptions(props.series, props.yAxisSettings, props.chartTitle, containerRef, props.chartSubtitle, props.enableBinaryDownload, props.onBinaryDownloadClick, "50%"));

	useEffect(() => {
		const options = buildOptions(props.series, props.yAxisSettings, props.chartTitle, containerRef, props.chartSubtitle, props.enableBinaryDownload, props.onBinaryDownloadClick, props.height);
		const expandedChartOptions = buildOptions(props.series, props.yAxisSettings, props.chartTitle, containerRef, props.chartSubtitle, props.enableBinaryDownload, props.onBinaryDownloadClick, "50%");

		setChartOptions(options);
		setExpandedOptions(expandedChartOptions);
	}, [props.chartSubtitle, props.chartTitle, props.enableBinaryDownload, props.expanded, props.height, props.onBinaryDownloadClick, props.series, props.yAxisSettings])

	//Two separate charts required with slightly differing options due to resizing to incorrect height when using single chart element
	return (
		<div style={{ width: "100%", height: "100%" }} ref={containerRef}>
			{props.expanded
				? <HighchartsReact highcharts={Highcharts} options={expandedOptions} style={{ width: "100%" }} />
				: <HighchartsReact highcharts={Highcharts} options={chartOptions} style={{ width: "100%" }} />}
		</div>
	)
}

function buildOptions(series: any, yAxisSettings: any, chartTitle: string, ref: React.MutableRefObject<HTMLInputElement>, chartSubtitle?: string, enableBinaryDownload?: boolean, onBinaryDownloadClick?: any, height?: string) {

	let exportMenuItems = ['downloadPNG', 'downloadCSV'];

	if (enableBinaryDownload) {
		exportMenuItems.push('binary');
	}
	const yAxes = yAxisSettings.map((axis: yAxisSettings) => {
		return {
			title: {
				text: axis.label,
				style: {
					color: CHART_ELEMENTS_COLOUR
				}
			},
			labels: {
				style: {
					color: CHART_ELEMENTS_COLOUR
				},
				distance: 50
			},
			gridLineWidth: 0,
			lineWidth: axis.lineWidth || 2,
			lineColor: CHART_ELEMENTS_COLOUR,
			opposite: axis.opposite
		}
	})

	return {
		chart: {
			type: 'line',
			zoomType: 'xy',
			backgroundColor: 'transparent',
			borderWidth: 0,
			style: {
				'font-family': 'Roboto',
			},
			height: height,
		},
		time: {
			useUTC: false
		},
		title: {
			text: chartTitle,
			style: {
				color: CHART_ELEMENTS_COLOUR,
				fontSize: "14px"
			}
		},
		subtitle: {
			text: chartSubtitle,
			style: {
				color: CHART_ELEMENTS_COLOUR
			}
		},
		legend: {
			enabled: true,
			itemStyle: {
				color: CHART_ELEMENTS_COLOUR
			}
		},
		tooltip: {
			shared: true,
			pointFormatter: function (this: any): string {
				return `<span style="color: ${this.color}">●</span> ${this.series.name}: <b>${Math.round(this.y)}</b><br/>`
			}
		},
		xAxis:
		{
			type: 'datetime',
			labels: {
				x: -15,
				style: {
					color: CHART_ELEMENTS_COLOUR
				}
			},
			showLastLabel: true,
			maxPadding: 0.05,
			lineWidth: 1,
			lineColor: CHART_ELEMENTS_COLOUR
		},
		yAxis: yAxes,
		plotOptions: {
			series: {
				lineWidth: 2,
			},
		},
		credits: {
			enabled: false
		},
		exporting: {
			enabled: true,
			menuItemDefinitions: {
				binary: {
					onclick: function () {
						onBinaryDownloadClick && onBinaryDownloadClick();
					},
					text: 'Download binary file'
				}
			},
			buttons: {

				contextButton: {
					menuItems: exportMenuItems

				}

			},
			chartOptions: {
				chart: {
					backgroundColor: "#FFF"
				},
				title: {
					style: {
						color: EXPORTED_CHART_ELEMENTS_COLOUR
					}
				},
				subtitle: {
					style: {
						color: EXPORTED_CHART_ELEMENTS_COLOUR
					}
				},
				legend: {
					itemStyle: {
						color: EXPORTED_CHART_ELEMENTS_COLOUR
					}
				},
				xAxis:
				{
					title: {
						style: {
							color: EXPORTED_CHART_ELEMENTS_COLOUR
						}
					},
					labels: {
						style: {
							color: EXPORTED_CHART_ELEMENTS_COLOUR
						}
					},
					lineColor: EXPORTED_CHART_ELEMENTS_COLOUR
				},
				yAxis:
				{
					title: {
						style: {
							color: "#000"
						}
					},
					labels: {
						style: {
							color: EXPORTED_CHART_ELEMENTS_COLOUR
						}
					},
					lineColor: EXPORTED_CHART_ELEMENTS_COLOUR
				},
			}
		},

		navigation: {
			menuStyle: {
				"-webkit-box-shadow": "none",
				"box-shadow": "none"
			},
			buttonOptions: {
				align: 'right',
				symbol: 'download',
				symbolFill: CHART_ELEMENTS_COLOUR,
				symbolStroke: CHART_ELEMENTS_COLOUR,
				x: -8,
				theme: {
					fill: 'none',
					stroke: 'none',
				},
			}
		},
		responsive: {
			rules: [{
				condition: {
					maxWidth: 300,
				},
				chartOptions: {
					chart: {
						height: "350px",
					},
					yAxis: {
						labels: {
							align: 'left',
							x: -12,
							y: 0,
						},
						title: {
							text: ' ',
						}
					}
				}
			}, {
				condition: {
					maxWidth: 850,
				},
				chartOptions: {
					chart: {
						height: "300px",
					}
				}
			}]
		},
		series: series,
	};


}