const dev = {
  apiGateway: {
    AUTH_API: 'https://auth.sub360test.co.uk',
    META_API: 'https://meta.sub360test.co.uk',
    DATA_API: 'https://data.sub360test.co.uk',
    CONFIG_API: 'https://lvcap.sub360test.co.uk',
    ALARMS_API: 'https://alarms.sub360test.co.uk',
    MQTT_SETTING_KEY: 'test'
  }
}

const prod = {
  apiGateway: {
    AUTH_API: 'https://auth.substation360.co.uk',
    META_API: 'https://meta.substation360.co.uk',
    DATA_API: 'https://data.substation360.co.uk',
    CONFIG_API: 'https://lvcap.substation360.co.uk',
    ALARMS_API: 'https://alarms.substation360.co.uk',
    MQTT_SETTING_KEY: 'test'
  }
}

const beta = {
  apiGateway: {
    AUTH_API: 'https://auth-beta.substation360.co.uk',
    META_API: 'https://meta-beta.substation360.co.uk',
    DATA_API: 'https://data-beta.substation360.co.uk',
    CONFIG_API: 'https://lvcap-beta.substation360.co.uk',
    ALARMS_API: 'https://alarms-beta.substation360.co.uk',
    MQTT_SETTING_KEY: 'test'
  }
}

const loadEnvironmentConfig = () => {
   switch (process.env.REACT_APP_TARGET_ENV ?? process.env.NODE_ENV) {
         case "development": return dev;
         case "production": return prod;
         case "beta": return beta;
         default: return dev;
   }
};
const config = loadEnvironmentConfig();

export default config
