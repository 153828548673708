import * as React from 'react';
import Header from './Header';
import Footer from './Footer'

export interface LayoutProps {
    userIsAuthenticated: boolean;
    children?: React.ReactNode;
}

export interface LayoutState {
    selected?: '/',
}

export class Layout extends React.Component<LayoutProps, LayoutState> {

    constructor(props: LayoutProps) {
        super(props)

        this.state = {
            selected: '/',
        }

    }

    onSelect = (selected: any) => {
        this.setState({ selected: selected });
    };

    public render() {

        return <section className="layout-container">
            <Header />

            <div className="layout-content">
                {this.props.children}
            </div>

			<Footer />
        </section>
    }
}
export default Layout;