import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingGraphic } from '../../shared/LoadingGraphic';
import moment from 'moment';
import { UserPermissionsContext } from '../../Contexts/UserPermissionsContext';
import CategorisedDataAPIs from '../../Data/CategorisedDataAPIs'
import { MessageToast, ToastMode } from '../../shared/MessageToast'
import { IDataPointType } from '../../../constants/datapointtypes';
import LineChart, { yAxisSettings } from './Highchart'
import { getPhaseSeriesFormatting } from './PhaseChartFormatting'

interface Props {
	dataPointType: IDataPointType;
	phaseAssets: any;
	displayCount: number; //number of waveform charts to display, starting from most recent
	expanded?: boolean;
}

export default function WaveformPhaseDataChart(props: Props) {

	const userPermissions = useContext(UserPermissionsContext);

	const [seriesData, setSeriesData] = useState<any[] | undefined>([]);
	const [categorisedDataLoading, setCategorisedDataLoading] = useState(true);
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastMode, setToastMode] = useState(ToastMode.Error);

	const chartYAxisSettings: yAxisSettings[] = [{
		label: props.dataPointType.unit,
		lineWidth: 1,
		opposite: false
	}];

	useEffect(() => {
		if (props.phaseAssets && props.phaseAssets.length > 0 && props.dataPointType) {
			getCategorisedData();
		}
	}, [JSON.stringify(props.phaseAssets), props.dataPointType.dataPointTypeId]);

	function getCategorisedData() {
		if (props.phaseAssets) {
			const phaseAssetIDs = props.phaseAssets.map((phase: any) => { return phase.assetId });

			setCategorisedDataLoading(true);

			const categorisedDataAPIs = new CategorisedDataAPIs();

			const endTime = moment().toISOString();
			const startTime = moment().add(-1, 'M').toISOString();

			categorisedDataAPIs.getDataByAssetAndDataPointTypeId(phaseAssetIDs, [props.dataPointType.dataPointTypeId.toString()], startTime, endTime, userPermissions.tokenManager.checkTokenIsValid, handleGetCategorisedDataSuccess, handleGetCategorisedDataError)
		}
	}

	const handleGetCategorisedDataSuccess = (dataResponse: any) => {

		const formattedSeriesData: any[] = [];
		for (var i = 0; i < props.displayCount; i++) {
			formattedSeriesData.push(formatWaveformData(dataResponse, props.dataPointType, props.phaseAssets, i));
		}

		if(!formattedSeriesData || formattedSeriesData.length === 0 || formattedSeriesData[0].length === 0){
			setToastText(`No data found for ${props.dataPointType.title}.`)
			setToastMode(ToastMode.Info);
			setShowToast(true);
		}

		setSeriesData(formattedSeriesData);
		setCategorisedDataLoading(false);
	}

	const handleGetCategorisedDataError = (error: any) => {
		setToastText(`Unable to load ${props.dataPointType.title} chart${props.displayCount > 1 && "s"} at this time. Please try again later.`)
		setToastMode(ToastMode.Error);
		setShowToast(true);

		setSeriesData([]);
		setCategorisedDataLoading(false);
	}

	if (categorisedDataLoading) {
		return (
			<div style={{ height: "100px" }}>
				<LoadingGraphic />
			</div>
		)
	}
	else {
		return <Container fluid className="px-0">
			<Row noGutters>
				{showToast ? <Col xs={12} className="d-flex justify-content-center"><MessageToast text={toastText} mode={toastMode} show={showToast} ></MessageToast></Col>
					: seriesData && seriesData.map(dataItem => <Col xs={12} >
						<LineChart
							series={dataItem}
							chartTitle={props.dataPointType.title}
							yAxisSettings={chartYAxisSettings}
							height={(9 / 16 * 100) + '%'}
							expanded={props.expanded}
						/>
					</Col>
					)
				}
			</Row>
		</Container>
	}
}

function formatWaveformData(categorisedData: any, dataPointType: IDataPointType, phaseAssets: any, waveformElementDistanceFromLast: number) {
	return categorisedData.map((phaseData: any) => {

		if (phaseData.series.categorisedData && Object.keys(phaseData.series.categorisedData).length > 0) {

			let seriesdata: any[] = [];
			const phaseAsset = phaseAssets.find((phase: any) => phaseData.assetId === phase.assetId);
			const phaseSeriesFormatting = getPhaseSeriesFormatting(phaseAsset.assetTypeKey, phaseAsset.assetName);
			const categorisedDataValues = phaseData.series.categorisedData[dataPointType.dataPointTypeId].values;
			const dataPointIDs = Object.keys(categorisedDataValues);

			let waveform = null;

			dataPointIDs.forEach((dataPoint: any) => {

				const waveformIndex = categorisedDataValues[dataPoint].length - 1 - (waveformElementDistanceFromLast ? waveformElementDistanceFromLast : 0);

				if (categorisedDataValues[dataPoint][waveformIndex] !== null) {
					waveform = categorisedDataValues[dataPoint][waveformIndex];
				}
			});

			if (waveform != null) {

				waveform = JSON.parse(waveform);

				seriesdata = waveform.Value;

				const startTime = (waveform.StartPoint === 0 ? waveform.TimeStampStart : waveform.StartPoint) * 1000;

				return {
					data: JSON.parse(waveform.Value),
					color: phaseSeriesFormatting.colour,
					name: phaseSeriesFormatting.label,
					pointStart: startTime,
					pointInterval: Math.abs(waveform.Interval) * 1000,

				};
			}
		}
	}).filter((dataItem: any) => dataItem !== undefined);
}
