import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

interface State {
    scrollLeftButtonEnabled: boolean;
    scrollRightButtonEnabled: boolean;
}
class HorizontalScrollContainer extends React.Component<{}, State> {

    private scrollContainerRef: React.RefObject<any>

    constructor(props = {}) {
        super(props)

        this.state = { scrollLeftButtonEnabled: false, scrollRightButtonEnabled: true };

        this.scrollContainerRef = React.createRef<HTMLDivElement>();
        window.addEventListener('resize', this.setScrollButtonStatus);
    }

    onScrollRightClick = () => {
        if (this.state.scrollRightButtonEnabled) {
            let scrollPosition = this.scrollContainerRef.current.scrollLeft;
            let scrollToPosition = scrollPosition + 200;

            this.scrollContainerRef.current.scrollTo({ left: scrollToPosition, behavior: 'smooth' })
        }
    }

    onScrollLeftClick = () => {
        if (this.state.scrollLeftButtonEnabled) {

            let scrollPosition = this.scrollContainerRef.current.scrollLeft;
            let scrollToPosition = scrollPosition - 200;

            this.scrollContainerRef.current.scrollTo({ left: scrollToPosition, behavior: 'smooth' })
        }
    }

    setScrollButtonStatus = () => {
        if (this.scrollContainerRef && this.scrollContainerRef.current) {

            const scrollWidth = this.scrollContainerRef.current.scrollWidth; //full width of the content
            const containerWidth = this.scrollContainerRef.current.offsetWidth; //the width of the viewing container
            const scrollPosition = this.scrollContainerRef.current.scrollLeft; //current scroll position
            const scrollEnd = scrollWidth - containerWidth; //end of scroll position

            if (scrollWidth === containerWidth) {
                //scroll area is the same size as the viewable area (no need to scroll)
                this.setState({ scrollLeftButtonEnabled: false, scrollRightButtonEnabled: false })
            }
            else if (scrollPosition === scrollEnd) {
                //scroll position is at the end of the scrollable area
                this.setState({ scrollLeftButtonEnabled: true, scrollRightButtonEnabled: false })
            }
            else if (scrollPosition === 0) {
                //scroll position is at the start of the scrollable area
                this.setState({ scrollLeftButtonEnabled: false, scrollRightButtonEnabled: true })
            }
            else {
                //scroll position somewhere in the middle of the scrollable area
                this.setState({ scrollLeftButtonEnabled: true, scrollRightButtonEnabled: true })
            }
        }
    }

    componentDidMount() {
        this.setScrollButtonStatus();
    }

    render() {
        return (
            <Row noGutters className="flex-nowrap" style={{ width: "90%" }}>
                <Col xs="1" className="d-flex justify-content-center align-items-center text-center" >
                    <div onClick={this.onScrollLeftClick} className={this.state.scrollLeftButtonEnabled ? 'scrollIcon' : 'scrollIcon-disabled'}>
                        <FontAwesomeIcon icon={faChevronCircleLeft} size="2x" />
                    </div>
                </Col>
                <Col >
                    <div ref={this.scrollContainerRef} className="scrollContainer" onScroll={this.setScrollButtonStatus} style={{ display: "flex", overflowX: "auto" }}>
                        {this.props.children}
                    </div>
                </Col>
                <Col xs="1" className="d-flex justify-content-center align-items-center text-center" >
                    <div onClick={this.onScrollRightClick} className={this.state.scrollRightButtonEnabled ? 'scrollIcon' : 'scrollIcon-disabled'}>
                        <FontAwesomeIcon icon={faChevronCircleRight} size="2x" />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default HorizontalScrollContainer;