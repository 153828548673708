import * as React from 'react';

export interface InfoMessage {
    show: boolean;
    warn: boolean;
    message: string;
    successMessage?: boolean;
    error: boolean;
}

export function InfoBanner(props: any) {
    if (!props.message.show) {
        return null;
    }

    var alertClass =
        'alert-label alert ' + ((props.message.error) ? 'alert-danger' : (props.message.warn) ?'alert-warning':(props.message.successMessage) ?'alert-success':'alert-info');

    return <label className={alertClass}>{(props.message.successMessage) ? <span className="glyphicon glyphicon-ok"> </span>:''}{props.message.message}</label>;
}