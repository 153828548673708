import React from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ContactPopper from './ContactPopper';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			display: 'flex',
			height: "30px",
			top: 'auto',
			bottom: 0,
			backgroundColor: theme.palette.eaPurple.main,
			color: theme.palette.primary.main,
			justifyContent: 'center',
			alignItems: 'center'
		},
		grid: {
			marginTop: 0,
			marginBottom: 0,

			padding: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(10),
			},

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(20),
			},
			pointerEvents: "none",
		},
		text: {
			display: 'flex',
			alignItems: 'center',
			pointerEvents: "all",
		},
	})
);
export default function Footer() {
	const classes = useStyles();

	const [anchorElement, setAnchorElement] = React.useState<HTMLButtonElement | null>(null);
	const [open, setOpen] = React.useState(false);

	function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
		setAnchorElement(event.currentTarget);
		setOpen((prev) => !prev);
	}

	const handleClickAway = () => {
		setOpen(false);
	};

	return <AppBar position='relative' className={classes.appBar}>
		<Grid container className={classes.grid} direction="row" justify={"space-between"}>
			<Grid item xs className={classes.text}>
				<Typography variant="caption" noWrap>Copyright @ EA Technology {new Date().getFullYear()}</Typography>
			</Grid>
			<Grid item xs className={classes.text} justify={"flex-end"}>
				<ClickAwayListener onClickAway={handleClickAway}>
					<div>
						<Button color="primary" onClick={handleClick}><Typography variant="caption" noWrap>Contact Support</Typography></Button>
						<ContactPopper open={open} anchorElement={anchorElement} />
					</div>
				</ClickAwayListener>
			</Grid>
		</Grid>
	</AppBar>
}
