import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import { LoadingGraphic } from '../../shared/LoadingGraphic'
import { CardTitleBar } from '../../shared/CardTitleBar'
import { ToastMode } from '../../shared/MessageToast'
import TdrDeviceSettingsAPIs, { DeviceDetails, DeviceSettings } from '../../Data/Tdr/TdrDeviceSettingsAPIs'
import { UserPermissionsContext, PermissionLevels, Roles } from '../../Contexts/UserPermissionsContext'

interface Props {
	loading?: boolean;
	instrumentAssetId: string;
	deviceSettings: DeviceDetails;
}

const useStyles = makeStyles((theme) => ({

	divider: {
		backgroundColor: theme.palette.grey[200],
		width: "100%"
	},
	typographyPrimary: {
		fontSize: '0.75em',
		fontWeight: 300,
		letterSpacing: '0.05em',

	},
	iconSpinning: {

		color: '#00AAE4',
		height: 20,
		width: 20,
	},

}));

const ValidationPatterns = {
	OneDigit: {
		pattern: "[0-9]",
		message: "Field must be a one digit number."
	},
	TwoDigits: {
		pattern: "[0-9]{1,2}",
		message: "Field must be a number with maximum of two digits."
	},
	ThreeDigits: {
		pattern: "[0-9]{1,3}",
		message: "Field must be a number with maximum of three digits."
	},
}

export default function TdrInstrumentDetails(props: Props) {
	const userPermissions = useContext(UserPermissionsContext);
	const userIsTdrConfigurator = (userPermissions.permissions.Tdr === PermissionLevels.Configurator || userPermissions.permissions.Tdr === PermissionLevels.CustomerConfigurator);
	const classes = useStyles();

	const [editModeEnabled, setEditModeEnabled] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("Success");
	const [toastMode, setToastMode] = useState(ToastMode.Success);
	const [formIsValid, setFormIsValid] = useState(true);

	const [savingSettings, setSavingSettings] = useState(false);

	const [voltageThreshold, setVoltageThreshold] = useState("");
	const [voltageDuration, setVoltageDuration] = useState("");
	const [phase, setPhase] = useState("");
	const [range, setRange] = useState("");
	const [gain, setGain] = useState("");
	const [model, setModel] = useState("");

	let formRef: React.RefObject<any>;
	formRef = React.createRef();

	const checkValidity = () => {
		setFormIsValid(formRef.current.checkValidity())
	};

	const handleSaveChanges = () => {
		setSavingSettings(true);

		const settings: DeviceSettings = {
			voltageThreshold: voltageThreshold,
			voltageDuration: voltageDuration,
			currentFsd: props.deviceSettings.currentFsd,
			phase: phase,
			tdrTrsMode: props.deviceSettings.tdrTrsMode,
			range: range,
			gain: gain,
			model: model,
			triggerMode: props.deviceSettings.triggerMode
		}

		const tdrDeviceSettingsAPIs = new TdrDeviceSettingsAPIs();
		tdrDeviceSettingsAPIs.setDeviceSettings(props.instrumentAssetId, settings, userPermissions.tokenManager.checkTokenIsValid, handleSaveChangesSuccess, handleSaveChangesError);

	}

	const handleSaveChangesSuccess = () => {
		setEditModeEnabled(false);
		setSavingSettings(false);

		setToastText("Settings successfully saved.")
		setToastMode(ToastMode.Success)
		setShowToast(true);
	}

	const handleSaveChangesError = () => {
		setSavingSettings(false);
		setToastText("Unable to save - please try again later.")
		setToastMode(ToastMode.Error)
		setShowToast(true);
	}

	const setSettingsStatesFromObject = () => {

		if (props.deviceSettings) {
			setVoltageThreshold(props.deviceSettings.voltageThreshold.toString());
			setVoltageDuration(props.deviceSettings.voltageDuration.toString());
			setPhase(props.deviceSettings.phase.toString());
			setRange(props.deviceSettings.range.toString());
			setGain(props.deviceSettings.gain.toString());
			setModel(props.deviceSettings.model.toString());
		}

		setEditModeEnabled(false)

	}

	useEffect(() => {
		setSettingsStatesFromObject();
	}, [props.deviceSettings])

	function renderSettingsRow(label: string, value: any, editable?: boolean, validation?: any, onChange?: any) {
		return <Row noGutters className="d-flex align-items-center pl-2 pt-2">
			<Col xs={10} xl={{ span: 6, offset: 2 }}  >
				<Typography className={classes.typographyPrimary}>{label} </Typography>
			</Col>

			{editable
				? <Form.Group as={Col} xs={2} xl={2}>

					<Form.Control
						required
						value={value}
						pattern={validation.pattern}
						onChange={(event: any) => { onChange && onChange(event.target.value); checkValidity(); }}
					/>
					<Form.Control.Feedback type="invalid">{validation.message}</Form.Control.Feedback>
				</Form.Group>

				: <Col xs={2} xl={2}> <Typography className={classes.typographyPrimary} noWrap>{value}</Typography></Col>
			}

		</Row>
	}

	if (props.loading) {
		return (
			<div style={{ height: "100px" }}>
				<LoadingGraphic />
			</div>
		)
	}
	else {

		return (

			<Card className="card-bg-secondary mt-2 mb-2 w-100"  >
				<Card.Body className="px-0">
					<CardTitleBar title="Device Settings" toastText={toastText} toastShow={showToast} toastAutoHide onToastAutohide={() => setShowToast(false)} toastMode={toastMode} />

					<Divider className={classes.divider} />
					<Container className="px-0 px-md-2">
						<Form ref={formRef} noValidate validated={true}>
							{renderSettingsRow("Voltage Threshold: ", voltageThreshold, editModeEnabled, ValidationPatterns.TwoDigits, (value: string) => setVoltageThreshold(value))}
							{renderSettingsRow("Voltage Duration: ", voltageDuration, editModeEnabled, ValidationPatterns.TwoDigits, (value: string) => setVoltageDuration(value))}
							{renderSettingsRow("Current FSD: ", props.deviceSettings && props.deviceSettings.currentFsd) /*not editable*/}
							{renderSettingsRow("Phase: ", phase, editModeEnabled, ValidationPatterns.OneDigit, (value: string) => setPhase(value))}
							{renderSettingsRow("TDR/TRS Mode: ", props.deviceSettings && props.deviceSettings.tdrTrsMode)  /*not editable*/}
							{renderSettingsRow("TRS Pre-trigger & pulse width/range: ", range, editModeEnabled, ValidationPatterns.ThreeDigits, (value: string) => setRange(value))}
							{renderSettingsRow("Gain: ", gain, editModeEnabled, ValidationPatterns.TwoDigits, (value: string) => setGain(value))}
							{renderSettingsRow("Model: ", model, editModeEnabled, ValidationPatterns.TwoDigits, (value: string) => setModel(value))}
							{renderSettingsRow("Balance, echo/cross & voltage trigger:  ", props.deviceSettings && props.deviceSettings.triggerMode)  /*not editable*/}

							{userIsTdrConfigurator && <Row noGutters className="pl-2 pt-2" >
								{editModeEnabled
									? <Col xs={12} className="d-flex justify-content-end">
										{savingSettings ? <CircularProgress className={`${classes.iconSpinning}`} />
											: <React.Fragment>
												<Button variant="success" onClick={() => handleSaveChanges()} className="mr-2" disabled={!formIsValid}><CheckIcon /></Button>
												<Button variant="secondary" onClick={setSettingsStatesFromObject}><CloseIcon /></Button>
											</React.Fragment>
										}
									</Col>
									: <Col xs={12} className="d-flex justify-content-end">
										<Button onClick={() => setEditModeEnabled(true)}><CreateIcon /></Button>
									</Col>
								}
							</Row> }
						</Form>
					</Container>

				</Card.Body>
			</Card>

		);
	}
}
