import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { GridColumnMenuFilterUIProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridColumnMenuFilterUIProps';

interface CustomFilterUIState {
    filterOperator?: any;
    filterValue?: string;
}

interface CustomFilterUIProps {
    value?: any;
    onChange?: any;
}

export default function DateTimeFilterUI<T extends GridColumnMenuFilterUIProps>(dateFormat: string): React.ComponentClass<T & CustomFilterUIProps, CustomFilterUIState> {
    return class extends React.Component<T & CustomFilterUIProps> {

        operators = [
            { text: 'Is before', value: 'lt' },
            { text: 'Is on or after', value: 'gt' },
        ]

        state: CustomFilterUIState = { filterOperator: this.operators[0] }

        onDateFilterChange = (event: any) => {
            this.setState({ filterValue: event.target.value });
            this.setFilterProps(event.target.value, this.state.filterOperator, event);
        }

        onOperatorDropdownChange = (event: any) => {
            this.setState({ filterOperator: event.target.value });
            this.setFilterProps(this.state.filterValue, event.target.value, event);
        }

        setFilterProps = (filterValue: string | undefined, filterOperator: any, event: any) => {
            const { firstFilterProps } = this.props;
            firstFilterProps.onChange({
                value: filterValue && filterOperator ? filterValue : '',
                operator: filterOperator ? filterOperator.value : '',
                syntheticEvent: event.syntheticEvent
            });
        }

        render() {
            return (
                <div>
                    <DropDownList name="operator"
                        data={this.operators}
                        value={this.state.filterOperator}
                        textField="text"
                        dataItemKey="value"
                        className="dropDownList"
                        onChange={this.onOperatorDropdownChange}

                    />

                    <DatePicker onChange={this.onDateFilterChange} format={dateFormat} formatPlaceholder="formatPattern" />
                </div>
            );
        }
    }
}
