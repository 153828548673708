import React, { RefObject, useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingGraphic } from '../../shared/LoadingGraphic';
import moment from 'moment';
import { UserPermissionsContext } from '../../Contexts/UserPermissionsContext';
import CategorisedDataAPIs from '../../Data/CategorisedDataAPIs'
import { MessageToast, ToastMode } from '../../shared/MessageToast'
import { IDataPointType } from '../../../constants/datapointtypes';
import LineChart, { yAxisSettings } from './Highchart'
import { getPhaseSeriesFormatting } from './PhaseChartFormatting'

interface Props {
	assetIDs: string[];
	dataPointType: IDataPointType;
	startDate: moment.Moment;
	phaseAssets: any;
	expanded?: boolean;
}

const PHASE_SORT_ORDER = ["L1 Phase", "L2 Phase", "L3 Phase"];

export default function NumericPhaseDataChart(props: Props) {

	const userPermissions = useContext(UserPermissionsContext);

	const [seriesData, setSeriesData] = useState<any[] | undefined>([]);
	const [categorisedData, setCategorisedData] = useState<any[] | undefined>([]);
	const [categorisedDataLoading, setCategorisedDataLoading] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastMode, setToastMode] = useState(ToastMode.Error);

	const chartYAxisSettings: yAxisSettings[] = [{
		label: props.dataPointType.unit,
		lineWidth: 1,
		opposite: false
	}];

	useEffect(() => {
		if (props.assetIDs && props.assetIDs.length > 0 && props.dataPointType && props.startDate) {
			getCategorisedData();
		}
	}, [props.assetIDs, props.dataPointType.dataPointTypeId]);

	function getCategorisedData() {
		if (props.assetIDs) {
			setSeriesData([]);
			setCategorisedData([]);
			setCategorisedDataLoading(true);

			const categorisedDataAPIs = new CategorisedDataAPIs();

			const endTime = moment().toISOString();
			const startTime = moment().add(-1, 'M').toISOString();

			categorisedDataAPIs.getDataByAssetAndDataPointTypeId(props.assetIDs, [props.dataPointType.dataPointTypeId.toString()], startTime, endTime, userPermissions.tokenManager.checkTokenIsValid, handleGetCategorisedDataSuccess, handleGetCategorisedDataError)
		}
	}

	const handleGetCategorisedDataSuccess = (dataResponse: any) => {
		const formattedSeriesData = formatNumericData(dataResponse, props.dataPointType, props.startDate, props.phaseAssets);

		if (!formattedSeriesData || formattedSeriesData.length == 0) {
			setToastText(`No data found for ${props.dataPointType.title}.`)
			setToastMode(ToastMode.Info);
			setShowToast(true);
		}

		setSeriesData(formattedSeriesData);
		setCategorisedData(dataResponse);
		setCategorisedDataLoading(false);
	}

	useEffect(() => {
		const formattedSeriesData = categorisedData && formatNumericData(categorisedData, props.dataPointType, props.startDate, props.phaseAssets)
		setSeriesData(formattedSeriesData);
	}, [props.startDate.toISOString()]);

	const handleGetCategorisedDataError = (error: any) => {
		setToastText(`Unable to load ${props.dataPointType.title} chart at this time. Please try again later.`)
		setToastMode(ToastMode.Error);
		setShowToast(true);

		setSeriesData([]);
		setCategorisedDataLoading(false);
	}

	if (categorisedDataLoading) {
		return (
			<div style={{ height: "100px" }}>
				<LoadingGraphic />
			</div>
		)
	}

	else {
		return <Container fluid className="px-0">
			<Row noGutters>
				<Col xs={12} className="d-flex justify-content-center">
					{showToast
						? <MessageToast text={toastText} mode={toastMode} show={showToast} ></MessageToast>
						: seriesData && (
							<>
								<LineChart
									series={seriesData}
									chartTitle={props.dataPointType.title}
									yAxisSettings={chartYAxisSettings}
									height={(9 / 16 * 100) + '%'}
									expanded={props.expanded}
								/>
							</>
						)
					}
				</Col>
			</Row>
		</Container >
	}

}

function formatNumericData(categorisedData: any, dataPointType: IDataPointType, dateStartFilter: moment.Moment, phaseAssets: any) {
	const data = categorisedData.map((phaseData: any) => {

		if (phaseData.series.categorisedData && Object.keys(phaseData.series.categorisedData).length > 0) {

			let seriesdata: any[] = [];
			let phaseAsset = phaseAssets.find((phase: any) => phaseData.assetId === phase.assetId);

			const phaseSeriesFormatting = dataPointType.isNonPhaseDataPoint
				? { colour: "#66CCCC", label: dataPointType.title }
				: getPhaseSeriesFormatting(phaseAsset.assetTypeKey, phaseAsset.assetName);

			const dataPointIDs = Object.keys(phaseData.series.categorisedData[dataPointType.dataPointTypeId.toString()].values);
			const times = phaseData.series.categorisedData[dataPointType.dataPointTypeId.toString()].times;

			times.forEach((time: any, index: number) => {
				const timeMoment = moment.utc(time)

				let addValueToSeries = true;

				if (dateStartFilter && moment.isMoment(dateStartFilter)) {
					if (timeMoment > dateStartFilter!) {
						addValueToSeries = true;
					}
					else {
						addValueToSeries = false;
					}
				}

				if (addValueToSeries) {
					dataPointIDs.forEach(dataPoint => {
						let data = phaseData.series.categorisedData[dataPointType.dataPointTypeId.toString()].values[dataPoint][index];

						if (data != null) {

							if (dataPointType.multiplier) data = data * dataPointType.multiplier;

							seriesdata.push(
								[
									Number(timeMoment.local().format('x')),
									Number(data)
								]
							)

						}
					})
				}
			});

			return {
				data: seriesdata,
				color: phaseSeriesFormatting.colour,
				name: phaseSeriesFormatting.label,

			};
		}

	}).filter((dataItem: any) => dataItem !== undefined);

	const sortedData = sortPhaseData(data);

	// Gets sum of L1 + L2 + L3 for Total Apparent Power chart
	if (dataPointType.title === "Total Apparent Power" && sortedData?.length >= 3) {
		const L1Data: number[][] = sortedData[0].data;
		const L2Data: number[][] = sortedData[1].data;
		const L3Data: number[][] = sortedData[2].data;

		const totalData: number[][] = [];

		L1Data.forEach((entry, index) => {
			const sum = entry[1] + L2Data[index][1] + L3Data[index][1];

			totalData.push([entry[0], sum]);
		})

		return {
			data: totalData,
			color: "#66CCCC",
			name: dataPointType.title,
		}
	}

	return sortedData;
}

function sortPhaseData(phaseData: any) {
	return phaseData.sort((a: any, b: any) => {
		if (a.name && b.name) {
			if (PHASE_SORT_ORDER.includes(a.name) && PHASE_SORT_ORDER.includes(b.name)) {
				return PHASE_SORT_ORDER.indexOf(a.name) - PHASE_SORT_ORDER.indexOf(b.name);
			}
			return a.name.localeCompare(b.name);
		}

		return 0;
	})
}
