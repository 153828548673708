import React, { useContext, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import moment from 'moment';
import { CardTitleBar } from '../../shared/CardTitleBar'
import { IncidentsGrid } from './IncidentsGrid';
import { UserPermissionsContext } from '../../Contexts/UserPermissionsContext';
import CategorisedDataAPIs from '../../Data/CategorisedDataAPIs'
import { MessageToast, ToastMode } from '../../shared/MessageToast'
import { DataPointTypes } from '../../../constants/datapointtypes';
import StreetsenseWaveformsDialog from './StreetsenseWaveformsDialog'

interface Props {
	feederAssetHierarchy: any;
	transformerAssetHierarchy: any;
}

const streetsenseDataPointTypeID = DataPointTypes.StreetsenseIncident.dataPointTypeId.toString();
const incidentGridStyle = { maxHeight: "200px" };

export default function StreetsenseUI(props: Props) {

	const userPermissions = useContext(UserPermissionsContext);

	const [incidentsData, setIncidentsData] = useState<any[] | undefined>([]);
	const [incidentsDataLoading, setIncidentsDataLoading] = useState(true);
	const [selectedIncident, setSelectedIncident] = useState<any>();
	const [waveformDialogOpen, setWaveformDialogOpen] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toastText, setToastText] = useState("");
	const [toastMode, setToastMode] = useState(ToastMode.Error);

	useEffect(() => {
		getIncidents();
	}, [props.feederAssetHierarchy.assetId]);

	function getIncidents() {
		if (props.feederAssetHierarchy) {
			setIncidentsDataLoading(true);

			const categorisedDataAPIs = new CategorisedDataAPIs();

			const endTime = moment().toISOString();
			const startTime = moment().add(-1, 'M').toISOString();

			categorisedDataAPIs.getDataByAssetAndDataPointTypeId([props.feederAssetHierarchy.assetId], [streetsenseDataPointTypeID], startTime, endTime, userPermissions.tokenManager.checkTokenIsValid, handleGetIncidentsDataSuccess, handleGetIncidentsDataError)
		}
	}

	const handleGetIncidentsDataSuccess = (dataResponse: any) => {
		const parsedResponse: any[] = [];

		if (dataResponse && dataResponse.length > 0 && dataResponse[0].series.categorisedData && Object.keys(dataResponse[0].series.categorisedData).length > 0) {
			const dataPointIDs = Object.keys(dataResponse[0].series.categorisedData[streetsenseDataPointTypeID].values);

			dataPointIDs.forEach(dataPoint => {
				const dataValues = dataResponse[0].series.categorisedData[streetsenseDataPointTypeID].values[dataPoint];
				dataValues.forEach((dataValue: any) => {
					parsedResponse.push(JSON.parse(dataValue));
				})
			});
		}

		setIncidentsData(parsedResponse);
		setIncidentsDataLoading(false);
	}

	const handleGetIncidentsDataError = (error: any) => {
		setToastText("Unable to load Streetsense Incidents at this time. Please try again later.")
		setToastMode(ToastMode.Error);
		setShowToast(true);

		setIncidentsData([]);
		setIncidentsDataLoading(false);
	}

	const handleViewIncidentClick = (incident: any) => {
		setSelectedIncident(incident);
		setWaveformDialogOpen(true)

	};

	const handleWaveformsDialogClose = () => {
		setWaveformDialogOpen(false)
	}

	if (props.feederAssetHierarchy && (props.feederAssetHierarchy.hasStreetSense || (incidentsData && incidentsData.length > 0))) {
		return (
			<React.Fragment>
				<Card className="card-bg-secondary mt-2 mb-2 w-100"  >
					<Card.Body className="px-0">
						<CardTitleBar title="Streetsense Incidents" toastText={toastText} toastShow={false} toastMode={toastMode} />
						<Container fluid className="px-2">

							<Row noGutters className="px-0"  >
								<Col>
									{showToast ? <MessageToast text={toastText} mode={ToastMode.Error} show={true} />
										:
										<IncidentsGrid loading={incidentsDataLoading} incidentData={incidentsData} onViewIncidentClick={handleViewIncidentClick} style={incidentGridStyle} />
									}
								</Col>
							</Row>

						</Container>

					</Card.Body>
				</Card>
				{waveformDialogOpen && <StreetsenseWaveformsDialog incident={selectedIncident} open={waveformDialogOpen} onClose={handleWaveformsDialogClose} transformerAssetHierarchy={props.transformerAssetHierarchy} incidentFeederName={props.feederAssetHierarchy.assetName} />}
			</React.Fragment>
		)
	}
	else {
		return null;
	}

}
