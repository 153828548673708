import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import Map, { MapProps } from './Map'

interface MapContainerProps {
	id: string;
	width_xs?: string;
	width_sm?: string;
	width_md?: string;
	width_lg?: string;
	width_xl?: string;
	theme?: any;
}

const useStyles = makeStyles(
	(theme: any) => {
		return {

			mapWidth: {
				[theme.breakpoints.up('xs')]: {
					width: (props: MapContainerProps) => props.width_xs || "100%",
				},
				[theme.breakpoints.up('sm')]: {
					width: (props: MapContainerProps) => props.width_sm  || "100%",
				},
				[theme.breakpoints.up('md')]: {
					width: (props: MapContainerProps) => props.width_md || undefined,
				},
				[theme.breakpoints.up('lg')]: {
					width: (props: MapContainerProps) => props.width_lg || undefined,
				},
				[theme.breakpoints.up('xl')]: {
					width: (props: MapContainerProps) => props.width_xl || undefined,
				},
			},
		}
	}
);

const MapContainer = (props: MapContainerProps & MapProps) => {
	const theme = useTheme();
	const classes = useStyles({...props, theme});
	
	const [invalidateSize, setInvalidateSize] = React.useState(false);

	//this hook detects a change in any of the map width properties, and sets the invalidateSize to true. invalidateSize will be passed to the map component so that it can reset leaflet's size.
	React.useEffect(() => {
		setInvalidateSize(true)
	}, [props.width_xs, props.width_sm, props.width_md, props.width_lg, props.width_xl]);

	//this hook detects a change in invalidSize state variable, triggers a re-render, and then resets the invalidSize back to false
	React.useEffect(() => {
		return () => {
			setInvalidateSize(false)
		}

	}, [invalidateSize]);

	return (
		<div id={props.id} className={classes.mapWidth}>
			<Map {...props as MapProps} invalidateSize={invalidateSize} />
		</div>
	);
}

export default MapContainer