import * as _ from "lodash";

export interface IDataPointType {
  dataPointTypeId: number;
  unit: string;
  abbreviatedUnit: string;
  title: string;
  multiplier?: number;
  isWaveform?: boolean;
  isBusbarData?: boolean;
  isNonPhaseDataPoint?: boolean;
}

export interface IDataPointTypes {
  CurrentMean30minutes: IDataPointType;
  VoltageMean30minutes: IDataPointType;
  ActivePower30min: IDataPointType;
  CurrentWaveform: IDataPointType;
  VoltageWaveform: IDataPointType;
  StreetsenseIncident: IDataPointType;
  ReactivePower30minutes: IDataPointType;
  Impedance: IDataPointType;
  TransformerTemperature: IDataPointType;
  VoltageTHD30minutes: IDataPointType;
  SignalStrength: IDataPointType;
}

export const DataPointTypes: IDataPointTypes = {

	CurrentMean30minutes: { dataPointTypeId: 100047, unit: 'Amps [A]', abbreviatedUnit: 'A', title: 'Feeder Current (A)' },
	VoltageMean30minutes: { dataPointTypeId: 100049, unit: 'Volts [V]', abbreviatedUnit: 'V', title: 'Busbar Voltage (V)', isBusbarData: true },
	ActivePower30min: { dataPointTypeId: 100110, unit: 'kW', abbreviatedUnit: 'kW', title: 'Active Power (kW)', multiplier: 0.001 },
	CurrentWaveform: { dataPointTypeId: 100200, unit: 'Amps', abbreviatedUnit: 'A', title: 'Current Waveform (Amps)', isWaveform: true },
	VoltageWaveform: { dataPointTypeId: 100201, unit: 'Volts', abbreviatedUnit: 'V', title: 'Voltage Waveform (Volts)', isWaveform: true },
	StreetsenseIncident: { dataPointTypeId: 100251, unit: 'Amps', abbreviatedUnit: 'A', title: 'Current Waveform (Amps)', isWaveform: true },
	ReactivePower30minutes: { dataPointTypeId: 100111, unit: 'VAr',  abbreviatedUnit: 'VAr', title: 'Reactive Power (VAr)' },
	Impedance: { dataPointTypeId: 110025, unit: '',  abbreviatedUnit: '', title: '' },
  TransformerTemperature: { dataPointTypeId: 100050, unit: 'Celsius', abbreviatedUnit: '°C', title: 'Temperature °C', isNonPhaseDataPoint: true },
  VoltageTHD30minutes: { dataPointTypeId: 100059, unit: '%', abbreviatedUnit: '', title: 'Voltage THD'},
  SignalStrength: { dataPointTypeId: 100080, unit: 'dBm', abbreviatedUnit: '', title: 'Signal Strength', isNonPhaseDataPoint: true },
}

export function GetDataPointTypeById(id: number) {
	return _.find(DataPointTypes, (value: any) => value.dataPointTypeId === id);
}