import React, { useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as Leaflet from 'leaflet';
import { useLeaflet } from 'react-leaflet';
import AlarmSeverity from '../../../utils/AlarmSeverity'
import InstrumentTypes from '../../../constants/instrumenttypes'
import { MapMarkerIcon } from './MapMarkerIcon'
import { MarkerClusterGroup, MarkerClusterDonutWithRAG, MarkerCounts, MarkerClusterTooltip } from './MarkerClusterGroup'

interface Props {
	substations: any;
	alarmsData?: any;
	alarmTypes?: any;
	onMarkerClick?: any;
}

export default function SubstationMarkers(props: Props) {
	const mapContext = useLeaflet();

	function closePopups() {
		mapContext.map.closePopup();
	};

	function onMarkerClick(e: any, substation: any) {

		props.onMarkerClick(substation);

	}

	const markers = useMemo(() => buildMarkerList(props, onMarkerClick), [props.substations]);

	if (props.substations) {

		return <React.Fragment>
			<MarkerClusterGroup
				markers={markers}
				iconCreateFunction={getClusterIcon}
				spiderfyOnMaxZoom={true}
				showCoverageOnHover={false}
				onClusterMouseover={(cluster: any) => { renderClusterTooltip(cluster, mapContext.map) }}
				onClusterMouseout={closePopups}
			/>

		</React.Fragment>

	}
	else {
		return null;
	}

};

function checkSubstationInstrumentType(substation: any, instrumentType: InstrumentTypes) {
	return substation.attachedInstrumentTypes && substation.attachedInstrumentTypes.some((attachedInstrumentType: string) => attachedInstrumentType.trim().toLowerCase() === instrumentType.toLowerCase());
}

function getSubstationAlarms(substation: any, alarmsData: any) {
	let alarms = null;

	if (alarmsData && alarmsData.substations && alarmsData.substations.length > 0) {
		alarms = alarmsData.substations.find((alarmSubstation: any) => alarmSubstation.substationId === substation.id);

	}

	return alarms ? alarms.alarms : null;
}

function buildMarkerList(props: Props, onClick: any) {

	let maxSeverity = "NONE"
	let markers: any = [];

	props.substations && props.substations.map((substation: any) => {

		if (substation.gpsCoords) {

			const substationHasGuardInstrument = checkSubstationInstrumentType(substation, InstrumentTypes.Guard);
			const substationHasVisnetHubInstrument = checkSubstationInstrumentType(substation, InstrumentTypes.VisnetHub);
			const alarms = getSubstationAlarms(substation, props.alarmsData);

			maxSeverity = AlarmSeverity.getMaximumAlarmSeverityFromAlarmList(alarms);

			const iconStyle = AlarmSeverity.getAlarmStyles(maxSeverity, substationHasVisnetHubInstrument && substation.isMonitored);
			const position = { lng: substation.gpsCoords.longitude, lat: substation.gpsCoords.latitude };

			markers.push(
				Leaflet.marker(new Leaflet.LatLng(position.lat, position.lng),
					{
						icon: getIcon(iconStyle, substationHasGuardInstrument),
						attribution: iconStyle.key
					}
				)
					.bindTooltip(substation.name)
					.on('click', (e: any) => onClick(e, substation))
			);
		}
	});

	return markers;
}

function renderClusterTooltip(cluster: any, map: any) {

	const childMarkers = cluster.layer.getAllChildMarkers();
	const markerCounts: MarkerCounts = getMarkerCounts(cluster.layer, childMarkers);

	const content = ReactDOMServer.renderToStaticMarkup(<MarkerClusterTooltip markerCounts={markerCounts}></MarkerClusterTooltip>);

	Leaflet.popup({ closeButton: false })
		.setLatLng(cluster.layer.getLatLng())
		.setContent(content)
		.openOn(map);

}

function getIcon(iconStyle: any, hasGuard: boolean) {

	const icon = MapMarkerIcon(iconStyle.color, hasGuard);

	return new Leaflet.DivIcon({
		html: icon,
		className: 'svg-marker',
		iconSize: Leaflet.point(50, 50),
		iconAnchor: Leaflet.point(25, 50),
	});
}

function getClusterIcon(cluster: any) {

	const childMarkers = cluster.getAllChildMarkers();
	const markerCounts: MarkerCounts = getMarkerCounts(cluster, childMarkers);
	const assetCluster = MarkerClusterDonutWithRAG(markerCounts);

	return new Leaflet.DivIcon({
		html: assetCluster.html,
		className: 'svg-marker',
		iconSize: Leaflet.point(assetCluster.radius * 1.2, assetCluster.radius * 1.2),
		popupAnchor: [0, -25]
	});
}

function getMarkerCounts(cluster: any, childMarkers: any): MarkerCounts {

	let counts = {
		redCount: childMarkers.filter((marker: any) => marker.options.attribution === 'RED').length,
		amberCount: childMarkers.filter((marker: any) => marker.options.attribution === 'AMBER').length,
		greenCount: childMarkers.filter((marker: any) => marker.options.attribution === 'GREEN').length,
		unmonitoredCount: childMarkers.filter((marker: any) => marker.options.attribution === 'NONE').length,
		totalCount: cluster.getChildCount(),
	};

	return counts;
}
