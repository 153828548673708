import * as React from 'react';
import TokenRefresher from './TokenRefresher';

const tokenKey = 'jwt';
const tokenExpiryKey = 'jwtExpires';
const refreshTokenKey = 'refreshToken';

export class JwtManager extends React.PureComponent {

    getToken() : string | null {
        return localStorage.getItem(tokenKey);
    }

    setToken(token:  string) {
        localStorage.setItem(tokenKey, token);
    }

    removeToken(){
        localStorage.removeItem(tokenKey)
    }

    getRefreshToken(): string | null {
        return localStorage.getItem(refreshTokenKey);
    }

    setRefreshToken(refreshToken: string) {
        localStorage.setItem(refreshTokenKey, refreshToken);
    }

    getExpires(): Date | null {
        var expires = localStorage.getItem(tokenExpiryKey);
        if (!expires)
            return null;
        return new Date(Number(expires) * 1000);
    }

    checkTokenIsValid() : boolean {
        var token = this.getToken();
        var expiry = this.getExpires();

        let tokenIsValid = true;

        if (!token || token.length === 0) {
            //token is empty - set to invalid
            tokenIsValid = false;
        }
        else if (expiry && new Date() > expiry) {

            tokenIsValid = false;

            // Token has expired, try a refresh
            var tokenRefresher = new TokenRefresher({});

            tokenRefresher.refreshToken()
                .then((token: any) => { tokenIsValid = true; })
                .catch(() => {
                    //can't refresh - set to invalid
                    tokenIsValid = false;
                });
        }

        return tokenIsValid;
    }

    getPayload(): any {
        var token = this.getToken();

        if(token != null){
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }
        else{
            return null;
        }
    }

    checkTokenHasAdminRights = () => {
        let tokenPayload = this.getPayload();
        let hasAdminRights = false;
        

        if(tokenPayload.IsAdmin === "IsAdmin"){
            hasAdminRights = true; 
        }

        return hasAdminRights;
    }

    checkTokenHasLoginRights = () => {
        let tokenPayload = this.getPayload();
        let hasLoginRights = false;

        //temporarily using "VisNetTester" claim to determine if user can access the dashboard
        if(tokenPayload.VisNetTester === "VisNetTester" || tokenPayload.IsAdmin === "IsAdmin"){
            hasLoginRights = true; 
        }

        return hasLoginRights;
    }

    setExpires(expires: any) {
        localStorage.setItem(tokenExpiryKey, expires);
    }

    storeAuthResponse(token: string, expires: number, refreshToken: string) {
        this.setToken(token);
        this.setExpires(expires);
        this.setRefreshToken(refreshToken);
    }
};