import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

interface Props {
	open: boolean;
	anchorElement: HTMLButtonElement | null;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popper: {
			alignItems: 'center',
			border: '1px solid',
			padding: theme.spacing(3),
			backgroundColor: theme.palette.background.paper,
		},
		icon: {
			color: '#220639',
			marginRight: theme.spacing(1)
		},

	})
);
export default function Footer(props: Props) {
	const classes = useStyles();

	return <Popper open={props.open} anchorEl={props.anchorElement} placement={'top'} transition>
		{({ TransitionProps }) => (
			<Fade {...TransitionProps} timeout={350}>
				<Paper square className={classes.popper}>
					<Typography variant="subtitle2" align="left" paragraph>Got a question or need help?< br /> Contact us below:</Typography>

					<Typography variant="body2" paragraph>
						<FontAwesomeIcon icon={faPhoneAlt} className={classes.icon} />+44 (0) 151 347 2283
						</Typography>
					<Link href="mailto:monitoringservices@eatechnology.com" variant="body2" color="inherit">
						<FontAwesomeIcon icon={faEnvelope} className={classes.icon} />
						monitoringservices@eatechnology.com
					</Link>

				</Paper>
			</Fade>
		)}
	</Popper>
}