import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { JwtManager } from './components/shared/JwtManager';
import { Layout } from './components/Layout';
import { MapDashboard } from './components/pages/MapDashboard';
import { Login } from './components/pages/Login';
import { UserPermissionsProvider } from './components/Contexts/UserPermissionsContext';
import { MuiThemeEA } from './components/Contexts/MuiThemeEA';

export interface RouteProps {

	children?: React.ReactNode;
	location?: any;
}

export function Routes(props: RouteProps) {
	const tokenManager = new JwtManager({});

	const [userIsAuthenticated, setUserIsAuthenticated] = useState(tokenManager.checkTokenIsValid());

	function checkTokenIsValid() {
		if (!tokenManager.checkTokenIsValid()) {
			setUserIsAuthenticated(false);
		}
	};

	function handleAuthenticate(userIsAuthenticated: boolean) {
		setUserIsAuthenticated(userIsAuthenticated);
	};

	function logOut() {
		tokenManager.removeToken();
		setUserIsAuthenticated(false);
	}

	function PrivateRoute({ component: Component, ...rest }: any) {

		return (
			<Route {...rest}
				render={(props) => (
					userIsAuthenticated === true
						? <Component {...props} checkTokenIsValid={checkTokenIsValid} />
						: <Redirect to={{
							pathname: "/login",
							state: { from: props.location }
						}} />
				)}
			/>
		)
	};

	return <BrowserRouter>
		<UserPermissionsProvider tokenManager={tokenManager} onLogout={logOut}>
			<MuiThemeEA>
				<Layout userIsAuthenticated={userIsAuthenticated}>
					<PrivateRoute exact path='/' component={MapDashboard as any} />
					<Route exact path='/login'
						render={routeProps => (
							<Login routeComponentProps={routeProps} checkTokenIsValid={checkTokenIsValid} onAuthenticate={handleAuthenticate} />
						)}
					/>

					<PrivateRoute exact path='/MapDashboard/' component={MapDashboard as any} />

				</Layout>
			</MuiThemeEA>
		</UserPermissionsProvider>
	</BrowserRouter>
}

export default Routes;
