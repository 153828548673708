import config from '../../config';
import ApiResponseHandler from '../shared/ApiResponseHandler';
import { Data } from '../shared/interfaces/Data';

export class DataAPIs {

	private apiResponseHandler = new ApiResponseHandler({});

	private static readonly BASE_URL: string = `${config.apiGateway.DATA_API}/api/Data`;
	getLatestDataByAssetAndDataPointType = <T,>(assetId: number, datapointTypesID: number): Promise<Data<T>> => {

		const apiAddress = `${DataAPIs.BASE_URL}/recent/datapointtype/${datapointTypesID}/asset/${assetId}`;

		return this.apiResponseHandler.get(apiAddress)
			.then(data => {
				return data
			});
	}
}