import { useEffect } from 'react'
import ApiResponseHandler from '../../shared/ApiResponseHandler';
import config from '../../../config';
import moment from 'moment';

interface Props {
	customer: any;
	refreshData: boolean;
	checkTokenIsValid: any;
	onDataGet: (response: any) => void;
	onDataError?: (message: string) => void;
	alarmTimespanStartDate: moment.Moment;
	alarmTimespanEndDate: moment.Moment;
}

export default function AlarmHistoryByCustomer(props: Props) {
	useEffect(() => {
		if (props.alarmTimespanStartDate && props.alarmTimespanEndDate) {
			const apiResponseHandler = new ApiResponseHandler({});

			const abortController = new AbortController();
			const signal = abortController.signal;

			const endpointAddress = `${config.apiGateway.ALARMS_API}/api/alarmHistory/activationhistory/customer/${props.customer}?from=${props.alarmTimespanStartDate.toISOString()}&to=${props.alarmTimespanEndDate.toISOString()}`;

			const handleError = (error: any) => {
				props.onDataError && props.onDataError(error)
			}

			apiResponseHandler.callAPIGet(endpointAddress, props.onDataGet, handleError, props.checkTokenIsValid, signal);
			return () => {
				abortController.abort()
			}
		}
	}, [props.customer, props.refreshData, props.alarmTimespanStartDate, props.alarmTimespanEndDate]);

	return null;
}
