import React, { useContext, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { LoadingGraphic } from '../shared/LoadingGraphic';
import { LicenceAreasAndDistrictsByCustomer } from '../Data/LicenceAreasAndDistricts';
import { UserPermissionsContext } from '../Contexts/UserPermissionsContext';
import { LicenceAreaAndRelatedDistricts, GeoFenceConfig } from '../shared/interfaces/Regions';

interface Props {
	customerId: string;
	substationsList: any[];
	selectedLicenceArea: any;
	selectedDistrict: any;
	onLicenceAreaChange: (licenceArea: LicenceArea | undefined, geofence: GeoFenceConfig | undefined) => void;
	onDistrictChange: (district: string, geofence?: GeoFenceConfig | undefined) => void;
	substationsDataLoading: boolean;

}
interface LicenceArea {
	name: string,
	districts: string[]
}

export default function LicenceAreaDistrictDropdowns(props: Props) {
	const userContext = useContext(UserPermissionsContext);

	const [licenceAreasList, setLicenceAreasList] = useState<LicenceArea[]>([]);
	const [geofencesLoading, setGeofencesLoading] = useState(true);
	const [geofenceList, setGeofenceList] = useState<LicenceAreaAndRelatedDistricts[]>([]);

	const { onLicenceAreaChange, onDistrictChange, customerId } = props;
	useEffect(() => {
		onLicenceAreaChange(undefined, undefined);
		onDistrictChange("", undefined);
		setGeofencesLoading(true);
	}, [customerId, onDistrictChange, onLicenceAreaChange])

	useEffect(() => {
		setLicenceAreasList(buildLicenceAreasListFromSubstations(props.substationsList));
	}, [props.substationsList])

	function handleLicenceAreaDropdownChange(event: any) {
		changeSelectedLicenceArea(event.target.value);
	}

	function changeSelectedLicenceArea(licenceArea: LicenceArea | undefined) {
		const licenceAreaGeofence = geofenceList && licenceArea && geofenceList.find(area => area.licenceArea.name === licenceArea.name);
		const geofence = licenceAreaGeofence && licenceAreaGeofence.licenceArea.geofence;

		props.onLicenceAreaChange(licenceArea, geofence);
	}

	function handleDistrictDropdownChange(event: any) {
		const district = event.target.value;

		if (district === "") {
			changeSelectedLicenceArea(props.selectedLicenceArea);
			props.onDistrictChange(district);
		}
		else {
			const licenceAreaGeofence: LicenceAreaAndRelatedDistricts = geofenceList && props.selectedLicenceArea && geofenceList.find(area => area.licenceArea.name === props.selectedLicenceArea.name);
			const districtGeofence = licenceAreaGeofence && licenceAreaGeofence.districts.find(dist => dist.name === district);
			const geofence = districtGeofence?.geofence;

			props.onDistrictChange(district, geofence);
		}
	}

	function handleGetGeofencesSuccess(response: any) {
		setGeofencesLoading(false);
		setGeofenceList(response);
	}

	if (props.substationsList) {
		return (
			<React.Fragment>
				{props.customerId && geofencesLoading && <LicenceAreasAndDistrictsByCustomer customer={props.customerId} refreshData={geofencesLoading} onDataGet={handleGetGeofencesSuccess} checkTokenIsValid={userContext.tokenManager.checkTokenIsValid} />}
				<Col xs={12} className="pb-2">
					<span>Licence Area</span>
					{props.customerId && (geofencesLoading || props.substationsDataLoading)
						? <div style={{ height: "50px" }}><LoadingGraphic small /></div>
						: <DropDownList name="type"
							disabled={!props.customerId || props.customerId === ""}
							data={licenceAreasList}
							value={props.selectedLicenceArea}
							textField="name"
							dataItemKey="name"
							onChange={handleLicenceAreaDropdownChange}
							defaultItem={{ name: "" }}
							className="dropDownList"
						/>
					}
				</Col>
	
				<Col xs={12} className="pb-2">
					<span>District/Zone</span>
					{props.customerId && (geofencesLoading || props.substationsDataLoading)
						? <div style={{ height: "50px" }}><LoadingGraphic small /></div>
						: <DropDownList name="type"
							disabled={!props.selectedLicenceArea || props.selectedLicenceArea.name === "" || props.selectedLicenceArea.name === undefined}
							data={(props.selectedLicenceArea && props.selectedLicenceArea.districts) || []}
							value={props.selectedDistrict}
							onChange={handleDistrictDropdownChange}
							defaultItem={""}
							className="dropDownList"
						/>
					}
				</Col>
			</React.Fragment>
		)
	}
	else {
		return null;
	}
}

function buildLicenceAreasListFromSubstations(substationsList: any[]) {
	let licenceAreasList: LicenceArea[] = [];

	if (substationsList) {

		substationsList.forEach((substation: any) => {

			const substationLicenceArea = substation.licenceArea;
			const substationDistrict = substation.district;

			if (substationLicenceArea !== "") {
				//find if this substation's licence area is already in the licence area list, and add it if it's not in there
				let isLicenceAreaInList = licenceAreasList.find((obj: any) => obj.name === substationLicenceArea);

				if (!isLicenceAreaInList) {
					licenceAreasList.push({ name: substationLicenceArea, districts: [] });
				}

				//if the substation has a district, populate the licence area's districts list
				if (substationDistrict) {
					//get the licence area object
					const laObj = licenceAreasList.find((obj: any) => obj.name === substationLicenceArea);

					if (laObj) {
						const isDistrictInList = laObj.districts.find((name: string) => name === substationDistrict);
						if (!isDistrictInList) {
							laObj.districts.push(substationDistrict);
						}

						laObj.districts.sort((a, b) => a.localeCompare(b));
					}
				}
			}
		});
	}

	return licenceAreasList.sort((a, b) => a.name.localeCompare(b.name));
}