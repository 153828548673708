import React from 'react'

interface Props {
  small?: boolean
}

class LoadingGraphic extends React.Component<Props>  {
    
    render() {

      const loadingImageSizeCSSClass = this.props.small ? "ea-loading-image-small" : "";

      return <div style={{height: "100%", width: "100%"}}>
                <div className="ea-loading-mask" style={{ backgroundColor: "transparent"}} >
                    <span className="ea-loading-text">Loading</span>
                    <div className={`ea-loading-image ${loadingImageSizeCSSClass}`} ></div>
                    <div className="ea-loading-color"></div>
                </div>

            </div>;
    }
  }

export default LoadingGraphic;