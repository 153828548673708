const phaseColours = {
	L1: "#66CCCC",
	L2: "#8560bd",
	L3: "#6699CC",
	N: "#999999",
	Other: "#FF0000"
}
export function getPhaseSeriesFormatting(assetTypeKey: string, assetName: string) {
	assetTypeKey = assetTypeKey.toLowerCase();

	if (assetTypeKey.includes("l1")) {
		return {
			colour: phaseColours.L1,
			label: "L1 Phase",
			defaultHiddenSeries: false
		}
	}
	else if (assetTypeKey.includes("l2")) {
		return {
			colour: phaseColours.L2,
			label: "L2 Phase",
			defaultHiddenSeries: false
		}
	}
	else if (assetTypeKey.includes("l3")) {
		return {
			colour: phaseColours.L3,
			label: "L3 Phase",
			defaultHiddenSeries: false
		}
	}
	else if (assetTypeKey.includes("neutral")) {
		return {
			colour: phaseColours.N,
			label: "N Phase",
			defaultHiddenSeries: true
		}
	}
	else {
		return {
			colour: phaseColours.Other,
			label: assetName,
			defaultHiddenSeries: false
		}
	}
}