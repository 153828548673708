import React, { CSSProperties } from 'react'
import { Slider } from '../shared/Slider';
import moment from 'moment'

interface TimespanSliderProps {
  marks: Mark[],
  onChange: any,
  disabled?: boolean,
  style?: CSSProperties
}

export interface Mark {
  value: number,
  label: string,
  timespanStart?: {
    unitOfTime: string,
    amount: number,
  },
  dateStart?: moment.Moment | string,

  dateEnd?: moment.Moment | string,
  defaultValue?: boolean,
  chartTimeUnit?: string
}

class TimespanSlider extends React.Component<TimespanSliderProps> {

  onDateSliderChange = (event: object, value: any) => {

    const selectedMark = this.props.marks.find((mark: any) => mark.value === value);

    this.props.onChange(selectedMark)

  }

  render() {

    return (
      <div style={this.props.style}>
        <Slider
          onChangeCommitted={this.onDateSliderChange}
          marks={this.props.marks}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}

export default TimespanSlider;
