import * as React from 'react';
import { RouteComponentProps, Redirect} from 'react-router-dom';
import queryString from 'query-string';
import BusyOverlay from '../shared/BusyOverlay';
import { JwtManager } from '../shared/JwtManager';
import { InfoMessage, InfoBanner } from '../shared/InfoBanner';
import config from '../../config';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

interface LoginState {
    username: string;
    password: string;
    submitted: boolean;
    result: InfoMessage;
    loginSuccess: boolean;
    redirectToReferrer: boolean;
}

type LocationState = {
    from: Location;
}

interface LoginProps {
    routeComponentProps: RouteComponentProps<{}>;
    onAuthenticate: any;
    checkTokenIsValid: any;
}

export class Login extends React.Component<LoginProps, LoginState> {
    tokenManager = new JwtManager({});

    constructor(props: LoginProps) {
        super(props);

        this.state = {
            username: "",
            password: "",
            submitted: false,
            redirectToReferrer: false,
            result: {
                show: false,
                warn: false,
                message: "",
                error: false
            },
            loginSuccess: false
        };

        if (this.props.routeComponentProps.location.search !== "") {
            const values = queryString.parse(this.props.routeComponentProps.location.search)

            if (values.logout) {
                this.tokenManager.removeToken();
                this.props.onAuthenticate(false);
            }
        }
    }

    handleUsernameChange = (event: any) => {
        this.setState({
            username: event.target.value,
            result: {
                show: false,
                warn: false,
                message: "",
                error: false
            }
        });
    }

    handlePasswordChange = (event: any) => {
        this.setState({
            password: event.target.value,
            result: {
                show: false,
                warn: false,
                message: "",
                error: false
            }
        });
    }

    handleSubmit = (event: any) => {
        if (!this.state.username) {
            this.setState({
                result: {
                    show: true,
                    warn: false,
                    message: "Please enter a username.",
                    error: true
                }
            });
        }
        else if (!this.state.password) {
            this.setState({
                result: {
                    show: true,
                    warn: false,
                    message: "Please enter a password.",
                    error: true
                }
            });
        }
        else {
            this.setState({
                submitted: true,
                result: {
                    show: true,
                    warn: false,
                    message: "Logging in...",
                    error: false
                }
            });

            const authEndpoint = config.apiGateway.AUTH_API + "/api/token";
            const postBody = new URLSearchParams({
                grant_type: "password",
                username: this.state.username,
                password: this.state.password,
                clienttype: "User"
            })
            fetch(authEndpoint,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: postBody
                })
                .then(resp => this.handleResponse(resp))
                .catch(error => {
                    console.error(`Error when logging in:  ${error.message}`)
                    this.setState({
                        result: {
                            show: true,
                            warn: true,
                            message: "Unable to log in. Please try again later.",
                            error: true
                        },
                        submitted: false
                    })
                });
            }
            event.preventDefault();    
    }

    handleResponse(response: Response) {
        if (response.status === 401) {
            this.setState({
                result: {
                    show: true,
                    warn: true,
                    message: "Invalid login credentials submitted.",
                    error: true
                },
                submitted: false
            });
        } else if (!response.ok) {
            console.error(`Error when logging in. Response ${response.status}: ${response.statusText}`)
            this.setState({
                result: {
                    show: true,
                    warn: true,
                    message: `Unable to log in. Please try again later.`,
                    error: true
                },
                submitted: false
            })
        } else {
            this.handleSuccess(response);
        }
    }

    handleSuccess(response: Response) {
        response.json().then(resp => {
            this.tokenManager.storeAuthResponse(
                resp.token,
                resp.expires,
                resp.refresh_token
            );

            this.props.onAuthenticate(true);

            this.setState(() => ({
                redirectToReferrer: true
            }))
        });
    }

    render() {
        if (this.state.redirectToReferrer === true) {
            let { from } = this.props.routeComponentProps.location.state as LocationState || { from: { pathName: "/" } };
            let { redirectToReferrer } = this.state;

            if (redirectToReferrer) return <Redirect to={from} />;
        }
        else {

            return (
                <Container fluid>
                    <BusyOverlay show={this.state.submitted} />
                    <Row>
                        <Col>
                            <div className="pt-5 pb-xs-2 pb-sm-3">
                                <h4 className="page-header">Login</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="justify-content-center" >
                            <Form>
                                <Form.Group as={Row} className="justify-content-center" >
                                    <Form.Label column xs={10} sm="auto" >
                                        Username
                                    </Form.Label>
                                    <Col xs={10} sm="auto" >
                                        <Form.Control type="text" placeholder="Username" value={this.state.username} onChange={this.handleUsernameChange} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="justify-content-center">
                                    <Form.Label xs={10} column sm="auto">
                                        Password
                                    </Form.Label>
                                    <Col xs={10} sm="auto">
                                        <Form.Control type="password" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="pt-xs-3 pt-sm-3 justify-content-center">
                                    <Col xs="auto" >
                                        <Button type="submit" onClick={this.handleSubmit}>Login</Button>

                                    </Col>
                                </Form.Group>
                                <Row className="justify-content-center">
                                    <Col xs="auto" >

                                        <InfoBanner message={this.state.result} />
                                    </Col>
                                </Row>
                            </Form>

                        </Col>
                    </Row>
                </Container>
            );
        }
    }
}