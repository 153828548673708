import { useEffect } from 'react';
import { useLeaflet } from 'react-leaflet';
import L from 'leaflet';
import "leaflet.markercluster/dist/leaflet.markercluster";

interface Props extends L.MarkerClusterGroupOptions {
	onClusterMouseover?: any;
	onClusterMouseout?: any;
	markers: any;
}

const MarkerClusterGroup = (props: Props & L.MarkerClusterGroupOptions) => {
	const { map } = useLeaflet();
	useEffect(() => {
		const markerClusterGroup = L.markerClusterGroup(props);

		props.markers.forEach((marker: any) => {
			marker && markerClusterGroup.addLayer(marker)
		})

		markerClusterGroup.addTo(map);

		props.onClusterMouseover && markerClusterGroup.on('clustermouseover', function (event: any) {
			props.onClusterMouseover(event);
		})

		props.onClusterMouseout && markerClusterGroup.on('clustermouseout', function (event: any) {
			props.onClusterMouseout(event);
		})

		return () => {
			markerClusterGroup.clearLayers();
		}
	}, [props.markers])

	return null;
}

export default MarkerClusterGroup;
