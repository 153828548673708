import * as React from 'react';
import { push as Menu } from 'react-burger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

interface Props {
	isOpen: boolean;
	onStateChange: any;
	onToggle: any;
	showBurgerIcon?: boolean;
	width?: string;
	right?: boolean;
	showBackButton?: boolean;
	onBackButtonClick?: any;
	pageWrapId?: string;
	outerContainerId?: string;
	classString?: string;
}

const customCrossIcon = <FontAwesomeIcon icon={faTimes} />;
class SidePanel extends React.Component<Props> {

	renderBackButton = () => {

		if (this.props.showBackButton) {
			return (
				<span className="bm-arrow-button" onClick={this.props.onBackButtonClick}>
					<FontAwesomeIcon icon={faArrowLeft} style={{ position: "absolute", top: "0px" }} />
				</span>
			)
		}
	}

	render() {
		let menuClassName = "leftMenu";

		if (this.props.right) {
			menuClassName = `rightMenu ${this.props.classString}`
		}

		return (
			<span className={`${menuClassName} menuContainer`}>
				<Menu
					right={this.props.right}
					noOverlay
					disableAutoFocus
					isOpen={this.props.isOpen}
					onStateChange={this.props.onStateChange}
					width={this.props.width}
					className={menuClassName}
					customBurgerIcon={this.props.showBurgerIcon ? undefined : false}
					customCrossIcon={customCrossIcon}
					pageWrapId={this.props.pageWrapId}
					outerContainerId={this.props.outerContainerId}
				>
					{this.renderBackButton()}
					{this.props.children}
				</Menu>
			</span>
		)
	}
}

export default SidePanel;