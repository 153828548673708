import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';

declare module "@material-ui/core/styles/createPalette" {
	interface Palette {
		eaPurple: Palette['primary'];
		darkBackground: Palette['primary'];
		lightBackground: Palette['primary'];
	}
	interface PaletteOptions {
		eaPurple: PaletteOptions['primary'];
		darkBackground: PaletteOptions['primary'];
		lightBackground: PaletteOptions['primary'];
	}
}

const theme = createMuiTheme({
	zIndex: {
		drawer: 900
	},
	palette: {
		primary: {
			main:  grey[300],
			light: grey[50],
			dark: grey[400],
		},
		text: {
			disabled: grey[400],
		},
		secondary: {
			main: "#00AAe4",
		},
		eaPurple: {
			main: '#220639',
			contrastText: "#fff"
		},
		darkBackground: {
			main: grey["A400"],
			light: '#363636',
			contrastText: grey[300]
		},
		lightBackground: {
			main: grey[200],
			light: grey[50],
			contrastText: grey[300]
		},
		success: {
			main: green[500]
		},
		warning: {
			main: "#E9A946"
		}
	},
	typography: {
		fontFamily: [
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
	props: {
		MuiButton: {
		  disableElevation: true
	   }
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: "capitalize",
				fontWeight: 400,
			}
		},
		MuiList: {
			padding: {
				paddingTop: "2px",

			}
		}
	}
});

interface Props {
	children?: React.ReactNode;
}

export function MuiThemeEA(props: Props) {
	return (
		<ThemeProvider theme={theme}>
			{props.children}
		</ThemeProvider>
	);
}