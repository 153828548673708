import { withStyles, Theme } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const Accordion = withStyles({
	root: {
		backgroundColor: "inherit",
		color: "inherit",
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

export const AccordionSummary =withStyles((theme: Theme) => ({
	root: {
		letterSpacing: 'normal',
		
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
		[theme.breakpoints.up('xs')]: {
			padding: '0px 0px 0px 0px',
		},
		[theme.breakpoints.up('md')]: {
			padding: '0px 16px 0px 16px',
		}

	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
		display: 'block',
	},
	expanded: {},
	
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme: Theme) => ({
	root: {
		[theme.breakpoints.up('xs')]: {
			padding: '0px 0px 0px 0px',
		},
		[theme.breakpoints.up('md')]: {
			padding: '0px 16px 0px 16px',
		}
		
	}
  }))(MuiAccordionDetails);
