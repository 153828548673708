import { DataPointTypes, IDataPointType } from './datapointtypes';

export interface IFeederAlarmConfig {
    name?: string;
    showInRAGMatrix?: boolean;

    isPreFault?: boolean;
    dataPointTypes?: IDataPointType[];
}

export interface IFeederAlarmConfig {
    name?: string;
    showInRAGMatrix?: boolean;

    dataPointTypes?: IDataPointType[];
    waveFormDataPointTypes?: any[];
}

export interface IAlarmConfig {
    transformerAlarms: string[];
    busbarAlarms: string[];

    feederAlarms: IFeederAlarmConfig[];
}

export const AlarmConfig: IAlarmConfig = {
    transformerAlarms: [
        'Transformer Long Term Overload',
        'Transformer Short Term Overload',
        'Power Quality Voltage THD',
    ],
    busbarAlarms: [
        'Low Volts (Peak)',
        'High Volts (Peak)',
        'Low Volts (Average)',
        'High Volts (Average)'
    ],
    feederAlarms: [
        { name: 'Reverse Power', showInRAGMatrix: true, dataPointTypes: [DataPointTypes.ActivePower30min, DataPointTypes.CurrentMean30minutes] },
        { name: 'Phase Imbalance', showInRAGMatrix: true, dataPointTypes: [DataPointTypes.CurrentMean30minutes] },
        { name: 'Feeder Long Term Overload', showInRAGMatrix: true, dataPointTypes: [DataPointTypes.CurrentMean30minutes] },
        { name: 'Feeder Short Term Overload', showInRAGMatrix: true, dataPointTypes: [DataPointTypes.CurrentMean30minutes] },
        { name: 'Pre-Faults Detected', showInRAGMatrix: false, isPreFault: true, dataPointTypes: [DataPointTypes.CurrentWaveform] },
        { name: 'Pre-Faults - Most Recent', showInRAGMatrix: false, isPreFault: true, dataPointTypes: [DataPointTypes.CurrentWaveform] }
    ]
}