import React, { useEffect, useState } from 'react'
import { JwtManager } from '../shared/JwtManager';

enum Roles {
	Admin = "Admin",
	User = "User",
	NoAuth = "NoAuth"
}

enum PermissionLevels {
	Configurator = "Configurator",
	CustomerConfigurator = "CustomerConfigurator",
	User = "User",
	None = "None"
}

interface IUserPermissions {
	isAuthenticated: boolean,
	role: Roles,
	permissions: Permissions,
	tokenManager: any,
	logout: () => void
}

interface Permissions {
	Tdr: PermissionLevels,
	alarmView: string[],
	checkAlarmAccess(customerId: string): boolean
}

const defaultPermissions: IUserPermissions = {
	isAuthenticated: false,
	role: Roles.NoAuth,
	permissions: {
		Tdr: PermissionLevels.None,
		alarmView: [],
		checkAlarmAccess: () => false
	},
	tokenManager: undefined,
	logout: () => { }
}

const UserPermissionsContext = React.createContext<IUserPermissions>(defaultPermissions);

interface Props {
	tokenManager?: JwtManager,
	children?: any,
	onLogout: () => void
}

function UserPermissionsProvider(props: Props) {

	const [userPermissions, setUserPermissions] = useState(defaultPermissions)

	useEffect(() => {
		let permissions = defaultPermissions;
		const tokenPayload = props.tokenManager && props.tokenManager.getPayload();

		if (props.tokenManager && tokenPayload) {

			if (tokenPayload.IsAdmin === "IsAdmin") {
				permissions = {
					isAuthenticated: true,
					role: Roles.Admin,
					permissions: {
						Tdr: PermissionLevels.Configurator,
						alarmView: [],
						checkAlarmAccess: () => true
					},
					tokenManager: props.tokenManager,
					logout: props.onLogout
				};
			}
			else if (tokenPayload.hasOwnProperty('CustomerView')) {
				permissions = {
					isAuthenticated: true,
					role: Roles.User,
					permissions: {
						Tdr: GetTdrPermissions(tokenPayload),
						alarmView: tokenPayload.AlarmView ? tokenPayload.AlarmView : [],
						checkAlarmAccess: (customerId: string) => tokenPayload.AlarmView && tokenPayload.AlarmView.includes(customerId.toString())
					},
					tokenManager: props.tokenManager,
					logout: props.onLogout
				};
			};
		}

		setUserPermissions(permissions);

	}, [props.tokenManager])

	return (
		<UserPermissionsContext.Provider value={userPermissions}>
			{props.children}
		</UserPermissionsContext.Provider>
	);
}

function GetTdrPermissions(tokenPayload: any): PermissionLevels {

	if (tokenPayload.TdrConfigurator === "TdrConfigurator") {
		return PermissionLevels.Configurator;
	}
	else if (tokenPayload.CustomerTdrConfigurator) {
		return PermissionLevels.CustomerConfigurator;
	}
	else {
		return PermissionLevels.User;
	}
}

export { UserPermissionsContext, UserPermissionsProvider, PermissionLevels, Roles };