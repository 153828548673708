import React, { CSSProperties } from 'react'
import MaterialSlider from '@material-ui/core/Slider';

interface SliderProps {
  onChangeCommitted: any,
  marks: any,
  disabled?: boolean,
  style?: CSSProperties
}

class Slider extends React.Component<SliderProps> {

  render() {
    if (this.props.marks) {
      const minValue = this.props.marks[0].value;
      const maxValue = this.props.marks[this.props.marks.length - 1].value;
      const defaultMark = this.props.marks.find((mark: any) => mark.defaultValue === true);

      return (
        <div style={this.props.style}>
          <MaterialSlider
            className='eaSlider'
            classes={{
              markLabel: 'markLabel',
              mark: 'mark',
              markActive: 'markActive',
              colorPrimary: 'colorPrimary',
              thumbColorPrimary: 'thumbColorPrimary',
              colorSecondary: 'colorSecondary',
              thumbColorSecondary: 'thumbColorSecondary',
              rail: 'rail',
              thumb: 'thumb'
            }}
            track={false}
            disabled={this.props.disabled ? true : false}
            color={this.props.disabled ? 'secondary' : 'primary'}
            defaultValue={defaultMark ? defaultMark.value : undefined}
            min={minValue}
            max={maxValue}
            valueLabelDisplay="off"
            marks={this.props.marks}
            onChangeCommitted={this.props.onChangeCommitted}
          />
        </div>
      );
    }
  }
}

export default Slider;
