import React from 'react';

import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { ImpedanceValue } from '../shared/interfaces/Impedance';
import { formatDateFromUnixTimestamp } from '../shared/helpers/DateFormat'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			color: theme.palette.primary.main,
		},
		valueText: {
			textAlign: 'center',
			fontSize: '0.75em',
		},
		dateText: {
			textAlign: 'center',
			fontSize: '0.6em',
			[theme.breakpoints.up("sm")]: {
				fontSize: '0.75em'
			},

		},
		card: {
			color: theme.palette.primary.main,
			backgroundColor: "#464646",
			display: "flex",
			alignItems: "center",
		},
		emptyCard: {
			color: theme.palette.primary.dark,
			backgroundColor: "#3C3C3C",
		},
		gridItem: {
			margin: 3,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
	}),
);

interface ImpedanceGridProps {
	l1Impedance: ImpedanceValue | undefined;
	l2Impedance: ImpedanceValue | undefined;
	l3Impedance: ImpedanceValue | undefined;
}

const impedanceEmptyText = "No impedance data found";

export function ImpedanceGrid(props: ImpedanceGridProps) {
	const classes = useStyles();

	return (
		<Grid container spacing={1}>
			<Grid container item xs={12} spacing={1}>
				<ImpedanceGridHeader />
			</Grid>
			<Grid container item xs={12} spacing={1}>
				<ImpedanceGridRow
					label="Date"
					l1Value={props.l1Impedance?.date && formatDateFromUnixTimestamp(props.l1Impedance?.date) || impedanceEmptyText}
					l2Value={props.l2Impedance?.date && formatDateFromUnixTimestamp(props.l2Impedance?.date) || impedanceEmptyText}
					l3Value={props.l3Impedance?.date && formatDateFromUnixTimestamp(props.l3Impedance?.date) || impedanceEmptyText}
					className={classes.dateText}
				/>
			</Grid>
			<Grid container item xs={12} spacing={1}>
				<ImpedanceGridRow
					label="Impedance Value"
					l1Value={props.l1Impedance?.impedancevalue}
					l2Value={props.l2Impedance?.impedancevalue}
					l3Value={props.l3Impedance?.impedancevalue}
				/>
			</Grid>
			<Grid container item xs={12} spacing={1}>
				<ImpedanceGridRow
					label="Impedance Type"
					l1Value={props.l1Impedance?.impedancetype}
					l2Value={props.l2Impedance?.impedancetype}
					l3Value={props.l3Impedance?.impedancetype}
				/>
			</Grid>
			<Grid container item xs={12} spacing={1}>
				<ImpedanceGridRow
					label="Credibility Level"
					l1Value={props.l1Impedance?.impedanceconfidencelevel}
					l2Value={props.l2Impedance?.impedanceconfidencelevel}
					l3Value={props.l3Impedance?.impedanceconfidencelevel}
				/>
			</Grid>
		</Grid>
	);
}

interface ImpedanceGridRowProps {
	label: string;
	l1Value: string | number | undefined;
	l2Value: string | number | undefined;
	l3Value: string | number | undefined;
	className?: string;
}

function ImpedanceGridRow(props: ImpedanceGridRowProps) {
	const classes = useStyles();
	return (
		<React.Fragment>

			<Grid item xs className={classes.gridItem}  >
				<Typography align="right" variant="caption" className={`${classes.label}`} >{props.label}</Typography>
			</Grid>
			<Grid
				item
				xs
				className={clsx(classes.gridItem, classes.card, {
					[classes.emptyCard]: !props.l1Value || props.l1Value === impedanceEmptyText
				})}

				component={Card} variant="outlined"
			>

				<Typography variant="body1" className={props.className || classes.valueText} >{props.l1Value}</Typography>

			</Grid>
			<Grid
				item
				xs
				className={clsx(classes.gridItem, classes.card, {
					[classes.emptyCard]: !props.l2Value || props.l2Value === impedanceEmptyText
				})}

				component={Card} variant="outlined"
			>
				<Typography variant="body1" className={props.className || classes.valueText}>{props.l2Value}</Typography>

			</Grid>
			<Grid
				item
				xs
				className={clsx(classes.gridItem, classes.card, {
					[classes.emptyCard]: !props.l3Value || props.l3Value === impedanceEmptyText
				})}

				component={Card} variant="outlined"
			>
				<Typography variant="body1" className={props.className || classes.valueText}>{props.l3Value}</Typography>

			</Grid>
		</React.Fragment>
	);
}

function ImpedanceGridHeader() {
	const classes = useStyles();
	return (
		<React.Fragment>

			<Grid item xs className={classes.gridItem} />

			<Grid item xs className={classes.gridItem}  >
				<Typography align="center" variant="caption" className={`${classes.label}`} >L1</Typography>
			</Grid>
			<Grid item xs className={classes.gridItem}  >
				<Typography align="center" variant="caption" className={`${classes.label}`} >L2</Typography>
			</Grid>
			<Grid item xs className={classes.gridItem}  >
				<Typography align="center" variant="caption" className={`${classes.label}`} >L3</Typography>
			</Grid>
		</React.Fragment>
	);
}