import config from '../../config';
import ApiResponseHandler from '../shared/ApiResponseHandler'

export default class CategorisedDataAPIs {

	getDataByAssetAndDataPointTypeId(assetIds: string[], datapointTypesIDs: string[], startTime: string, endTime: string, checkTokenIsValid: any, onSuccess: any, onError: any): any {

		const apiResponseHandler = new ApiResponseHandler({});

		const body = {
			assetIDs: assetIds,
			datapointTypeIds: datapointTypesIDs,
			startTime: startTime,
			endTime: endTime
		};

		let endpointAddress = `${config.apiGateway.DATA_API}/api/CategorisedData/ByAssetAndDataPointTypeId`;
		apiResponseHandler.callAPIPost(endpointAddress, onSuccess, onError, checkTokenIsValid, JSON.stringify(body));

	}
}