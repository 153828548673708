import React, { useState, useEffect } from 'react';
import { useImpedanceData } from '../Data/hooks/useImpedanceData';

import Card from 'react-bootstrap/Card';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { LoadingGraphic } from '../shared/LoadingGraphic';
import { TabView } from '../shared/TabView';
import { MessageToast, ToastMode } from '../shared/MessageToast';

import { ImpedanceGrid } from './ImpedanceGrid';

interface Props {
	phaseL1: any;
	phaseL2: any;
	phaseL3: any;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			fontSize: "1em",
			fontWeight: 300,
		}
	}),
);

export function ImpedanceUI(props: Props) {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(true);
	const [messageToast, setMessageToast] = useState({ show: false, mode: ToastMode.Info, text: "" });

	const phaseL1ImpedanceData = useImpedanceData(props.phaseL1.assetId);
	const phaseL2ImpedanceData = useImpedanceData(props.phaseL2.assetId);
	const phaseL3ImpedanceData = useImpedanceData(props.phaseL3.assetId);

	useEffect(() => {
		const loading = (phaseL1ImpedanceData.loading || phaseL2ImpedanceData.loading || phaseL3ImpedanceData.loading);
		setIsLoading(loading);

		if (!loading) {
			const hasError: boolean = phaseL1ImpedanceData.error || phaseL2ImpedanceData.error || phaseL3ImpedanceData.error ? true : false;

			if (hasError) {
				setMessageToast({
					text: "Unable to load impedance data at this time. Please try again later.",
					mode: ToastMode.Error,
					show: hasError
				});
			}
			else {
				const empty: boolean = phaseL1ImpedanceData.data === undefined && phaseL2ImpedanceData.data === undefined && phaseL3ImpedanceData.data === undefined ? true : false;

				setMessageToast({
					text: "No impedance data found.",
					mode: ToastMode.Info,
					show: empty
				});
			}
		}

	}, [phaseL1ImpedanceData.loading, phaseL2ImpedanceData.loading, phaseL3ImpedanceData.loading]);

	return <Card className="card-bg-secondary mt-2 mb-2 w-100"  >

		<Card.Body className="p-0">

			{isLoading || messageToast.show
				? <React.Fragment>
					<Typography align="center" className={classes.title}>Impedance</Typography>
					<Divider />

					{(isLoading)
						?
						<div style={{ height: "100px" }}>
							<LoadingGraphic />
						</div>

						: <div className="d-flex justify-content-center m-3">
							<MessageToast text={messageToast.text} mode={messageToast.mode} show={messageToast.show} />
						</div>
					}

				</React.Fragment>

				: <TabView tabs={[{ label: "Best Impedance" }, { label: "Latest Impedance" }]}>

					<ImpedanceGrid
						l1Impedance={phaseL1ImpedanceData?.data?.bestimpedance}
						l2Impedance={phaseL2ImpedanceData?.data?.bestimpedance}
						l3Impedance={phaseL3ImpedanceData?.data?.bestimpedance}
					/>
					<ImpedanceGrid
						l1Impedance={phaseL1ImpedanceData?.data?.latestimpedance}
						l2Impedance={phaseL2ImpedanceData?.data?.latestimpedance}
						l3Impedance={phaseL3ImpedanceData?.data?.latestimpedance}
					/>

				</TabView>
			}

		</Card.Body>
	</Card>

}