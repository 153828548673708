import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LoadingGraphic } from '../shared/LoadingGraphic'
import { EventsCardGrid } from './EventsCardGrid';
import { DataPointTypes } from '../../constants/datapointtypes';
import WaveformPhaseDataChart from './Chart/WaveformPhaseDataChart'
import InstrumentTypes from '../../constants/instrumenttypes'
import { ResizeIcon } from './ResizeableIcon';

interface State {
	selectedPhases: any[];
	feedersWithGuard: any[];
	chartExpanded: boolean;
}

interface Props {
	loading: boolean;
	transformerAssetHierarchy: any;
	substationName: string;
	prefaultData: any;
	onExpandChart: () => void;
}

class GuardDetailView extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props)

		this.state = {
			selectedPhases: [],
			feedersWithGuard: [],
			chartExpanded: false,
		}
	}

	componentDidMount() {
		if (this.props.transformerAssetHierarchy && this.props.transformerAssetHierarchy.feeders) {
			const feedersWithGuard = this.props.transformerAssetHierarchy.feeders.filter((feeder: any) => feeder.hasGuard)

			let defaultPhases: any[] = [];

			if (feedersWithGuard && feedersWithGuard.length > 0 && feedersWithGuard[0].phases && feedersWithGuard[0].phases.length > 0) {
				defaultPhases = this.addPhaseToList(feedersWithGuard[0].phases[0], defaultPhases);
			}

			this.setState({ feedersWithGuard: feedersWithGuard, selectedPhases: defaultPhases })
		}
	}

	handleCardClick = (selectedPhase: any) => {

		let found = this.state.selectedPhases.some((phase: any) => phase.assetId === selectedPhase.assetId)

		if (found) {
			const phaseList = this.removePhaseFromList(selectedPhase, this.state.selectedPhases);
			this.setState({ selectedPhases: phaseList })
		}
		else {
			const phaseList = this.addPhaseToList(selectedPhase, this.state.selectedPhases);
			this.setState({ selectedPhases: phaseList })
		}
	}

	handleColumnHeaderClick = (selectedFeeder: any) => {
		const allFeederPhasesAreCurrentlyOn = selectedFeeder.phases.every((phase: any) => this.state.selectedPhases.some((p: any) => p.assetId === phase.assetId))

		let selectedPhases = this.state.selectedPhases;

		if (allFeederPhasesAreCurrentlyOn) {
			selectedFeeder.phases.forEach((phase: any) => {
				selectedPhases = this.removePhaseFromList(phase, selectedPhases);
			})
		}
		else {
			selectedFeeder.phases.forEach((phase: any) => {
				const phaseIsAlreadySelected = selectedPhases.some((p: any) => p.assetId === phase.assetId)
				if (!phaseIsAlreadySelected) {
					this.addPhaseToList(phase, selectedPhases);
				}
			});
		}

		this.setState({ selectedPhases: selectedPhases })
	}

	removePhaseFromList = (selectedPhase: any, phaseList: any[]) => {
		return phaseList.filter((phase: any) => phase.assetId !== selectedPhase.assetId);
	}

	addPhaseToList = (selectedPhase: any, phaseList: any[]) => {

		let prefaultPhase: any = null;

		if (this.props.prefaultData) {

			this.props.prefaultData.feeders.some((feeder: any) => {
				const foundPhase = feeder.phases.find((prefaultPhase: any) => prefaultPhase.id === selectedPhase.assetId)

				if (foundPhase) {
					prefaultPhase = foundPhase;
					return true;
				}
			});
		}

		let eventCount = prefaultPhase && prefaultPhase.eventCount;

		if (eventCount > 0) {
			phaseList.push(selectedPhase)
		}
		return phaseList;
	}

	private onExpandChart() {
		this.props.onExpandChart();

		this.setState({ chartExpanded: !this.state.chartExpanded });
	}

	render() {
		if (this.props.loading) {
			return (
				<LoadingGraphic />
			)
		}
		else if (this.props.transformerAssetHierarchy) {
			const guardInstrument = this.props.transformerAssetHierarchy.instruments.find((instrument: any) => instrument.assetModel.trim().toLowerCase() === InstrumentTypes.Guard.toLowerCase());

			return (
				<Container fluid className="px-0 pt-1" style={{ width: "99%" }} >
					{!this.state.chartExpanded && <Row className="px-0 pb-1"  >
						<Col>
							<div style={{ fontWeight: 400, fontSize: "1.15em" }}>
								{this.props.substationName}
							</div>
							<div style={{ fontWeight: 200, fontSize: "1em" }}>
								{guardInstrument && guardInstrument.name}
							</div>
						</Col>
					</Row>}
					{!this.state.chartExpanded && <Row className="px-0" noGutters style={{ width: "99%" }} >
						<EventsCardGrid
							assets={this.state.feedersWithGuard}
							eventData={this.props.prefaultData}
							onCardClick={this.handleCardClick}
							onColumnHeaderClick={this.handleColumnHeaderClick}
							activeAssets={this.state.selectedPhases} />
					</Row>}
					<Row className="px-0 py-4" noGutters style={{ width: "99%" }} >
					<ResizeIcon onClick={this.onExpandChart.bind(this)} expanded={this.state.chartExpanded} />
						{this.state.selectedPhases && this.state.selectedPhases.length > 0 && <Col xs={12}>
							<WaveformPhaseDataChart dataPointType={DataPointTypes.CurrentWaveform} phaseAssets={this.state.selectedPhases} displayCount={2} expanded={this.state.chartExpanded} />
						</Col>}
					</Row>
				</Container>
			)
		}
		else {
			return null;
		}
	}
}

export default GuardDetailView;