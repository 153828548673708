import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Card } from '../../shared/Card';
import { HorizontalScrollContainer } from '../../shared/HorizontalScrollContainer'
import moment from 'moment';

interface Props {
    assets: any[];
    eventData: any;
    activeAssets?: any[];
    onCardClick?(selectedAsset: any): void;
    onColumnHeaderClick?(selectedFeeder: any): void;
}

const headerRowHeightPx = 60;
const cardWidthPx = 120;
const cardHeightPx = 120;
const gridPaddingPx = 5;
class EventsCardGrid extends React.Component<Props> {

    renderFeeders = (feeders: any) => {

        return feeders.map((feeder: any) => {

            const eventData = this.props.eventData.feeders.find((prefaultFeeder: any) => prefaultFeeder.id === feeder.assetId)

            return (
                <Col style={{ maxWidth: `${cardWidthPx + gridPaddingPx}px` }}>
                    <Row noGutters className="d-flex justify-content-center align-items-end text-center eventsCardGridColumnHeader" style={{ margin: `0 ${gridPaddingPx}px ${gridPaddingPx}px 0`, height: `${headerRowHeightPx}px` }}>
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { this.props.onColumnHeaderClick && this.props.onColumnHeaderClick(feeder) }}
                        >
                            {feeder.assetName}
                        </div>
                    </Row>

                    {this.renderPhases(feeder.phases, eventData)}

                </Col>
            );
        })
    }

    renderPhases = (phases: any, eventData: any) => {

        return phases.map((phase: any) => {

            let eventCount = 0;
            let mostRecent = null;
            let cardClassName = "eventsCard__defaultBg";
            let enabled = true;

            let isActive = this.props.activeAssets && this.props.activeAssets.some((activeAsset: any) => activeAsset.assetId === phase.assetId)

            if (isActive) {
                cardClassName = "eventsCard__activeBg";
            }

            if (eventData && eventData.phases) {
                let prefaultPhase = eventData.phases.find((prefaultPhase: any) => prefaultPhase.id === phase.assetId)

                eventCount = prefaultPhase && prefaultPhase.eventCount;
                mostRecent = prefaultPhase && moment.utc(prefaultPhase.mostRecent).local();
            }

            if (eventCount === 0) {
                cardClassName = "eventsCard__noEvents";
                enabled = false;
            }

            return (
                <Row style={{ margin: `0 ${gridPaddingPx}px ${gridPaddingPx}px 0` }}>
                    <Card
                        title="events count"
                        className={`eventsCard ${cardClassName}`}
                        style={{ width: `${cardWidthPx}px`, height: `${cardHeightPx}px`, cursor: `${enabled ? 'pointer' : 'default'}` }}
                        showFilterIcon={false}
                        onClick={() => { enabled && this.props.onCardClick && this.props.onCardClick(phase) }}
                    >
                        <Col className="h-100 d-flex flex-column">
                            <Row noGutters className="d-flex align-items-center justify-content-center py-1 eventsCard__count">
                                {eventCount}
                            </Row>
                            {eventCount > 0 && <React.Fragment>
                                <Row noGutters className="d-flex  py-1 align-items-center card__title" >
                                    recent events date
                                </Row>
                                <Row noGutters className="d-flex py-1 card__text" style={{ paddingLeft: "10px" }}>
                                    {mostRecent && mostRecent.format('DD/MM/YYYY')}
                                </Row>
                                <Row noGutters className="d-flex align-items-center py-1 card__text" style={{ paddingLeft: "10px" }}>
                                    {mostRecent && mostRecent.format('HH:mm:ss')}
                                </Row>
                            </React.Fragment>
                            }
                        </Col>
                    </Card>
                </Row>
            );
        });
    }

    renderPhaseLabelColumn = () => {
        const rowLabelClassName = "d-flex justify-content-center align-items-center text-center";
        return (
            <Col className="eventsCardGridRowHeader" style={{ maxWidth: "50px" }}>
                <Row noGutters style={{ height: `${headerRowHeightPx}px` }}>
                </Row>
                <Row noGutters className={rowLabelClassName} style={{ height: `${cardHeightPx}px` }}>
                    L1
                </Row>
                <Row noGutters className={rowLabelClassName} style={{ height: `${cardHeightPx}px` }}>
                    L2
                </Row>
                <Row noGutters className={rowLabelClassName} style={{ height: `${cardHeightPx}px` }}>
                    L3
                </Row>
                <Row noGutters className={rowLabelClassName} style={{ height: `${cardHeightPx}px` }}>
                    N
                </Row>
            </Col>
        )
    }
    render() {
        return (
            <Row noGutters className="flex-nowrap" style={{ width: "90%" }}>
                {this.renderPhaseLabelColumn()}
                <HorizontalScrollContainer>
                    <Row noGutters className="flex-nowrap">
                        {this.renderFeeders(this.props.assets)}
                    </Row>
                </HorizontalScrollContainer>
            </Row>
        )
    }
}

export default EventsCardGrid;