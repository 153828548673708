import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles';

interface Props {
	width: string;
	height: string;
}

const useStyles = makeStyles({
	border: { fill: '#00AAE4' },
	buttons: { fill: '#00AAE4' },
	text: { fill: '#00AAE4' },
	background: { fill: '#F6F6F6' }

});

export default function TdrIcon(props: Props) {

	const classes = useStyles();

	return (
		<svg width={props.width} height={props.height} x="0px" y="0px" viewBox="5 0 50 50"  >

			<g>
				<path className={classes.border} d="M36.19,46c0,0.55-0.45,1-1,1H14.8c-0.55,0-1-0.45-1-1V4.82c0-0.55,0.45-1,1-1h20.39c0.55,0,1,0.45,1,1V46z" />
			</g>
			<g>
				<path className={classes.background} d="M34.9,44.33c0,0.55-0.45,1-1,1H16.09c-0.55,0-1-0.45-1-1V6.49c0-0.55,0.45-1,1-1H33.9c0.55,0,1,0.45,1,1V44.33
		z"/>
			</g>
			<circle className={classes.buttons} cx="18.01" cy="9.02" r="1.08" />
			<circle className={classes.buttons} cx="18.01" cy="13.34" r="1.08" />
			<circle className={classes.buttons} cx="18.01" cy="17.66" r="1.08" />
			<circle className={classes.buttons} cx="18.01" cy="21.98" r="1.08" />
			<circle className={classes.buttons} cx="18.01" cy="26.3" r="1.08" />
			<circle className={classes.buttons} cx="18.01" cy="30.62" r="1.08" />
			<circle className={classes.buttons} cx="18.01" cy="34.94" r="1.08" />
			<g>
				<path className={classes.text} d="M22.42,39.75h-1.53l0,4.15h-1.23l0-4.15h-1.5V38.8l4.26,0V39.75z" />
				<path className={classes.text} d="M22.94,43.89l0-5.09h1.64c0.45,0,0.85,0.1,1.21,0.31s0.64,0.49,0.84,0.86c0.2,0.37,0.3,0.79,0.31,1.25v0.23
		c0,0.47-0.1,0.88-0.3,1.25s-0.47,0.66-0.83,0.87c-0.36,0.21-0.76,0.31-1.2,0.32H22.94z M24.17,39.75l0,3.2h0.43
		c0.35,0,0.62-0.12,0.81-0.38c0.19-0.25,0.28-0.62,0.28-1.12v-0.22c0-0.49-0.09-0.86-0.28-1.11c-0.19-0.25-0.46-0.37-0.83-0.37
		L24.17,39.75z"/>
				<path className={classes.text} d="M29.46,42.09H28.8v1.8h-1.23l0-5.09h2c0.6,0,1.08,0.13,1.42,0.4c0.34,0.27,0.51,0.65,0.51,1.14
		c0,0.35-0.07,0.65-0.21,0.88c-0.14,0.23-0.37,0.42-0.67,0.57l1.06,2.06v0.05h-1.32L29.46,42.09z M28.8,41.15h0.78
		c0.23,0,0.41-0.06,0.53-0.18c0.12-0.12,0.18-0.29,0.18-0.51c0-0.22-0.06-0.39-0.18-0.52c-0.12-0.12-0.29-0.19-0.52-0.19H28.8V41.15
		z"/>
			</g>

		</svg>

	)
}
