import * as React from 'react';
import { Redirect } from 'react-router';
import TokenRefresher from './TokenRefresher';
import { JwtManager } from './JwtManager';

interface props {
}

let redirect: boolean = false;

export class ApiResponseHandler extends React.Component<props> {
    tokenRefresher = new TokenRefresher({});
    tokenManager = new JwtManager({});
    token: string | null;

    constructor(props: props) {
        super(props);
        this.token = this.tokenManager.getToken();
        // The below is a funny line... it basically means that the redirect should occur if token (string | null) has no value or its value has no length.
        redirect = !this.token || this.token.length === 0
    }

    redirectToLogin(): Promise<any> {
        redirect = true;
        return Promise.reject("Unauthorised. Redirecting to login.");
    }

    callAPIGet(endpointAddress: string, handleSuccess: any, handleError: any, checkTokenIsValid: any, signal?: AbortSignal): Promise<any> {
        return this.get(endpointAddress, signal)
            .then(response => {
                if (redirect) {
                    //if we got a response, but the response handler is saying we should redirect to login - trigger the token checker sent from the parent component, which handles the redirect
                    checkTokenIsValid();
                }
                else {
					handleSuccess(response);
					return response;
                }
            })
            .catch(error => {
                if (error.status === 500) {
                    error.json()
                        .then((json: any) => {
                            let errorMessage: string = json.errors?.length > 0 ? json.errors[0].description : "";
                            handleError(`${json.message} ${errorMessage}`);
                        });
                }
                else {
                    handleError(`An error has occurred:  ${error}`)
                }
            });
    }

    callAPIPost(endpointAddress: string, handleSuccess: any, handleError: any, checkTokenIsValid: any, body?: string, signal?: AbortSignal) {
        this.post(endpointAddress, body, undefined, signal)
            .then(response => {
                if (redirect) {
                    //if we got a response, but the response handler is saying we should redirect to login - trigger the token checker sent from the parent component, which handles the redirect
                    checkTokenIsValid();
                }
                else {
                    handleSuccess(response);
                }
            })
            .catch(error => {
                if (error.status === 500) {
                    error.json()
                        .then((json: any) => {
                            let errorMessage: string = json.errors.length > 0 ? json.errors[0].description : "";
                            handleError(`${json.message} ${errorMessage}`);
                        });
                }
                else {
                    handleError(`An error has occurred:  ${error}`)
                }
            });
    }

    get(address: string, signal?: AbortSignal): Promise<any> {
        return this.query(address, "GET", undefined, undefined, undefined, signal);
    }

    post(address: string, body?: string, errorNotAsString?: boolean, signal?: AbortSignal): Promise<any> {
        return this.query(address, "POST", body, errorNotAsString, undefined, signal);
    }

    postFile(address: string, body: File): Promise<any> {
        this.setState({
            loading: true
        });
        return fetch(address,
            {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${this.token}`
                },
                body: body,
            })
            .then(response => {
                return this.handleResponse(response, address, "POST", body);
            })
            .catch(error => {
                console.error(error);
                return Promise.reject(error);
            });
    }

    put(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
        return this.query(address, "PUT", body, undefined, removeHeaderContentType);
    }

    delete(address: string, body?: any, removeHeaderContentType?: boolean): Promise<any> {
        return this.query(address, "DELETE", body, undefined, removeHeaderContentType);
    }

    query(address: string, method: string, body?: string, errorNotAsString?: boolean, removeHeaderContentType?: boolean, signal?: AbortSignal): Promise<any> {
		this.token = this.tokenManager.getToken();

        var header = {};
        header = {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.token}`
        }
        if (removeHeaderContentType) {
            header = {
                "Authorization": `Bearer ${this.token}`
            }
		}
		
        redirect = !this.token || this.token.length === 0;

            return fetch(address,
            {
                method: method,
                headers: header,
				body: body,
				signal: signal
            })
            .then(response => {			
                return this.handleResponse(response, address, method, body);
            })
            .catch(error => {
                console.error(error);
                return Promise.reject(error);
            });
    }

    handleResponse(response: Response, address: string, method: string, body?: string | File) {
        if (response.ok) {
			const contentType = response.headers.get("content-type") || "";

            // Handle the NoContent response
            return response.text().then(function (text) {
				if(contentType.includes("application/json"))
					return JSON.parse(text);
				else if(contentType.includes("text/plain"))
					return(text)
				else Promise.resolve(undefined);
            });

        } else {
            if (response.status === 401 || response.status === 403) {
                var expiry = this.tokenManager.getExpires();
                if (expiry && new Date() > expiry) {
                    // Token has expired, try a refresh
                    return this.tokenRefresher.refreshToken()
                        .then((tk: any) => {
                            // Update the token and recursively try again.
                            this.token = tk;
                            if (body instanceof File) {
                                return this.postFile(address, body);
                            } else {
                                return this.query(address, method, body);
                            }
                        })
                        .catch(() => {
                            this.redirectToLogin();
                        });
                } else {
                    this.redirectToLogin();
                }
            } else {
                return Promise.reject(response);
            }
        }
    }

    getErrorString(error: any, errorNotAsString?: boolean): string {

        if (errorNotAsString)
            return error;

        if (typeof error === "string") {
            return error;
        }
        if (error instanceof Response) {
            var errorResponse = error as Response;
            return errorResponse.statusText;
        }
        return error.toString();
    }

    render() {
        return <div>
            {redirect && <Redirect to='/login' push />}
        </div>;
    }
}

export default ApiResponseHandler;