import React from 'react'
import { Grid as KendoGrid, GridProps as KendoGridProps, GridDataStateChangeEvent, GridColumnMenuSort, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';

interface GridProps {
    gridData: any;
}

interface GridState {
    sort?: any;
    filter?: any;
    dataState: any,
    result: any,
    allData: any
}

const dataState = { skip: 0, sort: [], filter: undefined };
class Grid<T extends KendoGridProps> extends React.Component<T & GridProps, GridState> {

    constructor(props: T & GridProps) {
        super(props)

        this.state = {
            dataState: dataState,
            result: process(props.gridData, dataState),
            allData: props.gridData
        }
    }

    componentDidUpdate(prevProps: T & GridProps) {
        if (this.props.gridData !== prevProps.gridData) {
            this.setState({
                result: process(this.props.gridData, dataState),
                allData: this.props.gridData
            });
        }
    }

    onDataStateChange = (e: GridDataStateChangeEvent) => {
        this.setState({
            dataState: e.data,
            result: process(this.state.allData, e.data)
        });
    };

    isColumnActive(field: any, dataState: any) {
        return GridColumnMenuFilter.active(field, dataState.filter) ||
            GridColumnMenuSort.active(field, dataState.sort);
    }

    renderChildComponents(){
        return React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
                let childElement = child as React.ReactElement<any>;
                return React.cloneElement(childElement, {
                    headerClassName: this.isColumnActive(childElement.props.field, this.state.dataState) ? 'active' : ''
                })
            }
        })
    }

    render() {
        return (
            <KendoGrid
                {...this.props}
                {...this.state.dataState}
                {...this.state.result}
                onDataStateChange={this.onDataStateChange}
            >
                {this.renderChildComponents()}
            </KendoGrid>
        )
    }
}

export default Grid;