import { useReducer } from "react";

export interface DataLoadingState<D> {
	data?: D;
	loading: boolean;
	error?: string;
}

export type LoadDataAction<D> =
	| { type: "loading" }
	| { type: "loaded", data: D }
	| { type: "errored", message: string }

/**
 * Reducer which calculates the next data loading state given some data loading action.
 * @param state - Current state
 * @param action - Action which occurred
 * @returns - The next loading state
 */
export function dataLoadingReducer<D>(state: DataLoadingState<D>, action: LoadDataAction<D>): DataLoadingState<D> {

	if (action.type === "loading") {
		return {
			...state,
			loading: true,
			error: undefined
		};
	}
	else if (action.type === "loaded")
		return {
			...state,
			data: action.data,
			loading: false
		}

	else return {
		...state,
		error: action.message,
		loading: false
	};

}

/**
 * Generate the initial loading state for given generic data type.
 * @param initialData - Initial value to use for data
 */
export function generateInitialLoadingState<D>(initialData: D): DataLoadingState<D> {
	return {
		data: initialData,
		loading: false,
		error: undefined
	};
}

/**
 * React hook which initialises a data loading reducer for the given type.
 * @param initialData - Initial data value
 */
export function useDataLoadingReducer<D>(initialData: D) {
	const dataReducer = (state: DataLoadingState<D>, action: LoadDataAction<D>) => dataLoadingReducer<D>(state, action);
	return useReducer(dataReducer, generateInitialLoadingState(initialData));
}
