import React, { CSSProperties } from 'react'
import { default as BootstrapCard } from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'


interface Props {
    title: string;
    style?: CSSProperties;
    className?: string;
    showFilterIcon?: boolean;
    filterIconSelected?: boolean;
    onClick?(): void;
}

class Card extends React.Component<Props> {

    render() {

        return (
            <BootstrapCard
                className={this.props.className}
                style={this.props.style}
                onClick={this.props.onClick}
            >
                <Row noGutters className="card__title" >
                    <Col>
                        {this.props.title}
                    </Col>

                    {this.props.showFilterIcon && <Col xs={2} className="d-flex justify-content-end mr-1 mt-1">
                        <span >
                            {this.props.filterIconSelected
                                ? <FontAwesomeIcon className="text-info" icon={faFilter} />
                                : <FontAwesomeIcon style={{ color: "white" }} icon={faFilter} />}
                        </span>
                    </Col>}
                </Row>
                <Row noGutters className="d-flex justify-content-center align-items-center h-100">
                    {this.props.children}
                </Row>
            </BootstrapCard>
        )

    }
}

export default Card;