import React, { CSSProperties } from 'react'
import * as _ from "lodash";
import { GridColumn, GridNoRecords, GridCellProps, GridColumnMenuSort, GridColumnMenuFilter } from '@progress/kendo-react-grid';
import { LoadingGraphic } from '../../shared/LoadingGraphic'
import { CSVLink } from "react-csv";
import moment from 'moment'
import AlarmSeverity from '../../../utils/AlarmSeverity'
import { Grid, DropdownFilterUI, DateTimeFilterUI } from '../../shared/Grid'
import './AlarmTable.css';

const dateFormat = 'DD/MM/YYYY HH:mm:ss';

interface AlarmTableProps {
	loading: boolean;
	alarmsData: any;
	assetHeaderText: string;
	assetHeaderField: string;
	isAlarmHistory?: boolean;
	exportButton?: boolean;
	style?: CSSProperties;
}

class AlarmTable extends React.Component<AlarmTableProps> {

	renderEmptyContentPlaceholder = () => {
		return (
			<tr key={`alarmRowEmpty`}>
				<td colSpan={4}>No alarm data found.</td>
			</tr>
		)
	}

	renderSeverityCell = (props: GridCellProps) => {

		let alarmTextClassName = AlarmSeverity.getAlarmStyles(props.dataItem.severity, true).text;
		return (
			<td className={alarmTextClassName}>
				{props.dataItem.severity}
			</td>
		);
	};

	flattenData = () => {

		let formattedAlarmsData: any = [];

		if (this.props.alarmsData && this.props.alarmsData.substations && this.props.alarmsData.substations.length > 0) {

			this.props.alarmsData.substations.forEach((asset: any) => {
				asset.alarms.forEach((alarm: any) => {
					let flattenedObject = Object.assign({}, asset, alarm, { alarms: undefined });
					flattenedObject.timestamp = flattenedObject.timestamp && moment.utc(flattenedObject.timestamp).local().toDate();
					flattenedObject.clearedDate = flattenedObject.clearedDate && moment.utc(flattenedObject.clearedDate).local().toDate();
					formattedAlarmsData.push(flattenedObject);
				});
			});

		}

		formattedAlarmsData = _.orderBy(formattedAlarmsData, ['severity', 'timestamp'], ['desc', 'desc']);
		
		return formattedAlarmsData;
	}

	formatData = () => {

		let formattedAlarmsData: any = [];

		if (this.props.alarmsData && this.props.alarmsData.substations && this.props.alarmsData.substations.length > 0) {
			this.props.alarmsData.substations.map((asset: any) => {

				asset.alarms.map((alarm: any) => {

					let alarmTimestamp = "";
					let clearTimestamp = "";

					alarmTimestamp = alarm.timestamp && moment.utc(alarm.timestamp).local().format(dateFormat);
					clearTimestamp = alarm.clearedDate && moment.utc(alarm.clearedDate).local().format(dateFormat);

					formattedAlarmsData.push({
						"Severity": alarm.severity,
						[this.props.assetHeaderText]: asset[this.props.assetHeaderField],
						"Asset": alarm.assetName,
						"Type": alarm.alarmName,
						"Date/Time": alarmTimestamp,
						"Clear Date": clearTimestamp
					})
				});
			});
		}

		return formattedAlarmsData;

	}

	renderExportButton = (data: any) => {
		if (this.props.exportButton) {
			let formattedData = this.formatData();
			return <CSVLink data={formattedData} className="btn btn-primary" filename={"activeAlarms.csv"}>Export to CSV</CSVLink>
		}
	}

	getFilterDropdown = (data: any, field: string) => {
		const severityList = Array.from(new Set(data.map((p: any) => p[field])));
		return DropdownFilterUI(severityList, '');
	}

	getFilterDateTime = () => {
		return DateTimeFilterUI("dd/MM/yyyy");
	}

	render() {

		if (this.props.loading) {
			return (
				<div style={{ height: "100px" }}>
					<LoadingGraphic />
				</div>
			)
		}
		else {
			let formattedAlarmsData = this.flattenData();

			return (
				<div>

					<div id="alarmTable">
						<Grid
							gridData={formattedAlarmsData}
							style={this.props.style}
							sortable
						>
							<GridColumn title="Severity"
								field="severity"
								cell={this.renderSeverityCell}
								columnMenu={props =>
									<div>
										<GridColumnMenuSort {...props} />
										<GridColumnMenuFilter
											{...props}
											filterUI={this.getFilterDropdown(formattedAlarmsData, "severity")}
										/>
									</div>
								}
							/>
							<GridColumn title={this.props.assetHeaderText}
								field={this.props.assetHeaderField}
								columnMenu={props =>
									<div>
										<GridColumnMenuSort {...props} />
										<GridColumnMenuFilter
											{...props}
											filterUI={this.getFilterDropdown(formattedAlarmsData, this.props.assetHeaderField)}
										/>
									</div>
								}
							/>
							<GridColumn title="Type"
								field="alarmName"
								columnMenu={props =>
									<div>
										<GridColumnMenuSort {...props} />
										<GridColumnMenuFilter
											{...props}
											filterUI={this.getFilterDropdown(formattedAlarmsData, "alarmName")}
										/>
									</div>
								}
							/>
							<GridColumn title="Date/Time" field="timestamp" format="{0: dd/MM/yyyy HH:mm:ss}" filter="date" columnMenu={props =>
								<div>
									<GridColumnMenuSort {...props} />
									<GridColumnMenuFilter
										{...props}
										filterUI={this.getFilterDateTime()}
									/>
								</div>
							}
							/>
							{this.props.isAlarmHistory && <GridColumn title="Clear Date/Time" field="clearedDate" format="{0: dd/MM/yyyy HH:mm:ss}" filter="date" columnMenu={props =>
								<div>
									<GridColumnMenuSort {...props} />
									<GridColumnMenuFilter
										{...props}
										filterUI={this.getFilterDateTime()}
									/>
								</div>
							}
							/>}

							<GridNoRecords>
								No alarms found.
                            </GridNoRecords>
						</Grid>
					</div>

					{this.renderExportButton(formattedAlarmsData)}
				</div>
			)
		}

	}
}

export default AlarmTable;