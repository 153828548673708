import React, { useEffect } from 'react';
import moment from 'moment';
import LineChart, { yAxisSettings } from '../Chart/Highchart'
import { ResizeIcon } from '../ResizeableIcon';

interface Props {
	loading?: boolean;
	tdrEventData: any;
	onBinaryDownloadClick: () => void;
	enableBinaryDownload?: boolean;
	onExpandChart: () => void;
	expanded: boolean;
}

const tdrPhases = [
	{ name: "Red", label: "Red Phase [V]", color: "#ff1c3f", yAxis: 0 },
	{ name: "Yellow", label: "Yellow Phase [V]", color: "#ffcc59", yAxis: 0 },
	{ name: "Blue", label: "Blue Phase [V]", color: "#42cfff", yAxis: 0 },
	{ name: "Current", label: "Current [A]", color: "#a572f2", yAxis: 1 }
]

export default function TdrChart(props: Props) {

	const timestamp = moment.utc(props.tdrEventData.Time).local();
	const waveformTimestamp = parseInt(timestamp.format('x'));
	const chartTitle = `${props.tdrEventData.EventType} ${timestamp.format('DD/MM/YYYY HH:mm:ss')}`;

	const chartYAxisSettings: yAxisSettings[] = [{
		label: "Volts",
		opposite: false
	},
	{
		label: "Current",
		opposite: true
	}]
	const series = tdrPhases.map((phase: any) => {
		return {
			data: props.tdrEventData[phase.name],
			color: phase.color,
			name: phase.label,
			pointStart: waveformTimestamp,
			pointInterval: 0.25,
			yAxis: phase.yAxis
		};
	})

	function handleBinaryDownloadClick() {
		props.onBinaryDownloadClick();
	}

	return (
		<>
			<ResizeIcon onClick={props.onExpandChart} expanded={props.expanded} />
			<LineChart series={series} chartTitle={chartTitle} yAxisSettings={chartYAxisSettings} enableBinaryDownload onBinaryDownloadClick={handleBinaryDownloadClick} expanded={props.expanded}></LineChart>
		</>
	)

}
