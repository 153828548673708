import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import WaveformChart, { yAxisSettings } from '../Chart/Highchart'
import { DataPointTypes, GetDataPointTypeById } from '../../../constants/datapointtypes'

interface Props {
	incident: any;
	open: boolean;
	onClose: () => void;
	transformerAssetHierarchy: any;
	incidentFeederName: string;
}

export default function StreetsenseWaveformsDialog(props: Props) {
	function handleClose() {
		props.onClose();
	}

	let initialWaveformSeries = props.incident && buildHighchartsSeries(props.incident.InitialWaveform, props.transformerAssetHierarchy, props.incidentFeederName);

	return props.incident ? <Dialog
		fullWidth={true}
		open={props.open}
		onClose={handleClose}
		maxWidth="lg"

		PaperProps={{ style: { backgroundColor: "#393939" } }}
	>
		<DialogTitle id="max-width-dialog-title">
			<Container fluid className={"px-0"} style={{ color: '#fff' }}>
				<Row noGutters >
					<Col xs={6}>
						StreetSense Incident {props.incident && props.incident.formattedTime}
					</Col>
					<Col xs={6} className="d-flex justify-content-end">
						<CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />

					</Col>
				</Row>

			</Container>
		</DialogTitle>
		<Container fluid>
			<Row noGutters>
				<Col xs={12} lg={6} className="p-lg-1">
					<WaveformChart
						series={initialWaveformSeries}
						chartTitle={buildChartTitle(props.incident.InitialWaveform)}
						chartSubtitle={props.incident.InitialWaveform.Instrument.LocationNotes}
						yAxisSettings={buildYAxisSettings(props.incident.InitialWaveform)}
					/>
				</Col>
				{props.incident.SupportingWaveforms && props.incident.SupportingWaveforms.map((supportingWaveform: any) => {
					return <Col xs={12} lg={6} className="p-lg-1">
						<WaveformChart
							series={buildHighchartsSeries(supportingWaveform, props.transformerAssetHierarchy, props.incidentFeederName)}
							chartTitle={buildChartTitle(supportingWaveform)}
							chartSubtitle={supportingWaveform.Instrument.LocationNotes}
							yAxisSettings={buildYAxisSettings(supportingWaveform)}
						/>
					</Col>
				})}
			</Row>
		</Container>
	</Dialog>
		: null
}

function buildYAxisSettings(waveform: any) {
	let chartYAxisSettings: yAxisSettings[] = [{
		label: DataPointTypes.CurrentWaveform.unit,
		lineWidth: 1,
		opposite: false
	}];

	const hasVoltageMeasurements = waveform.GuardWaveforms.some((guardWaveform: any) => guardWaveform.DataPointType === DataPointTypes.VoltageWaveform.dataPointTypeId)

	hasVoltageMeasurements && chartYAxisSettings.push({
			label: DataPointTypes.VoltageWaveform.unit,
			opposite: true
	});

	return chartYAxisSettings;
}

function buildChartTitle(streetsenseWaveform: any) {
	return `${streetsenseWaveform.Instrument.Model} ${streetsenseWaveform.Instrument.SerialNumber ? ` ${streetsenseWaveform.Instrument.SerialNumber}` : ""} `;
}

function buildHighchartsSeries(streetsenseWaveform: any, transformerAssetHierarchy: any, incidentFeederName: string) {

	const incidentColor = "#42cfff";
	const guardChartColours = [
		'#64E572', //green
		'#FF9655', //orange
		'#ffcc59', //yellow
		'#BB86FC', //purple
		'#ff1c3f', //red
		'#ff6196', //pink
		'#518DF5', //blue
		'#CCC' //grey
	]

	let series: any[] = [];

	if (streetsenseWaveform && streetsenseWaveform.Instrument.Model === "Guard") {
		series = streetsenseWaveform.GuardWaveforms && streetsenseWaveform.GuardWaveforms.map((guardWaveform: any, index: number) => {

			const seriesDataPointType = GetDataPointTypeById(guardWaveform.DataPointType);
			const unit = seriesDataPointType && seriesDataPointType.abbreviatedUnit;
			const yAxisIndex = seriesDataPointType && seriesDataPointType.dataPointTypeId === DataPointTypes.VoltageWaveform.dataPointTypeId ? 1 : 0;

			let seriesName = guardWaveform.AssetName;

			if (guardWaveform.AssetTypeKey === "Busbar") {
				seriesName = `${guardWaveform.AssetName}`;
			}
			else {
				const feeder = findFeederByPhaseAssetId(guardWaveform.AssetId, transformerAssetHierarchy)
				seriesName = feeder && feeder.assetName;
			}

			seriesName = unit && seriesName.concat(` [${unit}]`);

			return buildSeriesObject(guardWaveform.Waveform, seriesName, guardChartColours[index % guardChartColours.length], yAxisIndex)
		});

		series.push(buildSeriesObject(streetsenseWaveform.Waveform, `${incidentFeederName} [A]`, incidentColor, 0));
	}
	else {
		streetsenseWaveform && series.push(buildSeriesObject(streetsenseWaveform.Waveform, moment.utc(streetsenseWaveform.Time).local().format('DD/MM/YYYY HH:mm:ss'), incidentColor, 0));
	}

	return series;
}

function buildSeriesObject(waveform: any, seriesName: string, seriesColor: string, yAxisIndex: number) {
	waveform = tryParseJSON(waveform);

	return {
		data: JSON.parse(waveform.Value),
		color: seriesColor,
		name: seriesName,
		pointStart: waveform.TimeStampStart * 1000,
		pointInterval: Math.abs(waveform.Interval) * 1000,
		yAxis: yAxisIndex
	}
}

function findFeederByPhaseAssetId(assetId: number, transformerAssetHierarchy: any) {
	return transformerAssetHierarchy.feeders.find((feeder: any) => feeder.phases.some((phase: any) => phase.assetId === assetId));
}

function tryParseJSON(jsonString: string) {
	try {
		const o = JSON.parse(jsonString);
		if (o && typeof o === "object") {
			return o;
		}
	}
	catch (e) { }

	return false;
};
