import React, { CSSProperties, useState, useEffect } from 'react';
import { LoadingGraphic } from '../../../shared/LoadingGraphic'
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';
import { GridColumn, GridNoRecords, GridColumnMenuSort, GridColumnMenuFilter, GridCell } from '@progress/kendo-react-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, DropdownFilterUI, DateTimeFilterUI, ColumnMenuCheckboxFilter } from '../../../shared/Grid'
import { MessageToast, ToastMode } from '../../../shared/MessageToast'

interface Props {
	loading?: boolean;
	style?: CSSProperties;
	onViewIncidentClick: (incident: any) => void,
	incidentData: any;
	dataError?: any;
}

export default function IncidentsGrid(props: Props) {
	const [formattedData, setFormattedData] = useState([]);

	useEffect(() => {
		if (props.loading) {
			setFormattedData([]);
		}
		else {
			const data = formatData(props.incidentData)
			setFormattedData(data);
		}
	}, [props.incidentData, props.loading])

	function formatData(data: any) {
		return data.filter(((incidentDataItem: any) => incidentDataItem !== null && incidentDataItem !== undefined))
			.map((incidentDataItem: any) => {
				return {
					...incidentDataItem,
					formattedTime: incidentDataItem.InitialWaveform.Time && moment.utc(incidentDataItem.InitialWaveform.Time).local().format('DD/MM/YYYY HH:mm:ss'),
					waveformCount: incidentDataItem.SupportingWaveforms ? incidentDataItem.SupportingWaveforms.length + 1 : 1
				}
			}).sort((dataItemA: any, dataItemB: any) => {
				var timeA = dataItemA.InitialWaveform.Time;
				var timeB = dataItemB.InitialWaveform.Time;
				if (timeA > timeB) {
				  return -1;
				}
				if (timeA < timeB) {
				  return 1;
				}
				return 0;
			  });;
	}

	function getFilterDateTime() {
		return DateTimeFilterUI("dd/MM/yyyy");
	}

	return (

		<React.Fragment>
			<div id="incidentsGrid">
				<Grid
					gridData={formattedData}
					style={props.style}
					sortable
					
				>
					<GridColumn title="Date/Time" field="formattedTime" format="{0: dd/MM/yyyy HH:mm:ss}" filter="date" columnMenu={columnMenuProps =>
						<React.Fragment>
							<GridColumnMenuSort {...columnMenuProps} />
							<GridColumnMenuFilter
								{...columnMenuProps}
								filterUI={getFilterDateTime()}
							/>
						</React.Fragment>
					}
					/>
					<GridColumn title="Waveform Count"
						field="waveformCount"
						columnMenu={columnMenuProps =>
							<React.Fragment>
								<GridColumnMenuSort {...columnMenuProps} />
								<ColumnMenuCheckboxFilter  {...columnMenuProps} data={formattedData} />
							</React.Fragment>
						}
					/>

					<GridColumn cell={GridActions(props.onViewIncidentClick)} width="80px" />

					<GridNoRecords>
						{props.loading
							? <div className="pb-2 " style={{ height: "100px", width: "100%" }}>
								<LoadingGraphic />

							</div>
							: props.dataError
								? <MessageToast text={"Unable to load Streetsense Incidents at this time. Please try again later."} mode={ToastMode.Error} show={true} />
								: "No incidents found."}
					</GridNoRecords>
				</Grid>
			</div>
		</React.Fragment>
	);
}

function GridActions(onViewIncidentClick: (dataItem: any) => void) {
	return class extends GridCell {
		render() {
			return <td style={{ textAlign: "center" }}>
				<Tooltip title="View Incident" placement="top"><Button className="small-button" onClick={() => onViewIncidentClick(this.props.dataItem)}><FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} /></Button></Tooltip>
			</td>
		}
	};
} 