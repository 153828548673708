
import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'react-bootstrap';

export default function EventGridActions(onViewEventClick: (dataItem: any) => void) {
	return class extends GridCell {

		render() {
			return <td style={{ textAlign: "center" }}>
				<Tooltip title="View Event Data" placement="top"><Button className="small-button"  onClick={() => onViewEventClick(this.props.dataItem)}><FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} /></Button></Tooltip>
			</td>
		}
	};
} 