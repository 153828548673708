import { useEffect } from 'react'
import ApiResponseHandler from '../../shared/ApiResponseHandler';
import config from '../../../config';

interface Props {

	refreshData: boolean;
	checkTokenIsValid: any;
	onDataGet: (response: any) => void;
	onDataError?: (message: string) => void;

	substationAssetId: any;
}

export default function PrefaultEventsBySubstation(props: Props) {
	useEffect(() => {
		const apiResponseHandler = new ApiResponseHandler({});

		const abortController = new AbortController();
		const signal = abortController.signal;

		const endpointAddress = `${config.apiGateway.DATA_API}/api/PreFault/substation/${props.substationAssetId}`;

		const handleError = (error: any) => {
			props.onDataError && props.onDataError(error)
		}

		apiResponseHandler.callAPIGet(endpointAddress, props.onDataGet, handleError, props.checkTokenIsValid, signal);

		return function cancel() {
			abortController.abort()
		}
	}, [props.substationAssetId, props.refreshData]);

	return null;
}
