import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridColumnMenuFilterUIProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridColumnMenuFilterUIProps';

interface CustomFilterUIProps {
    value?: any;
    onChange?: any;
}

export default function DropdownFilterUI<T extends GridColumnMenuFilterUIProps>(data: any, defaultItem: any): React.ComponentClass<T & CustomFilterUIProps, any> {
    return class extends React.Component<T & CustomFilterUIProps> {

        hasValue = (value: any) => Boolean(value && value !== defaultItem);

        onChange = (event: any) => {
            const hasValue = this.hasValue(event.target.value);

            const { firstFilterProps } = this.props;

            firstFilterProps.onChange({
                value: hasValue ? event.target.value : '',
                operator: hasValue ? 'eq' : '',
                syntheticEvent: event.syntheticEvent
            });
        }

        render() {
            return (
                <DropDownList
                    data={data}
                    defaultItem={defaultItem}
                    onChange={this.onChange}
                />
            );
        }
    }
}
